import { actionCreator, BaseAction, BaseCompanyAction } from '@hum/common';
import { BaseRequestStateChanged } from '@hum/common/src/ducks/actions';
import { BalanceSheetPartner, PrincipalInvestment } from '../state';

export enum PrincipalInvestmentActionType {
  PRINCIPAL_INVESTMENTS_HOOK_USED = 'PRINCIPAL_INVESTMENTS_HOOK_USED',
  API_PRINCIPAL_INVESTMENTS_STATE_CHANGED = 'API_PRINCIPAL_INVESTMENTS_STATE_CHANGED',
  API_PRINCIPAL_INVESTMENT_STATE_CHANGED = 'API_PRINCIPAL_INVESTMENT_STATE_CHANGED',
  API_PRINCIPAL_INVESTMENT_CREATED = 'API_PRINCIPAL_INVESTMENT_CREATED',
  API_BALANCE_SHEET_PARTNER_CREATED = 'API_BALANCE_SHEET_PARTNER_CREATED',
  BALANCE_SHEET_PARTNER_FORM_SAVED = 'BALANCE_SHEET_PARTNER_FORM_SAVED',
  PRINCIPAL_INVESTMENTS_AVAILABLE_BALANCE_SHEET_PARTNER_HOOK_USED = 'PRINCIPAL_INVESTMENTS_AVAILABLE_BALANCE_SHEET_PARTNER_HOOK_USED',
  API_AVAILABLE_BALANCE_SHEET_PARTNERS_STATE_CHANGED = 'API_AVAILABLE_BALANCE_SHEET_PARTNERS_STATE_CHANGED',
}

export type PrincipalInvestmentsHookUsed = BaseCompanyAction<PrincipalInvestmentActionType.PRINCIPAL_INVESTMENTS_HOOK_USED>;

export type ApiPrincipalInvestmentsStateChanged = BaseRequestStateChanged<
  PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENTS_STATE_CHANGED,
  PrincipalInvestment[]
>;

export type ApiPrincipalInvestmentStateChanged = BaseAction<
  PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENT_STATE_CHANGED,
  { companyId: number }
>;

export type ApiPrincipalInvestmentCreated = BaseAction<
  PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENT_CREATED,
  { companyId: number }
>;

export type ApiBalanceSheetPartnerCreated = BaseAction<PrincipalInvestmentActionType.API_BALANCE_SHEET_PARTNER_CREATED>;

export type BalanceSheetPartnerFormSaved = BaseCompanyAction<PrincipalInvestmentActionType.BALANCE_SHEET_PARTNER_FORM_SAVED>;
export type PrincipalInvestmentAvailableBalanceSheetPartnerHookUsed = BaseCompanyAction<PrincipalInvestmentActionType.PRINCIPAL_INVESTMENTS_AVAILABLE_BALANCE_SHEET_PARTNER_HOOK_USED>;

export type ApiAvailableBalanceSheetPartnersStateChanged = BaseRequestStateChanged<
  PrincipalInvestmentActionType.API_AVAILABLE_BALANCE_SHEET_PARTNERS_STATE_CHANGED,
  {
    companies: BalanceSheetPartner[];
  }
>;

//  actionCreator
export const principalInvestmentsHookUsed = actionCreator<PrincipalInvestmentsHookUsed>(
  PrincipalInvestmentActionType.PRINCIPAL_INVESTMENTS_HOOK_USED
);

export const apiPrincipalInvestmentsStateChanged = actionCreator<ApiPrincipalInvestmentsStateChanged>(
  PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENTS_STATE_CHANGED
);

export const apiPrincipalInvestmentStateChanged = actionCreator<ApiPrincipalInvestmentStateChanged>(
  PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENT_STATE_CHANGED
);

export const apiPrincipalInvestmentCreated = actionCreator<ApiPrincipalInvestmentCreated>(
  PrincipalInvestmentActionType.API_PRINCIPAL_INVESTMENT_CREATED
);

export const apiBalanceSheetPartnerCreated = actionCreator<ApiBalanceSheetPartnerCreated>(
  PrincipalInvestmentActionType.API_BALANCE_SHEET_PARTNER_CREATED
);

export const balanceSheetPartnerFormSaved = actionCreator<BalanceSheetPartnerFormSaved>(
  PrincipalInvestmentActionType.BALANCE_SHEET_PARTNER_FORM_SAVED
);

export const principalInvestmentAvailableBalanceSheetPartnerHookUsed = actionCreator<PrincipalInvestmentAvailableBalanceSheetPartnerHookUsed>(
  PrincipalInvestmentActionType.PRINCIPAL_INVESTMENTS_AVAILABLE_BALANCE_SHEET_PARTNER_HOOK_USED
);

export const apiAvailableBalanceSheetPartnersStateChanged = actionCreator<ApiAvailableBalanceSheetPartnersStateChanged>(
  PrincipalInvestmentActionType.API_AVAILABLE_BALANCE_SHEET_PARTNERS_STATE_CHANGED
);

export type PrincipalInvestmentAction =
  | PrincipalInvestmentsHookUsed
  | ApiPrincipalInvestmentsStateChanged
  | ApiPrincipalInvestmentStateChanged
  | ApiPrincipalInvestmentCreated
  | ApiBalanceSheetPartnerCreated
  | BalanceSheetPartnerFormSaved
  | PrincipalInvestmentAvailableBalanceSheetPartnerHookUsed
  | ApiAvailableBalanceSheetPartnersStateChanged;
