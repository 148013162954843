import { normalize } from './models';
import { CreateAPIOptions } from '.';
import {
  Connector,
  ConnectorStatus,
  ConnectorHealthStatus,
  ConnectorType,
} from './models/connectorModels';
import {
  Response,
  handleApiPayload,
  handleApiError,
} from '@hum/common/src/api/response';

export const createConnectorsAPI = ({ client }: CreateAPIOptions) => ({
  createConnector: async (
    globalCompanyId: number,
    globalConnectorId: number,
    companyName: string,
    connectorType: ConnectorType
  ): Promise<Response<Connector>> => {
    return client
      .post(`/v1/companies/${globalCompanyId}/connectors`, {
        global_connector_id: globalConnectorId,
        company_name: companyName,
        connector_type: connectorType,
      })
      .then(normalize.connector.in)
      .then((data) => handleApiPayload<Connector>(data))
      .catch((error) => handleApiError<Connector>(error));
  },
  disconnectConnector: async (
    globalCompanyId: number,
    globalConnectorId: number
  ): Promise<Response<Connector>> => {
    return client
      .post(
        `/v1/companies/${globalCompanyId}/connectors/${globalConnectorId}/disconnect`
      )
      .then(normalize.connector.in)
      .then((data) => handleApiPayload<Connector>(data))
      .catch((error) => handleApiError<Connector>(error));
  },
  patchConnector: async (
    connectorId: number | string,
    status: ConnectorStatus,
    healthStatus: ConnectorHealthStatus
  ): Promise<Response<null>> => {
    return client
      .patch(`/v1/connectors/${connectorId}`, {
        status,
        health_status: healthStatus,
      })
      .then((data) => handleApiPayload<null>(data))
      .catch((data) => handleApiError<null>(data));
  },
});
