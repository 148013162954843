import { ReportType } from '@hum/types';
import { CreateAPIOptions } from '.';
import { normalize } from './models';

export type AdminCreateUser = {
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  position?: string;
};

export type AdminUpdateUser = AdminCreateUser & { userId: number };

export const createAdminAPI = ({ client }: CreateAPIOptions) => {
  const createUser = async (user: AdminCreateUser & { companyId: number }) => {
    return await client.post(`users`, normalize.user.out(user), {
      withCredentials: true,
    });
  };

  const updateUser = async ({ userId, ...user }: AdminUpdateUser) => {
    return await client.patch(`user/${userId}`, normalize.user.out(user), {
      withCredentials: true,
    });
  };

  const createCreditBoxReport = async (
    companyId: number,
    reportType: ReportType
  ) => {
    return await client
      .post(`/v1/companies/${companyId}/reports/${reportType}`)
      .then(normalize.creditBoxReport.in);
  };

  const downloadCreditBoxReport = async (
    companyId: number,
    creditBoxReportId: string
  ) => {
    return await client
      .post(
        `/v1/companies/${companyId}/reports/${creditBoxReportId}/download_url`
      )
      .then(normalize.creditBoxReportDownload.in);
  };
  const getCreditBoxReports = async (
    companyId: number,
    reportType: ReportType
  ) => {
    return await client
      .get(`/v1/companies/${companyId}/reports?report_type=${reportType}`)
      .then(({ _items }) =>
        _items.map((item) => {
          return normalize.creditBoxReport.in(item);
        })
      )
      .catch((error) => {
        throw error;
      });
  };
  const getCreditBoxReport = async (companyId: number, reportId: string) => {
    return await client
      .get(`/v1/companies/${companyId}/reports/${reportId}`)
      .then(normalize.creditBoxReport.in);
  };
  return {
    createUser,
    updateUser,
    createCreditBoxReport,
    downloadCreditBoxReport,
    getCreditBoxReport,
    getCreditBoxReports,
  };
};
