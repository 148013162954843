import { CompanyDashboardDisconnectServiceButtonClicked } from '@hum/icm-app/src/actions/companyDashboard';
import { CreateAPIOptions } from '.';

export const createCompanyDashboardAPI = ({ client }: CreateAPIOptions) => {
  const disconnectService = async ({
    companyId,
    connectorId,
  }: CompanyDashboardDisconnectServiceButtonClicked['payload']) => {
    return await client.delete(
      `/companies/${companyId}/connectors/${connectorId}`,
      { withCredentials: true }
    );
  };
  const disconnectAirbyteService = async ({
    companyId,
    connectorId,
  }: CompanyDashboardDisconnectServiceButtonClicked['payload']) => {
    return await client.delete(
      `/companies/${companyId}/airbyte/connectors/${connectorId}`,
      { withCredentials: true }
    );
  };
  const disconnectCodatService = async ({
    companyId,
    connectorId,
  }: CompanyDashboardDisconnectServiceButtonClicked['payload']) => {
    return await client.post(
      `/companies/${companyId}/connectors/${connectorId}/disconnect`,
      { withCredentials: true }
    );
  };
  const serviceStartDataPull = async ({
    companyId,
    connectorId,
  }: CompanyDashboardDisconnectServiceButtonClicked['payload']) => {
    return await client
      .post(
        `/companies/${companyId}/connectors/${connectorId}/connector_task`,
        { is_test: false }, // TODO: document this on the API, why do we need to send test=false?
        { withCredentials: true }
      )
      .then((r) => {
        if (r?.group_id) return { groupId: r?.group_id };
        return r;
      })
      .catch((e) => {
        throw new Error(e);
      });
  };
  const serviceStartDataPullAirbyte = async ({
    companyId,
    connectorId,
  }: CompanyDashboardDisconnectServiceButtonClicked['payload']) => {
    return await client
      .post(
        `/companies/${companyId}/airbyte/connectors/${connectorId}/connector_task`,
        { is_test: false }, // TODO: document this on the API, why do we need to send test=false?
        { withCredentials: true }
      )
      .then((r) => ({ groupId: r?.group_id }));
  };
  return {
    disconnectService,
    disconnectAirbyteService,
    disconnectCodatService,
    serviceStartDataPull,
    serviceStartDataPullAirbyte,
  };
};
