import { User } from '@hum/types';
import {
  BaseAction,
  BaseCompanyAction,
  BaseRequestStateChanged,
  actionCreator,
} from '@hum/common';
import {
  AdminCreateUser,
  AdminUpdateUser,
} from '@hum/icm-app/src/backend/api/admin';
import { SignUpV5Form } from '../state';

export enum AdminActionType {
  ADMIN_COMPANY_USERS_HOOK_USED = 'ADMIN_COMPANY_USERS_HOOK_USED',
  ADMIN_COMPANY_USERS_STATE_CHANGED = 'ADMIN_COMPANY_USERS_STATE_CHANGED',
  ADMIN_COMPANY_USERS_FAILED = 'ADMIN_COMPANY_USERS_FAILED',
  ADMIN_COMPANY_SYNDICATION_DISABLED_TOGGLED = 'ADMIN_COMPANY_SYNDICATION_DISABLED_TOGGLED',
  ADMIN_UNWANTED_INVESTOR_LIST_SAVED = 'ADMIN_UNWANTED_INVESTOR_LIST_SAVED',
  ADMIN_UPDATE_USER_FORM_SUBMITTED = 'ADMIN_UPDATE_USER_FORM_SUBMITTED',
  ADMIN_UPDATE_USER_FORM_SUBMITTED_SUCCESS = 'ADMIN_UPDATE_USER_FORM_SUBMITTED_SUCCESS',
  ADMIN_UPDATE_USER_FORM_SUBMITTED_FAILED = 'ADMIN_UPDATE_USER_FORM_SUBMITTED_FAILED',
  ADMIN_INVITE_USER_FORM_SUBMITTED = 'ADMIN_INVITE_USER_FORM_SUBMITTED',
  ADMIN_INVITE_USER_FORM_SUBMITTED_SUCCESS = 'ADMIN_INVITE_USER_FORM_SUBMITTED_SUCCESS',
  ADMIN_INVITE_USER_FORM_SUBMITTED_FAILED = 'ADMIN_INVITE_USER_FORM_SUBMITTED_FAILED',
  ADMIN_ADD_COMPANY_FORM_SUBMITTED = 'ADMIN_ADD_COMPANY_FORM_SUBMITTED',
  ADMIN_ADD_COMPANY_FORM_SUBMITTED_SUCCESS = 'ADMIN_ADD_COMPANY_FORM_SUBMITTED_SUCCESS',
  ADMIN_ADD_COMPANY_FORM_SUBMITTED_FAIL = 'ADMIN_ADD_COMPANY_FORM_SUBMITTED_FAIL',
  ADMIN_ADD_MNDA_FILE_SUBMITTED = 'ADMIN_ADD_MNDA_FILE_SUBMITTED',
  ADMIN_ADD_MNDA_FILE_SUBMITTED_SUCCESS = 'ADMIN_ADD_MNDA_FILE_SUBMITTED_SUCCESS',
  ADMIN_ADD_MNDA_FILE_SUBMITTED_FAIL = 'ADMIN_ADD_MNDA_FILE_SUBMITTED_FAIL',
}

// Company users
export type AdminCompanyUsersHookUsed = BaseCompanyAction<AdminActionType.ADMIN_COMPANY_USERS_HOOK_USED>;
export const adminCompanyUsersHookUsed = actionCreator<AdminCompanyUsersHookUsed>(
  AdminActionType.ADMIN_COMPANY_USERS_HOOK_USED
);

export type AdminCompanyUsersStateChanged = BaseRequestStateChanged<
  AdminActionType.ADMIN_COMPANY_USERS_STATE_CHANGED,
  User[]
>;
export const adminCompanyUsersStateChanged = actionCreator<AdminCompanyUsersStateChanged>(
  AdminActionType.ADMIN_COMPANY_USERS_STATE_CHANGED
);

export type AdminCompanyUsersFailed = BaseAction<
  AdminActionType.ADMIN_COMPANY_USERS_FAILED,
  Error
>;
export const adminCompanyUsersFailed = actionCreator<AdminCompanyUsersFailed>(
  AdminActionType.ADMIN_COMPANY_USERS_FAILED
);

// Company syndication
export type AdminCompanySyndicationEnabledToggled = BaseRequestStateChanged<
  AdminActionType.ADMIN_COMPANY_SYNDICATION_DISABLED_TOGGLED,
  {}
>;
export const adminCompanySyndicationEnabledToggled = actionCreator<AdminCompanySyndicationEnabledToggled>(
  AdminActionType.ADMIN_COMPANY_SYNDICATION_DISABLED_TOGGLED
);

// Unwanted investors page
export type AdminUnwantedInvestorListSaved = BaseAction<AdminActionType.ADMIN_UNWANTED_INVESTOR_LIST_SAVED>;
export const adminUnwantedInvestorListSaved = actionCreator<AdminUnwantedInvestorListSaved>(
  AdminActionType.ADMIN_UNWANTED_INVESTOR_LIST_SAVED
);

export type AdminInviteUserFormSubmitted = BaseAction<
  AdminActionType.ADMIN_INVITE_USER_FORM_SUBMITTED,
  { payload: AdminCreateUser; companyId: number }
>;

export const adminInviteUserFormSubmitted = actionCreator<AdminInviteUserFormSubmitted>(
  AdminActionType.ADMIN_INVITE_USER_FORM_SUBMITTED
);

export type AdminInviteUserFormSubmittedSuccess = BaseAction<AdminActionType.ADMIN_INVITE_USER_FORM_SUBMITTED_SUCCESS>;
export const adminInviteUserFormSubmittedSuccess = actionCreator<AdminInviteUserFormSubmittedSuccess>(
  AdminActionType.ADMIN_INVITE_USER_FORM_SUBMITTED_SUCCESS
);

export type AdminInviteUserFormSubmittedFailed = BaseAction<AdminActionType.ADMIN_INVITE_USER_FORM_SUBMITTED_FAILED>;
export const adminInviteUserFormSubmittedFailed = actionCreator<AdminInviteUserFormSubmittedFailed>(
  AdminActionType.ADMIN_INVITE_USER_FORM_SUBMITTED_FAILED
);

export type AdminUpdateUserFormSubmitted = BaseAction<
  AdminActionType.ADMIN_UPDATE_USER_FORM_SUBMITTED,
  { payload: AdminUpdateUser; companyId: number }
>;
export const adminUpdateUserFormSubmitted = actionCreator<AdminUpdateUserFormSubmitted>(
  AdminActionType.ADMIN_UPDATE_USER_FORM_SUBMITTED
);

export type AdminUpdateUserFormSubmittedSuccess = BaseAction<AdminActionType.ADMIN_UPDATE_USER_FORM_SUBMITTED_SUCCESS>;
export const adminUpdateUserFormSubmittedSuccess = actionCreator<AdminUpdateUserFormSubmittedSuccess>(
  AdminActionType.ADMIN_UPDATE_USER_FORM_SUBMITTED_SUCCESS
);

export type AdminUpdateUserFormSubmittedFailed = BaseAction<
  AdminActionType.ADMIN_UPDATE_USER_FORM_SUBMITTED_FAILED,
  Error
>;
export const adminUpdateUserFormSubmittedFailed = actionCreator<AdminUpdateUserFormSubmittedFailed>(
  AdminActionType.ADMIN_UPDATE_USER_FORM_SUBMITTED_FAILED
);

export type AdminAddCompanyFormSubmitted = BaseAction<
  AdminActionType.ADMIN_ADD_COMPANY_FORM_SUBMITTED,
  { payload: SignUpV5Form }
>;
export const adminAddCompanyFormSubmitted = actionCreator<AdminAddCompanyFormSubmitted>(
  AdminActionType.ADMIN_ADD_COMPANY_FORM_SUBMITTED
);

export type AdminAddCompanyFormSubmittedSuccess = BaseAction<AdminActionType.ADMIN_ADD_COMPANY_FORM_SUBMITTED_SUCCESS>;
export const adminAddCompanyFormSubmittedSuccess = actionCreator<AdminAddCompanyFormSubmittedSuccess>(
  AdminActionType.ADMIN_ADD_COMPANY_FORM_SUBMITTED_SUCCESS
);

export type AdminAddCompanyFormSubmittedFail = BaseAction<
  AdminActionType.ADMIN_ADD_COMPANY_FORM_SUBMITTED_FAIL,
  Error
>;
export const adminAddCompanyFormSubmittedFail = actionCreator<AdminAddCompanyFormSubmittedFail>(
  AdminActionType.ADMIN_ADD_COMPANY_FORM_SUBMITTED_FAIL
);

export type AdminAddMndaFileSubmitted = BaseAction<
  AdminActionType.ADMIN_ADD_MNDA_FILE_SUBMITTED,
  { companyId: number }
>;
export const adminAddMndaFileSubmitted = actionCreator<AdminAddMndaFileSubmitted>(
  AdminActionType.ADMIN_ADD_MNDA_FILE_SUBMITTED
);

export type AdminAddMndaFileSubmittedSuccess = BaseAction<AdminActionType.ADMIN_ADD_MNDA_FILE_SUBMITTED_SUCCESS>;
export const adminAddMndaFileSubmittedSuccess = actionCreator<AdminAddMndaFileSubmittedSuccess>(
  AdminActionType.ADMIN_ADD_MNDA_FILE_SUBMITTED_SUCCESS
);

export type AdminAddMndaFileSubmittedFail = BaseAction<
  AdminActionType.ADMIN_ADD_MNDA_FILE_SUBMITTED_FAIL,
  Error
>;
export const adminAddMndaFileSubmittedFail = actionCreator<AdminAddMndaFileSubmittedFail>(
  AdminActionType.ADMIN_ADD_MNDA_FILE_SUBMITTED_FAIL
);

export type AdminAction =
  | AdminCompanyUsersHookUsed
  | AdminCompanyUsersStateChanged
  | AdminCompanyUsersFailed
  | AdminCompanySyndicationEnabledToggled
  | AdminUnwantedInvestorListSaved
  | AdminUpdateUserFormSubmitted
  | AdminUpdateUserFormSubmittedSuccess
  | AdminUpdateUserFormSubmittedFailed
  | AdminInviteUserFormSubmitted
  | AdminInviteUserFormSubmittedSuccess
  | AdminInviteUserFormSubmittedFailed
  | AdminAddCompanyFormSubmitted
  | AdminAddCompanyFormSubmittedSuccess
  | AdminAddCompanyFormSubmittedFail
  | AdminAddMndaFileSubmitted
  | AdminAddMndaFileSubmittedSuccess
  | AdminAddMndaFileSubmittedFail;
