import {
  Action,
  PM_bulkStatusUpdated,
  PM_companyInviteResponseSent,
  PM_inviteCreated,
  PM_inviteUpdated,
} from '@hum/icm-app/src/actions';
import {
  CompanyPortfolioInvite,
  CompanyPortfolioInviteAction,
  PorfolioMonitoringInviteStatus,
  PortfolioMonitoringInvite,
  PortfolioMonitoringInviteForm,
  PortfolioMonitoringStatusForm,
} from '@hum/icm-app/src/state';
import { toast } from '@hum/common/src/modules/toast';

import { Dispatch } from 'redux';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { request } from './utils';
import { CreateAPIOptions } from '.';

export const createPortfolioMonitoringAPI = ({ client }: CreateAPIOptions) => {
  // get principal investments for admin
  const getPortfolioInvites = async (companyId: number) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/investor/portfolio/invites`, {
          withCredentials: true,
        })
        .then((invites: PortfolioMonitoringInvite[]) => {
          const normalizedInvites = invites.map(normalize.portfolioInvite.in);

          // Sorting is useful for investor and admin, sort by status order
          // prettier-ignore
          const sortedInvites = [
            ...normalizedInvites.filter(({ status }) => status === PorfolioMonitoringInviteStatus.Created),
            ...normalizedInvites.filter(({ status }) => status === PorfolioMonitoringInviteStatus.Pending),
            ...normalizedInvites.filter(({ status }) => status === PorfolioMonitoringInviteStatus.Sent),
            ...normalizedInvites.filter(({ status }) => status === PorfolioMonitoringInviteStatus.Viewed),
            ...normalizedInvites.filter(({ status }) => status === PorfolioMonitoringInviteStatus.Accepted)
          ]

          return sortedInvites;
        });
      return response as PortfolioMonitoringInvite[];
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch invitations');
      return [];
    }
  };

  // get company's pending invites to portfolio
  const getCompanyPortfolioInvites = async (companyId: number) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/invites/awaiting`, {
          withCredentials: true,
        })
        .then((result) => result.map(normalize.companyPortfolioInvite.in));

      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to load portfolio invites.');
      return error;
    }
  };

  // accept or reject an from an investor as a company
  const respondToPortfolioInvite = (dispatch: Dispatch<Action>) => (
    companyId: number,
    inviteId: number,
    action: CompanyPortfolioInviteAction
  ) => {
    return request(dispatch)(
      ({ result }) => PM_companyInviteResponseSent({ companyId, result }),
      async (): Promise<CompanyPortfolioInvite> => {
        try {
          const response = (await client.post(
            `/companies/${companyId}/invites/response`,
            {
              invite_id: inviteId,
              action,
            }
          )) as CompanyPortfolioInvite;

          const successMessages = {
            [CompanyPortfolioInviteAction.Accept]: `You've been added to their investment portfolio.`,
            [CompanyPortfolioInviteAction.Reject]: `You've declined the invite to join their investment portfolio.`,
          };

          toast.success(successMessages[action]);
          return response;
        } catch (error: any) {
          toast.error(error?.message || 'Failed to send invite response.');
          return error;
        }
      }
    );
  };

  const bulkInvestorInvites = async (companyId: number, fileId: number) => {
    try {
      return await client.post(
        `/companies/${companyId}/investor/portfolio/invites/bulk`,
        {
          file_id: fileId,
        },
        {
          withCredentials: true,
        }
      );
    } catch (error: any) {
      toast.error(error?.message || 'Failed to assign correct file');
      return [];
    }
  };

  const createPortfolioInvite = (dispatch: Dispatch<Action>) => (
    companyId: number,
    payload: PortfolioMonitoringInviteForm
  ) => {
    return request(dispatch)(
      (payload) => PM_inviteCreated({ companyId, ...payload }),
      async (): Promise<PortfolioMonitoringInvite> => {
        try {
          const response = await client
            .post(
              `/companies/${companyId}/investor/portfolio/invites`,
              normalize.portfolioInvite.out(payload)
            )
            .then(normalize.portfolioInvite.in);

          toast.success('Company added to queue');

          return response;
        } catch (error: any) {
          toast.error(error?.message || 'Failed to create invite.');
          return error;
        }
      }
    );
  };

  const updatePortfolioInvite = (dispatch: Dispatch<Action>) => (
    companyId: number,
    inviteId: number,
    payload: PortfolioMonitoringInviteForm | PortfolioMonitoringStatusForm
  ) => {
    return request(dispatch)(
      ({ result }) => PM_inviteUpdated({ companyId, result }),
      async (): Promise<PortfolioMonitoringInvite> => {
        try {
          const response = await client
            .patch(
              `/companies/${companyId}/investor/portfolio/invites/${inviteId}`,
              normalize.portfolioInvite.out(payload)
            )
            .then(normalize.portfolioInvite.in);

          toast.success('Company successfully edited');

          return response;
        } catch (error: any) {
          toast.error(error?.message || 'Failed to update invite.');
          return error;
        }
      }
    );
  };

  const bulkCompanyStatusUpdate = (dispatch: Dispatch<Action>) => (
    companyId: number,
    payload: number[]
  ) => {
    return request(dispatch)(
      (result) => PM_bulkStatusUpdated({ companyId, result }),
      async (): Promise<PortfolioMonitoringInvite> => {
        try {
          const response = await client.post(
            `/companies/${companyId}/investor/portfolio/invites/send`,
            { investor_invites: payload }
          );

          toast.success('Company invites sent');

          return response;
        } catch (error: any) {
          toast.error(error?.message || 'Failed to send company invites.');
          return error;
        }
      }
    );
  };

  const getPortfolioShowOnboarding = async (companyId: number) => {
    const path = `companies/${companyId}/investor/portfolio/show_onboarding`;
    try {
      return await client
        .get(path, { withCredentials: true })
        .then(normalize.application.in);
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch on-boarding status');
    }
  };

  const getPortfolioDashboard = async (companyIds: Array<number>) => {
    const path = `companies/kpis`;
    const params = new URLSearchParams();
    companyIds.forEach((id) => {
      params.append('company_id', `${id}`);
    });

    const request = { params };
    try {
      return await client.get(path, request).then((data) => {
        for (const item in data) {
          data[item] = normalize.application.in(data[item]);
        }
        return data;
      });
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch dashboard');
    }
  };

  const getPMConnectorsStatus = async (companyId: number) => {
    const path = `companies/${companyId}/portfolio-connectors`;
    try {
      return await client
        .get(path)
        .then((res) => res.map(normalize.pmConnectorStatus.in));
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || 'Failed to fetch connectors status');
    }
  };

  const getPortfolioCompanyInviteUrl = async (companyId: number) => {
    const path = `/investor/portfolio/${companyId}/invite-url`;
    try {
      return await client
        .get(path, { withCredentials: true })
        .then(normalize.application.in);
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch invite url');
    }
  };

  return {
    createPortfolioMonitoringAPI,
    getPortfolioInvites,
    getCompanyPortfolioInvites,
    respondToPortfolioInvite,
    bulkInvestorInvites,
    createPortfolioInvite,
    updatePortfolioInvite,
    bulkCompanyStatusUpdate,
    getPortfolioShowOnboarding,
    getPortfolioDashboard,
    getPMConnectorsStatus,
    getPortfolioCompanyInviteUrl,
  };
};
