import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '@hum/icm-app/src/state';
import { toast } from '@hum/common/src/modules/toast';
import { useAPI } from '@hum/icm-app/src/hooks/useAPI';

import { apiInvestorDownloadDataroomFileButtonClicked } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';

export const useDownloadFile = (companyId?: number) => {
  const api = useAPI();
  const dispatch = useDispatch();
  const {
    session: { data: currentUser },
  } = useSelector((state: AppState) => state);

  const download = async (fileId: number) => {
    try {
      if (!companyId) {
        throw 'companyId is required';
      }

      const file = await api.legacy.getFile(companyId, fileId);

      dispatch(
        apiInvestorDownloadDataroomFileButtonClicked({
          companyId,
          userId: currentUser?.id,
          fileId,
          documentType: file.file.documentType,
        })
      );

      // open file url in new tab, browser should open/download
      if (file) {
        window.open(file.download_url, '_blank');
      }

      return file;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to download file.');
    }
  };

  return { download };
};
