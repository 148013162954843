import { normalize as models } from './models';
import { onboardingPrefill } from './onboardingModels';
import { companyExtras } from './companyExtrasModels';
import { application } from './applicationModels';
import { connector } from './connectorModels';

const normalize = {
  ...models,
  onboardingPrefill,
  companyExtras,
  application,
  connector,
};

export { normalize };
