import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

export enum CompanyActivationActionType {
  GET_STARTED_DISMISSABLE = 'GET_STARTED_DISMISSABLE',
  GET_STARTED_DISMISS = 'GET_STARTED_DISMISS',

  // analytics
  PAGEVIEW_CONFIDENTIALITY_AGREEMENT = 'PAGEVIEW_CONFIDENTIALITY_AGREEMENT',
  PAGEVIEW_BUSINESS_DETAILS = 'PAGEVIEW_BUSINESS_DETAILS',
  PAGEVIEW_DOCUMENT_DATAROOM = 'PAGEVIEW_DOCUMENT_DATAROOM',
}

export type CompanyActivationDismissable = BaseAction<CompanyActivationActionType.GET_STARTED_DISMISSABLE>;
export const companyActivationDismissable = actionCreator<CompanyActivationDismissable>(
  CompanyActivationActionType.GET_STARTED_DISMISSABLE
);

export type CompanyActivationDismiss = BaseAction<CompanyActivationActionType.GET_STARTED_DISMISS>;
export const companyActivationDismiss = actionCreator<CompanyActivationDismiss>(
  CompanyActivationActionType.GET_STARTED_DISMISS
);

export type PageviewConfidentialityAgreement = BaseAction<CompanyActivationActionType.PAGEVIEW_CONFIDENTIALITY_AGREEMENT>;
export const pageviewConfidentialityAgreement = actionCreator<PageviewConfidentialityAgreement>(
  CompanyActivationActionType.PAGEVIEW_CONFIDENTIALITY_AGREEMENT
);

export type PageviewBusinessDetails = BaseAction<CompanyActivationActionType.PAGEVIEW_BUSINESS_DETAILS>;
export const pageviewBusinessDetails = actionCreator<PageviewBusinessDetails>(
  CompanyActivationActionType.PAGEVIEW_BUSINESS_DETAILS
);

export type PageviewDocumentDataroom = BaseAction<CompanyActivationActionType.PAGEVIEW_DOCUMENT_DATAROOM>;
export const pageviewDocumentDataroom = actionCreator<PageviewDocumentDataroom>(
  CompanyActivationActionType.PAGEVIEW_DOCUMENT_DATAROOM
);

export type CompanyActivationAction =
  | CompanyActivationDismissable
  | CompanyActivationDismiss
  | PageviewConfidentialityAgreement
  | PageviewBusinessDetails
  | PageviewDocumentDataroom;
