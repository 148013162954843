import { renameKeys } from '@hum/icm-app/src/backend/api/utils';

export const companyExtras = {
  in: renameKeys({
    legal_name: 'legalName',
    phone_numbers: 'phoneNumbers',
    email_addresses: 'emailAddresses',
    industry_group: 'industryGroup',
    sub_industry: 'subIndustry',
    sic_codes: 'sicCodes',
    naics_code: 'naicsCode',
    country_code: 'countryCode',
    alexa_us_rank: 'alexaRankUsa',
    alexa_global_rank: 'alexaRankGlobal',
    founded_year: 'yearFounded',
    market_cap: 'marketCap',
    annual_revenue: 'annualRevenue',
    linkedin_handle: 'linkedinHandle',
    crunchbase_handle: 'crunchbaseHandle',
    zoominfo_id: 'zoominfoId',
    zoominfo_has_data: 'hasZoominfoData',
    naics_code_1: 'naicsPrimaryCode',
    naics_code_2: 'naicsSecondaryCode',
    naics_name_1: 'naicsPrimaryDescription',
    naics_name_2: 'naicsSecondaryDescription',
  }),
  out: renameKeys({}),
};
