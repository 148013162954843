import { renameKeys } from '@hum/icm-app/src/backend/api/utils';

// RFC: Refactoring ICM Connectors BE
// See: https://www.notion.so/humcapital/RFC-Refactoring-ICM-Connectors-BE-3859724961214c11a8cb862e58a27475
export type TokenAuthParmas = {
  apiKey: string;
};

export type OAuth2Params = {
  code: string;
  state: string;
};

export type XeroRequiredParams = OAuth2Params & {
  url: string;
  clientId: string;
  clientSecret: string;
};

export type QuickbooksRequiredParams = OAuth2Params & {
  realmId: string;
};

export type RequiredParams = XeroRequiredParams | QuickbooksRequiredParams;

export type Field = {
  label: string;
  fieldName: string;
};

export type ConnectorConfig = {
  id: string;
  label: string;
  icon: string;
  connectionType: 'direct' | 'oauth' | 'codat';
  parameters: Field[];
  helpLink?: string;
  description?: string;
  source: 'codat' | 'capital';
  connectorType: 'quickbooks' | 'xero';
  platformType: 'accounting_system' | 'payment_processor' | 'bank';
  enabled: boolean;
};

// Legacy Connector API
export enum ConnectorType {
  Quickbooks = 'quickbooks',
  QuickbooksSandbox = 'quickbooks_sandbox',
  QuickbooksDesktop = 'quickbooks_desktop',
  Netsuite = 'netsuite',
}

export enum ConnectorHealthStatus {
  Critical = 'critical',
  Healthy = 'healhty',
}

export enum ConnectorAuthorizationStatus {
  Active = 'active',
  Pending = 'pending',
  Disconnected = 'disconnected',
  Deauthorized = 'deauthorized',
}

export enum ConnectorStatus {
  Active = 'active',
  Pending = 'pending',
  Disconnected = 'disconnected',
  Deauthorized = 'deauthorized',
}

export type Connector = {
  globalConnectorId: number;
  externalId: string;
  companyId: string;
  connectorType: ConnectorType;
  id: string;
  authorizationStatus: ConnectorAuthorizationStatus;
  healthStatus?: ConnectorHealthStatus; // default: ConnectorHealthStatus.Healthy;
  lastSyncAt?: string;
  createdAt: string;
  linkUrl: string;
};

export type ConnectorConfigList = {
  configs: ConnectorConfig[];
};

export const connector = {
  in: renameKeys({
    global_connector_id: 'globalConnectorId',
    external_id: 'externalId',
    company_id: 'companyId',
    connector_type: 'connectorType',
    connector_source: 'connectorSource',
    authorization_status: 'authorizationStatus',
    health_status: 'healthStatus',
    last_sync_at: 'lastSyncAt',
    created_at: 'createdAt',
    link_url: 'linkUrl',
    // missing properties from frontend/packages/icm-app/src/backend/api/models/models.ts
    connector_health_status: 'health', // legacy status, cannot deprecate
    data_pull_in_progress: 'dataPullInProgress',
    most_recent_underlying_data: 'mostRecentUnderlyingData',
    last_success_pull_at: 'lastSuccessfulPullAt',
    auth_provider_id: 'authProviderId',
  }),
  out: renameKeys({
    globalConnectorId: 'global_connector_id',
    externalId: 'external_id',
    companyId: 'company_id',
    connectorType: 'connector_type',
    authorizationStatus: 'authorization_status',
    healthStatus: 'health_status',
    lastSyncAt: 'last_sync_at',
    createdAt: 'created_at',
    linkUrl: 'link_url',
  }),
};
