import {
  Response,
  handleApiPayload,
  handleApiError,
} from '@hum/common/src/api/response';
import { CreateAPIOptions } from '.';

export const createNoticeAPI = ({ client }: CreateAPIOptions) => {
  const getNoticeWasSeen = (
    companyId: number,
    noticeName: string
  ): Promise<Response<boolean>> => {
    return client
      .get(`/companies/${companyId}/notices/${noticeName}`, {
        withCredentials: true,
        headers: { Pragma: 'no-cache' },
      })
      .then((r) => handleApiPayload(r.exists))
      .catch((e) => handleApiError(e));
  };

  const setNoticeAsSeen = (
    companyId: number,
    noticeName: string
  ): Promise<Response<boolean>> => {
    return client
      .post(`/companies/${companyId}/notices`, {
        name: noticeName,
      })
      .then((r) => handleApiPayload(r.exists))
      .catch((e) => handleApiError(e));
  };

  return {
    getNoticeWasSeen,
    setNoticeAsSeen,
  };
};
