import { datadogLogs } from '@datadog/browser-logs';
import { toast } from '@hum/common/src/modules/toast';
import { normalize } from './models';
import { CreateAPIOptions } from '.';

export type addBalanceSheetPartnerPayload = {
  balanceSheetPartnerCompanyId: string;
};
export type updateStatusPrincipalInvestmentPayload = {
  status: string;
};

export const createPrincipleInvestmentAPI = ({ client }: CreateAPIOptions) => {
  // get principal investments for admin
  const getPrincipalInvestments = async (companyId: number) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/principal-investments`, {
          withCredentials: true,
        })
        .then((r) => r.map(normalize.principalInvestment.in));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch principal investments');
    }
  };

  const createPrincipalInvestment = async (companyId: number) => {
    try {
      const response = await client.post(
        `/companies/${companyId}/principal-investments`,
        {
          withCredentials: true,
        }
      );

      toast.success('Created principal investment!');
      return [response];
    } catch (error: any) {
      console.error(error);
      const message =
        error?.message || 'Failed to create principal investment.';
      toast.error(message);
      datadogLogs.logger.error(message);
    }
  };

  function getAvailableBalanceSheetPartner(companyId: number) {
    return client.get(
      `/companies/${companyId}/principal-investments/balance-sheet-partners/assignable`
    );
  }

  const addBalanceSheetPartnerToPrincipalInvestment = async (
    companyId: number,
    payload: addBalanceSheetPartnerPayload
  ) => {
    try {
      const response = await client.post(
        `/companies/${companyId}/principal-investments/balance-sheet-partners`,
        normalize.balanceSheetPartner.out(payload),
        {
          withCredentials: true,
        }
      );
      toast.success('Added balance sheet partner to principal investment!');
      return response;
    } catch (error: any) {
      toast.error(
        error?.description ||
          error?.message ||
          `Failed to add balance sheet partner to principal investment.`
      );
    }
  };

  const markBalanceSheetPartnerAs = async (
    companyId: number,
    balanceSheetPartnerId: number,
    status: string
  ) => {
    try {
      const payload = {
        status: status,
      };
      await client.patch(
        `/companies/${companyId}/principal-investments/balance-sheet-partners/${balanceSheetPartnerId}`,
        payload,
        {
          withCredentials: true,
        }
      );

      toast.success('balance sheet partner status updated successfully');
    } catch (error: any) {
      toast.error(
        error.message || 'Failed to update status balance sheet partner'
      );
    }
  };

  const updateStatusPrincipalInvestment = async (
    companyId: number,
    payload: updateStatusPrincipalInvestmentPayload
  ) => {
    try {
      await client.patch(
        `/companies/${companyId}/principal-investments`,
        payload,
        {
          withCredentials: true,
        }
      );
      toast.success('Updated status of principal investment!');
    } catch (error: any) {
      console.error(error);
      toast.error(
        error?.description ||
          error?.message ||
          `Failed to updated status of principal investment.`
      );
    }
  };

  return {
    getPrincipalInvestments,
    createPrincipalInvestment,
    getAvailableBalanceSheetPartner,
    addBalanceSheetPartnerToPrincipalInvestment,
    markBalanceSheetPartnerAs,
    updateStatusPrincipalInvestment,
  };
};
