import {
  CompanyChart,
  CompanyFile,
  CompanyType,
  SyndicationInvestorDetails,
  SyndicationMatch,
} from '@hum/types';
import {
  BaseRequestStateChanged,
  BaseCompanyAction,
  actionCreator,
  BaseAction,
} from '@hum/common';
import {
  CompanyDetailsForInvestorInterest,
  CompanyMatchInvestor,
  MatchResponse,
  MatchTocVersion,
  SyndicationLegalTerms,
  SyndicationMatchEvent,
  SyndicationMatchOutReasons,
  SyndicationMatchProfile,
  SyndicationMatchProfile2,
  SyndicationOnboardingMatch,
  SyndicationSectionDeals,
  SyndicationPermissions,
  BlockPermissionsPayloadType,
  AdminSyndicationPermissions,
  MatchOutReasonsPayloadType,
  SyndicationTransactionDocumentUpload,
} from '@hum/icm-app/src/state';
import { Result } from '@hum/common/src/ducks/state';

export enum SyndicationActionType {
  API_SYNDICATION_COMPANY_UPDATED = 'API_SYNDICATION_COMPANY_UPDATED',
  API_SYNDICATION_INVESTOR_CREATED = 'API_SYNDICATION_INVESTOR_CREATED',
  SYNDICATION_RECOMMENDATIONS_HOOK_USED = 'SYNDICATION_RECOMMENDATIONS_HOOK_USED',
  API_SYNDICATION_COMPANY_MATCH_CREATED = 'API_SYNDICATION_COMPANY_MATCH_CREATED',
  API_SYNDICATION_COMPANY_MATCH_UPDATED = 'API_SYNDICATION_COMPANY_MATCH_UPDATED',
  SYNDICATION_INVESTOR_V2_MATCHES_HOOK_USED = 'SYNDICATION_INVESTOR_V2_MATCHES_HOOK_USED',
  API_SYNDICATION_MATCH_REQUEST_STATE_CHANGED = 'API_SYNDICATION_MATCH_REQUEST_STATE_CHANGED',
  SYNDICATION_INVESTOR_V2_MATCHES_REQUEST_STATE_CHANGED = 'SYNDICATION_INVESTOR_V2_MATCHES_REQUEST_STATE_CHANGED',
  API_SYNDICATION_COMPANY_MATCH_DELETED = 'API_SYNDICATION_COMPANY_MATCH_DELETED',
  /**
   * @deprecated
   */
  PRE_INTRO_CHARTS_HOOK_USED = 'PRE_INTRO_CHARTS_HOOK_USED',
  SYNDICATION_INSIGHTS_CHARTS_HOOK_USED = 'SYNDICATION_INSIGHTS_CHARTS_HOOK_USED',
  API_SYNDICATION_COMPANY_INFORMATION_STATE_CHANGED = 'API_SYNDICATION_COMPANY_INFORMATION_STATE_CHANGED',
  SYNDICATION_MATCHES_HOOK_USED = 'SYNDICATION_MATCHES_HOOK_USED',
  SYNDICATION_MATCH_HOOK_USED = 'SYNDICATION_MATCH_HOOK_USED',

  /**
   * @deprecated
   */

  SYNDICATION_ONBOARDING_MATCHES_HOOK_USED = 'SYNDICATION_ONBOARDING_MATCHES_HOOK_USED',
  SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_HOOK_USED = 'SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_HOOK_USED',
  SYNDICATION_EXISTING_PROOF_FILES_HOOK_USED = 'SYNDICATION_EXISTING_PROOF_FILES_HOOK_USED',
  SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_HOOK_USED = 'SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_HOOK_USED',
  SYNDICATION_SEND_MATCH_RESPONSE_HOOK_USED = 'SYNDICATION_SEND_MATCH_RESPONSE_HOOK_USED',
  SYNDICATION_SEND_SAW_CARD_HOOK_USED = 'SYNDICATION_SEND_SAW_CARD_HOOK_USED',
  SYNDICATION_INVESTOR_ACCEPTS_LEGAL_CONDITIONS_HOOK_USED = 'SYNDICATION_INVESTOR_ACCEPTS_LEGAL_CONDITIONS_HOOK_USED',
  SYNDICATION_ASSOCIATE_FILE_TO_OUT_OF_FEE_PROOFS_HOOK_USED = 'SYNDICATION_ASSOCIATE_FILE_TO_OUT_OF_FEE_PROOFS_HOOK_USED',
  /**
   * @deprecated
   */
  SYNDICATION_ONBOARDING_RESULTS_HOOK_USED = 'SYNDICATION_ONBOARDING_RESULTS_HOOK_USED',
  SYNDICATION_MATCH_EVENTS_HOOK_USED = 'SYNDICATION_MATCH_EVENTS_HOOK_USED',
  SYNDICATION_MATCH_OUT_REASONS_HOOK_USED = 'SYNDICATION_MATCH_OUT_REASONS_HOOK_USED',
  SYNDICATION_LOAD_OUT_OF_FEE_FILES_HOOK_USED = 'SYNDICATION_LOAD_OUT_OF_FEE_FILES_HOOK_USED',
  SYNDICATION_MATCH_DATAROOM_HOOK_USED = 'SYNDICATION_MATCH_DATAROOM_HOOK_USED',
  API_SYNDICATION_RECOMMENDATIONS_STATE_CHANGED = 'API_SYNDICATION_RECOMMENDATIONS_STATE_CHANGED',
  API_PRE_INTRO_CHARTS_STATE_CHANGED = 'API_PRE_INTRO_CHARTS_STATE_CHANGED',
  API_SYNDICATION_INSIGHTS_CHARTS_STATE_CHANGED = 'API_SYNDICATION_INSIGHTS_CHARTS_STATE_CHANGED',
  API_SYNDICATION_MATCHES_STATE_CHANGED = 'API_SYNDICATION_MATCHES_STATE_CHANGED',
  API_SYNDICATION_ONBOARDING_MATCHES_STATE_CHANGED = 'API_SYNDICATION_ONBOARDING_MATCHES_STATE_CHANGED',
  API_SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_STATE_CHANGED = 'API_SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_STATE_CHANGED',
  API_SYNDICATION_EXISTING_PROOF_FILES_STATE_CHANGED = 'API_SYNDICATION_EXISTING_PROOF_FILES_STATE_CHANGED',
  API_SYNDICATION_MATCH_TOC_VERSION_STATE_CHANGED = 'API_SYNDICATION_MATCH_TOC_VERSION_STATE_CHANGED',
  API_SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_STATE_CHANGED = 'API_SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_STATE_CHANGED',
  API_SYNDICATION_SEND_MATCH_RESPONSE_STATE_CHANGED = 'API_SYNDICATION_SEND_MATCH_RESPONSE_STATE_CHANGED',
  API_SYNDICATION_ONBOARDING_RESULTS_STATE_CHANGED = 'API_SYNDICATION_ONBOARDING_RESULTS_STATE_CHANGED',
  API_SYNDICATION_MATCH_EVENTS_STATE_CHANGED = 'API_SYNDICATION_MATCH_EVENTS_STATE_CHANGED',
  API_SYNDICATION_MATCH_OUT_REASONS_STATE_CHANGED = 'API_SYNDICATION_MATCH_OUT_REASONS_STATE_CHANGED',
  API_SYNDICATION_LOAD_OUT_OF_FEE_FILES_STATE_CHANGED = 'API_SYNDICATION_LOAD_OUT_OF_FEE_FILES_STATE_CHANGED',
  API_SYNDICATION_MATCH_DATAROOM_STATE_CHANGED = 'API_SYNDICATION_MATCH_DATAROOM_STATE_CHANGED',

  SYNDICATION_MATCH_PROFILE_HOOK_USED = 'SYNDICATION_MATCH_PROFILE_HOOK_USED',
  SYNDICATION_MATCH_PROFILE_STATE_CHANGED = 'SYNDICATION_MATCH_PROFILE_STATE_CHANGED',

  SYNDICATION_MATCH_PROFILE_ANALYTICS_HOOK_USED = 'SYNDICATION_MATCH_PROFILE_ANALYTICS_HOOK_USED',
  API_SYNDICATION_MATCH_PROFILE_ANALYTICS_STATE_CHANGED = 'API_SYNDICATION_MATCH_PROFILE_ANALYTICS_STATE_CHANGED',

  API_SYNDICATION_MATCHES_FOR_GUEST_STATE_CHANGED = 'API_SYNDICATION_MATCHES_FOR_GUEST_STATE_CHANGED',

  /**
   * @deprecated
   */
  SYNDICATION_ONBOARDING_MATCHES_FOR_GUEST_HOOK_USED = 'SYNDICATION_ONBOARDING_MATCHES_FOR_GUEST_HOOK_USED',

  MATCH_FEEDBACK_SENT = 'MATCH_FEEDBACK_SENT',

  REQUEST_INTRODUCTION = 'REQUEST_INTRODUCTION',

  PERMISSIONS_CHECK_HOOK_USED = 'PERMISSIONS_CHECK_HOOK_USED',
  PERMISSIONS_CHECK_STATE_CHANGED = 'PERMISSIONS_CHECK_STATE_CHANGED',
  PERMISSIONS_STATE_CHANGED = 'PERMISSIONS_STATE_CHANGED',
  PERMISSIONS_ADMIN_STATE_CHANGED = 'PERMISSIONS_ADMIN_STATE_CHANGED',

  ADMIN_CLICKED_SEND_INTROS = 'ADMIN_CLICKED_SEND_INTROS',
  ADMIN_SUCCESSFULLY_SENT_INTROS = 'ADMIN_SUCCESSFULLY_SENT_INTROS',
  ADMIN_FAILED_TO_SEND_INTROS = 'ADMIN_FAILED_TO_SEND_INTROS',

  SYNDICATION_INVESTOR_DETAILS_HOOK_USED = 'SYNDICATION_INVESTOR_DETAILS_HOOK_USED',
  SYNDICATION_INVESTOR_DETAILS_STATE_CHANGED = 'SYNDICATION_INVESTOR_DETAILS_STATE_CHANGED',

  SEND_TRANSACTION_DOCUMENT_BUTTON_CLICKED = 'SEND_TRANSACTION_DOCUMENT_BUTTON_CLICKED',
  TRANSACTION_DOCUMENT_UPLOAD_RESPONSE = 'TRANSACTION_DOCUMENT_UPLOAD_RESPONSE',
  TRANSACTION_DOCUMENT_UPLOAD_EMAIL_SENT = 'TRANSACTION_DOCUMENT_UPLOAD_EMAIL_SENT',

  USER_CLICKED_SEND_TO_ARCHIVE_BUTTON = 'USER_CLICKED_SEND_TO_ARCHIVE_BUTTON',
  SUCCESSFULLY_SENT_DEALS_TO_ARCHIVE = 'SUCCESSFULLY_SENT_DEALS_TO_ARCHIVE',
  FAILED_TO_SENT_DEALS_TO_ARCHIVE = 'FAILED_TO_SENT_DEALS_TO_ARCHIVE',

  NOTIFY_USER_COMPANY_IS_FUNDRAISING = 'NOTIFY_USER_COMPANY_IS_FUNDRAISING',

  SYNDICATION_CONTACT_SALES_CLICKED = 'SYNDICATION_CONTACT_SALES_CLICKED',
}

export type ApiSyndicationCompanyUpdated = BaseCompanyAction<
  SyndicationActionType.API_SYNDICATION_COMPANY_UPDATED,
  { companyId: number }
>;

export type ApiSyndicationInvestorCreated = BaseAction<SyndicationActionType.API_SYNDICATION_INVESTOR_CREATED>;

export type SyndicationRecommendationsHookUsed = BaseCompanyAction<SyndicationActionType.SYNDICATION_RECOMMENDATIONS_HOOK_USED>;

export type ApiSyndicationCompanyMatchCreated = BaseAction<
  SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_CREATED,
  { result: Result<{}>; companyId: number }
>;

export type ApiSyndicationCompanyMatchDeleted = BaseAction<
  SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_DELETED,
  { companyId: number; matchId: number }
>;

export type ApiSyndicationCompanyMatchUpdated = BaseCompanyAction<
  SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_UPDATED,
  { result: Result<{}> }
>;

export type SyndicationMatchesHookUsed = BaseCompanyAction<SyndicationActionType.SYNDICATION_MATCHES_HOOK_USED>;

export type SyndicationMatchesV2HookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_MATCHES_HOOK_USED,
  { sectionDeals: SyndicationSectionDeals }
>;

export type PreIntroChartsHookUsed = BaseAction<
  SyndicationActionType.PRE_INTRO_CHARTS_HOOK_USED,
  { matchUuid: string; companyAnalysisId?: number }
>;

export type SyndicationInsightsChartsHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_INSIGHTS_CHARTS_HOOK_USED,
  {
    companyId: number;
    companyAnalysisId?: number;
  }
>;

export type SyndicationOnboardingMatchesHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_ONBOARDING_MATCHES_HOOK_USED,
  { companyType: CompanyType[] }
>;
export type SyndicationExistingProofFilesHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_EXISTING_PROOF_FILES_HOOK_USED,
  { matchUuid: string }
>;

export type SyndicationInvestorV2MatchesHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_INVESTOR_V2_MATCHES_HOOK_USED,
  { sectionDeals: SyndicationSectionDeals }
>;
export type SyndicationMatchOutReasonsHookUsed = BaseAction<SyndicationActionType.SYNDICATION_MATCH_OUT_REASONS_HOOK_USED>;
export type SyndicationInvestorShowsInterestInCompanyHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_HOOK_USED,
  { matchUuid: string }
>;

export type SyndicationSendSawCardHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_SEND_SAW_CARD_HOOK_USED,
  { companyType: CompanyType[]; matchUuid: string }
>;

export type SyndicationInvestorAcceptsLegalConditionsHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_INVESTOR_ACCEPTS_LEGAL_CONDITIONS_HOOK_USED,
  {
    payload: { [id: string]: any };
  }
>;

export type SyndicationConditionsNeedingAcceptanceHookUsed = BaseCompanyAction<SyndicationActionType.SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_HOOK_USED>;

export type SyndicationLoadOutOfFeeFilesHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_LOAD_OUT_OF_FEE_FILES_HOOK_USED,
  { matchId: number }
>;

export type SyndicationMatchDataroomHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_MATCH_DATAROOM_HOOK_USED,
  { companyId: number }
>;

export type SyndicationAssociateFileToOutOfFeeProofsHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_ASSOCIATE_FILE_TO_OUT_OF_FEE_PROOFS_HOOK_USED,
  { companyId: number; matchUuid: string; createdFileId: number }
>;

export type SyndicationSendMatchResponseHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_SEND_MATCH_RESPONSE_HOOK_USED,
  { companyType: CompanyType[]; payload: { [id: string]: any } }
>;
export type SyndicationOnboardingResultsHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_ONBOARDING_RESULTS_HOOK_USED,
  { companyType: CompanyType[] }
>;
export type SyndicationMatchHookUsed = BaseAction<
  SyndicationActionType.SYNDICATION_MATCH_HOOK_USED,
  {
    companyId: number;
    uuid: string;
  }
>;
export type SyndicationMatchEventsHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_MATCH_EVENTS_HOOK_USED,
  { matchId: number }
>;

export type ApiSyndicationRecommendationsStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_RECOMMENDATIONS_STATE_CHANGED,
  SyndicationInvestorDetails[]
>;
export type ApiSyndicationMatchOutReasons = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_MATCH_OUT_REASONS_STATE_CHANGED,
  SyndicationMatchOutReasons[]
>;
export type ApiSyndicationMatchesStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_MATCHES_STATE_CHANGED,
  SyndicationMatch[]
>;

export type ApiPreIntroChartsStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_PRE_INTRO_CHARTS_STATE_CHANGED,
  CompanyChart[]
>;

export type ApiSyndicationInsigthsChartsStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_INSIGHTS_CHARTS_STATE_CHANGED,
  CompanyChart[]
>;

export type ApiSyndicationOnboardingMatchesStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_ONBOARDING_MATCHES_STATE_CHANGED,
  SyndicationOnboardingMatch[]
>;

export type ApiSyndicationConditionsNeedingAcceptanceStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_STATE_CHANGED,
  SyndicationLegalTerms
>;

export type ApiSyndicationLoadOutOfFeeFilesStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_LOAD_OUT_OF_FEE_FILES_STATE_CHANGED,
  CompanyFile[]
>;

export type ApiSyndicationMatchDataroomStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_MATCH_DATAROOM_STATE_CHANGED,
  CompanyFile[]
>;

export type ApiSyndicationExistingProofFilesStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_EXISTING_PROOF_FILES_STATE_CHANGED,
  CompanyFile[]
>;

export type ApiSyndicationMatchTocVersionStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_MATCH_TOC_VERSION_STATE_CHANGED,
  MatchTocVersion
>;

export type ApiSyndicationInvestorShowsInterestInCompanyStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_STATE_CHANGED,
  CompanyDetailsForInvestorInterest
>;

export type ApiSyndicationMatchRequestStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_MATCH_REQUEST_STATE_CHANGED,
  SyndicationMatchProfile2
>;

export type ApiSyndicationSendMatchResponseStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_SEND_MATCH_RESPONSE_STATE_CHANGED,
  MatchResponse
>;

export type ApiSyndicationOnboardingResultsStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_ONBOARDING_RESULTS_STATE_CHANGED,
  SyndicationOnboardingMatch[]
>;

export type ApiSyndicationMatchEventsStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_MATCH_EVENTS_STATE_CHANGED,
  SyndicationMatchEvent,
  { matchId: number }
>;

export type SyndicationOnboardingMatchesForGuestHookUsed = BaseAction<
  SyndicationActionType.SYNDICATION_ONBOARDING_MATCHES_FOR_GUEST_HOOK_USED,
  { token: string }
>;

export type ApiSyndicationOnboardingMatchesForGuestStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_MATCHES_FOR_GUEST_STATE_CHANGED,
  SyndicationOnboardingMatch[]
>;

export type SyndicationMatchProfileHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_MATCH_PROFILE_HOOK_USED,
  { companyType: CompanyType[]; uuid: string }
>;

export type ApiSyndicationMatchProfileStateChanged = BaseRequestStateChanged<
  SyndicationActionType.SYNDICATION_MATCH_PROFILE_STATE_CHANGED,
  SyndicationMatchProfile
>;

export type SyndicationMatchProfileAnalyticsHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_MATCH_PROFILE_ANALYTICS_HOOK_USED,
  { uuid: string; companyAnalysisId?: number }
>;

export type ApiSyndicationMatchProfileAnalyticsStateChanged = BaseRequestStateChanged<
  SyndicationActionType.API_SYNDICATION_MATCH_PROFILE_ANALYTICS_STATE_CHANGED,
  CompanyChart[]
>;

export type ApiSyndicationCompanyInformationStateChanged = BaseCompanyAction<
  SyndicationActionType.API_SYNDICATION_COMPANY_INFORMATION_STATE_CHANGED,
  { result: Result<{}> }
>;

export type PermissionsCheckHookUsed = BaseCompanyAction<
  SyndicationActionType.PERMISSIONS_CHECK_HOOK_USED,
  { investorId?: number; adminMatchPermissions?: boolean }
>;

export type PermissionsCheckStateChanged = BaseRequestStateChanged<
  SyndicationActionType.PERMISSIONS_CHECK_STATE_CHANGED,
  SyndicationPermissions
>;

export type PermissionsAdminStateChanged = BaseRequestStateChanged<
  SyndicationActionType.PERMISSIONS_ADMIN_STATE_CHANGED,
  AdminSyndicationPermissions
>;

export type PermissionsStateChanged = BaseRequestStateChanged<
  SyndicationActionType.PERMISSIONS_STATE_CHANGED,
  BlockPermissionsPayloadType
>;

export type AdminClickedSendIntros = BaseCompanyAction<
  SyndicationActionType.ADMIN_CLICKED_SEND_INTROS,
  { adminMatchPermissions: boolean; matchIds: number[] }
>;

export type AdminSuccessfullySentIntros = BaseAction<SyndicationActionType.ADMIN_SUCCESSFULLY_SENT_INTROS>;
export type AdminFailedToSendIntros = BaseAction<SyndicationActionType.ADMIN_FAILED_TO_SEND_INTROS>;

export type SyndicationInvestorDetailsHookUsed = BaseCompanyAction<
  SyndicationActionType.SYNDICATION_INVESTOR_DETAILS_HOOK_USED,
  { matchUuid: string }
>;

export type SyndicationInvestorDetailsStateChanged = BaseRequestStateChanged<
  SyndicationActionType.SYNDICATION_INVESTOR_DETAILS_STATE_CHANGED,
  CompanyMatchInvestor
>;

export type MatchFeedbackSent = BaseCompanyAction<
  SyndicationActionType.MATCH_FEEDBACK_SENT,
  { result: Result<MatchResponse | undefined>; companyId: number }
>;

export type RequestIntroductionChanged = BaseCompanyAction<
  SyndicationActionType.REQUEST_INTRODUCTION,
  { result: Result<SyndicationMatchProfile2 | undefined>; companyId: number }
>;

export type ApiSendDealToArchive = BaseAction<
  SyndicationActionType.USER_CLICKED_SEND_TO_ARCHIVE_BUTTON,
  {
    companyId: number;
    matchUuid: string;
    userType: string;
    reasons: MatchOutReasonsPayloadType;
  }
>;
export type SuccessfullySentDealToArchive = BaseAction<SyndicationActionType.SUCCESSFULLY_SENT_DEALS_TO_ARCHIVE>;
export type FailedSentDealToArchive = BaseAction<SyndicationActionType.FAILED_TO_SENT_DEALS_TO_ARCHIVE>;

export type ApiCompanyIsFundraising = BaseAction<
  SyndicationActionType.NOTIFY_USER_COMPANY_IS_FUNDRAISING,
  { companyId: number; matchUuid: string; isFundraising: boolean }
>;

export type SyndicationContactSalesClicked = BaseAction<
  SyndicationActionType.SYNDICATION_CONTACT_SALES_CLICKED,
  {}
>;

export const apiSyndicationCompanyUpdated = actionCreator<ApiSyndicationCompanyUpdated>(
  SyndicationActionType.API_SYNDICATION_COMPANY_UPDATED
);

export const apiSyndicationInvestorCreated = actionCreator<ApiSyndicationInvestorCreated>(
  SyndicationActionType.API_SYNDICATION_INVESTOR_CREATED
);

export const syndicationRecommendationsHookUsed = actionCreator<SyndicationRecommendationsHookUsed>(
  SyndicationActionType.SYNDICATION_RECOMMENDATIONS_HOOK_USED
);

export const apiSyndicationCompanyMatchCreated = actionCreator<ApiSyndicationCompanyMatchCreated>(
  SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_CREATED
);

export const apiSyndicationCompanyMatchDeleted = actionCreator<ApiSyndicationCompanyMatchDeleted>(
  SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_DELETED
);

export const apiSyndicationCompanyMatchUpdated = actionCreator<ApiSyndicationCompanyMatchUpdated>(
  SyndicationActionType.API_SYNDICATION_COMPANY_MATCH_UPDATED
);

export const syndicationMatchesHookUsed = actionCreator<SyndicationMatchesHookUsed>(
  SyndicationActionType.SYNDICATION_MATCHES_HOOK_USED
);

/**
 * @deprecated
 */
export const preIntroChartsHookUsed = actionCreator<PreIntroChartsHookUsed>(
  SyndicationActionType.PRE_INTRO_CHARTS_HOOK_USED
);

export const syndicationInsightsChartsHookUsed = actionCreator<SyndicationInsightsChartsHookUsed>(
  SyndicationActionType.SYNDICATION_INSIGHTS_CHARTS_HOOK_USED
);

export const syndicationOnboardingMatchesHookUsed = actionCreator<SyndicationOnboardingMatchesHookUsed>(
  SyndicationActionType.SYNDICATION_ONBOARDING_MATCHES_HOOK_USED
);
export const syndicationMatchOutReasonsHookUsed = actionCreator<SyndicationMatchOutReasonsHookUsed>(
  SyndicationActionType.SYNDICATION_MATCH_OUT_REASONS_HOOK_USED
);
export const apiSyndicationMatchOutReasonsStateChanged = actionCreator<ApiSyndicationMatchOutReasons>(
  SyndicationActionType.API_SYNDICATION_MATCH_OUT_REASONS_STATE_CHANGED
);
export const syndicationConditionsNeedingAcceptanceHookUsed = actionCreator<SyndicationConditionsNeedingAcceptanceHookUsed>(
  SyndicationActionType.SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_HOOK_USED
);
export const apiSyndicationMatchRequestStateChanged = actionCreator<ApiSyndicationMatchRequestStateChanged>(
  SyndicationActionType.API_SYNDICATION_MATCH_REQUEST_STATE_CHANGED
);

export const syndicationLoadOutOfFeeFilesHookUsed = actionCreator<SyndicationLoadOutOfFeeFilesHookUsed>(
  SyndicationActionType.SYNDICATION_LOAD_OUT_OF_FEE_FILES_HOOK_USED
);

export const syndicationMatchDataroomHookUsed = actionCreator<SyndicationMatchDataroomHookUsed>(
  SyndicationActionType.SYNDICATION_MATCH_DATAROOM_HOOK_USED
);

export const syndicationExistingProofFilesHookUsed = actionCreator<SyndicationExistingProofFilesHookUsed>(
  SyndicationActionType.SYNDICATION_EXISTING_PROOF_FILES_HOOK_USED
);

export const syndicationInvestorV2MatchesHookUsed = actionCreator<SyndicationInvestorV2MatchesHookUsed>(
  SyndicationActionType.SYNDICATION_INVESTOR_V2_MATCHES_HOOK_USED
);

export const syndicationInvestorShowsInterestInCompanyHookUsed = actionCreator<SyndicationInvestorShowsInterestInCompanyHookUsed>(
  SyndicationActionType.SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_HOOK_USED
);

export const syndicationSendMatchResponseHookUsed = actionCreator<SyndicationSendMatchResponseHookUsed>(
  SyndicationActionType.SYNDICATION_SEND_MATCH_RESPONSE_HOOK_USED
);

export const syndicationSendSawCardHookUsed = actionCreator<SyndicationSendSawCardHookUsed>(
  SyndicationActionType.SYNDICATION_SEND_SAW_CARD_HOOK_USED
);
export const syndicationMatchHookUsed = actionCreator<SyndicationMatchHookUsed>(
  SyndicationActionType.SYNDICATION_MATCH_HOOK_USED
);

export const syndicationInvestorAcceptsLegalConditionsHookUsed = actionCreator<SyndicationInvestorAcceptsLegalConditionsHookUsed>(
  SyndicationActionType.SYNDICATION_INVESTOR_ACCEPTS_LEGAL_CONDITIONS_HOOK_USED
);

export const syndicationAssociateFileToOutOfFeeProofsHookUsed = actionCreator<SyndicationAssociateFileToOutOfFeeProofsHookUsed>(
  SyndicationActionType.SYNDICATION_ASSOCIATE_FILE_TO_OUT_OF_FEE_PROOFS_HOOK_USED
);

/**
 * @deprecated
 */
export const syndicationOnboardingResultsHookUsed = actionCreator<SyndicationOnboardingResultsHookUsed>(
  SyndicationActionType.SYNDICATION_ONBOARDING_RESULTS_HOOK_USED
);

export const syndicationMatchEventsHookUsed = actionCreator<SyndicationMatchEventsHookUsed>(
  SyndicationActionType.SYNDICATION_MATCH_EVENTS_HOOK_USED
);

export const apiSyndicationRecommendationsStateChanged = actionCreator<ApiSyndicationRecommendationsStateChanged>(
  SyndicationActionType.API_SYNDICATION_RECOMMENDATIONS_STATE_CHANGED
);

export const apiSyndicationMatchesStateChanged = actionCreator<ApiSyndicationMatchesStateChanged>(
  SyndicationActionType.API_SYNDICATION_MATCHES_STATE_CHANGED
);

export const apiPreIntroChartsStateChanged = actionCreator<ApiPreIntroChartsStateChanged>(
  SyndicationActionType.API_PRE_INTRO_CHARTS_STATE_CHANGED
);

export const apiSyndicationInsightsChartsStateChanged = actionCreator<ApiSyndicationInsigthsChartsStateChanged>(
  SyndicationActionType.API_SYNDICATION_INSIGHTS_CHARTS_STATE_CHANGED
);

export const apiSyndicationOnboardingMatchesStateChanged = actionCreator<ApiSyndicationOnboardingMatchesStateChanged>(
  SyndicationActionType.API_SYNDICATION_ONBOARDING_MATCHES_STATE_CHANGED
);

export const apiSyndicationConditionsNeedingAcceptanceStateChanged = actionCreator<ApiSyndicationConditionsNeedingAcceptanceStateChanged>(
  SyndicationActionType.API_SYNDICATION_CONDITIONS_NEEDING_ACCEPTANCE_STATE_CHANGED
);

export const apiSyndicationLoadOutOfFeeFilesStateChanged = actionCreator<ApiSyndicationLoadOutOfFeeFilesStateChanged>(
  SyndicationActionType.API_SYNDICATION_LOAD_OUT_OF_FEE_FILES_STATE_CHANGED
);

export const apiSyndicationMatchDataroomStateChanged = actionCreator<ApiSyndicationMatchDataroomStateChanged>(
  SyndicationActionType.API_SYNDICATION_MATCH_DATAROOM_STATE_CHANGED
);

export const apiSyndicationExistingProofFilesStateChanged = actionCreator<ApiSyndicationExistingProofFilesStateChanged>(
  SyndicationActionType.API_SYNDICATION_EXISTING_PROOF_FILES_STATE_CHANGED
);

export const apiSyndicationMatchTocVersionStateChanged = actionCreator<ApiSyndicationMatchTocVersionStateChanged>(
  SyndicationActionType.API_SYNDICATION_MATCH_TOC_VERSION_STATE_CHANGED
);

export const apiSyndicationInvestorShowsInterestInCompanyStateChanged = actionCreator<ApiSyndicationInvestorShowsInterestInCompanyStateChanged>(
  SyndicationActionType.API_SYNDICATION_INVESTOR_SHOWS_INTEREST_IN_COMPANY_STATE_CHANGED
);

export const apiSyndicationSendMatchResponseStateChanged = actionCreator<ApiSyndicationSendMatchResponseStateChanged>(
  SyndicationActionType.API_SYNDICATION_SEND_MATCH_RESPONSE_STATE_CHANGED
);

export const apiSyndicationOnboardingResultsStateChanged = actionCreator<ApiSyndicationOnboardingResultsStateChanged>(
  SyndicationActionType.API_SYNDICATION_ONBOARDING_RESULTS_STATE_CHANGED
);

export const apiSyndicationMatchEventsStateChanged = actionCreator<ApiSyndicationMatchEventsStateChanged>(
  SyndicationActionType.API_SYNDICATION_MATCH_EVENTS_STATE_CHANGED
);

export const syndicationMatchProfileHookUsed = actionCreator<SyndicationMatchProfileHookUsed>(
  SyndicationActionType.SYNDICATION_MATCH_PROFILE_HOOK_USED
);

export const apiSyndicationMatchProfileStateChanged = actionCreator<ApiSyndicationMatchProfileStateChanged>(
  SyndicationActionType.SYNDICATION_MATCH_PROFILE_STATE_CHANGED
);

export const syndicationMatchProfileAnalyticsHookUsed = actionCreator<SyndicationMatchProfileAnalyticsHookUsed>(
  SyndicationActionType.SYNDICATION_MATCH_PROFILE_ANALYTICS_HOOK_USED
);

export const apiSyndicationMatchProfileAnalyticsStateChanged = actionCreator<ApiSyndicationMatchProfileAnalyticsStateChanged>(
  SyndicationActionType.API_SYNDICATION_MATCH_PROFILE_ANALYTICS_STATE_CHANGED
);

export const apiSyndicationMatchesForGuestStateChanged = actionCreator<ApiSyndicationOnboardingMatchesForGuestStateChanged>(
  SyndicationActionType.API_SYNDICATION_MATCHES_FOR_GUEST_STATE_CHANGED
);

/**
 * @deprecated
 */
export const syndicationOnboardingMatchesForGuestHookUsed = actionCreator<SyndicationOnboardingMatchesForGuestHookUsed>(
  SyndicationActionType.SYNDICATION_ONBOARDING_MATCHES_FOR_GUEST_HOOK_USED
);

export const apiSyndicationCompanyInformationStateChanged = actionCreator<ApiSyndicationCompanyInformationStateChanged>(
  SyndicationActionType.API_SYNDICATION_COMPANY_INFORMATION_STATE_CHANGED
);

export const adminClickedSendIntros = actionCreator<AdminClickedSendIntros>(
  SyndicationActionType.ADMIN_CLICKED_SEND_INTROS
);

export const adminSuccessfullySentIntros = actionCreator<AdminSuccessfullySentIntros>(
  SyndicationActionType.ADMIN_SUCCESSFULLY_SENT_INTROS
);
export const adminFailedToSendIntros = actionCreator<AdminFailedToSendIntros>(
  SyndicationActionType.ADMIN_FAILED_TO_SEND_INTROS
);

export const permissionsCheckHookUsed = actionCreator<PermissionsCheckHookUsed>(
  SyndicationActionType.PERMISSIONS_CHECK_HOOK_USED
);

export const permissionsCheckStateChanged = actionCreator<PermissionsCheckStateChanged>(
  SyndicationActionType.PERMISSIONS_CHECK_STATE_CHANGED
);

export const permissionsAdminStateChanged = actionCreator<PermissionsAdminStateChanged>(
  SyndicationActionType.PERMISSIONS_ADMIN_STATE_CHANGED
);

export const permissionsStateChanged = actionCreator<PermissionsStateChanged>(
  SyndicationActionType.PERMISSIONS_STATE_CHANGED
);

export const syndicationInvestorDetailsHookUsed = actionCreator<SyndicationInvestorDetailsHookUsed>(
  SyndicationActionType.SYNDICATION_INVESTOR_DETAILS_HOOK_USED
);

export const syndicationInvestorDetailsStateChanged = actionCreator<SyndicationInvestorDetailsStateChanged>(
  SyndicationActionType.SYNDICATION_INVESTOR_DETAILS_STATE_CHANGED
);

export const matchFeedbackSent = actionCreator<MatchFeedbackSent>(
  SyndicationActionType.MATCH_FEEDBACK_SENT
);

export const requestIntroductionSent = actionCreator<RequestIntroductionChanged>(
  SyndicationActionType.REQUEST_INTRODUCTION
);

export const apiSentDealToArchive = actionCreator<ApiSendDealToArchive>(
  SyndicationActionType.USER_CLICKED_SEND_TO_ARCHIVE_BUTTON
);

export const apiSuccessfullySentDealsToArchive = actionCreator<SuccessfullySentDealToArchive>(
  SyndicationActionType.SUCCESSFULLY_SENT_DEALS_TO_ARCHIVE
);

export const apiFailedSentDealToArchive = actionCreator<FailedSentDealToArchive>(
  SyndicationActionType.FAILED_TO_SENT_DEALS_TO_ARCHIVE
);

export const apiCompanyIsFundraising = actionCreator<ApiCompanyIsFundraising>(
  SyndicationActionType.NOTIFY_USER_COMPANY_IS_FUNDRAISING
);

export type SendTransactionDocumentButtonClicked = BaseCompanyAction<
  SyndicationActionType.SEND_TRANSACTION_DOCUMENT_BUTTON_CLICKED,
  { matchUuid: string }
>;
export const sendTransactionDocumentButtonClicked = actionCreator<SendTransactionDocumentButtonClicked>(
  SyndicationActionType.SEND_TRANSACTION_DOCUMENT_BUTTON_CLICKED
);

export type TransactionDocumentUploadResponse = BaseRequestStateChanged<
  SyndicationActionType.TRANSACTION_DOCUMENT_UPLOAD_RESPONSE,
  SyndicationTransactionDocumentUpload
>;
export const transactionDocumentUploadResponse = actionCreator<TransactionDocumentUploadResponse>(
  SyndicationActionType.TRANSACTION_DOCUMENT_UPLOAD_RESPONSE
);

export type TransactionDocumentUploadEmailSent = BaseCompanyAction<SyndicationActionType.TRANSACTION_DOCUMENT_UPLOAD_EMAIL_SENT>;
export const transactionDocumentEmailSent = actionCreator<TransactionDocumentUploadEmailSent>(
  SyndicationActionType.TRANSACTION_DOCUMENT_UPLOAD_EMAIL_SENT
);

export const syndicationContactSalesClicked = actionCreator<SyndicationContactSalesClicked>(
  SyndicationActionType.SYNDICATION_CONTACT_SALES_CLICKED
);

export type SyndicationAction =
  | ApiSyndicationCompanyUpdated
  | ApiSyndicationInvestorCreated
  | SyndicationRecommendationsHookUsed
  | ApiSyndicationCompanyMatchCreated
  | ApiSyndicationCompanyMatchUpdated
  | ApiSyndicationCompanyMatchDeleted
  | SyndicationMatchesHookUsed
  | ApiSyndicationMatchRequestStateChanged
  | PreIntroChartsHookUsed
  | SyndicationInsightsChartsHookUsed
  | SyndicationOnboardingMatchesHookUsed
  | SyndicationConditionsNeedingAcceptanceHookUsed
  | SyndicationLoadOutOfFeeFilesHookUsed
  | SyndicationMatchDataroomHookUsed
  | SyndicationOnboardingResultsHookUsed
  | SyndicationMatchHookUsed
  | SyndicationExistingProofFilesHookUsed
  | SyndicationMatchEventsHookUsed
  | SyndicationMatchOutReasonsHookUsed
  | SyndicationInvestorShowsInterestInCompanyHookUsed
  | SyndicationSendMatchResponseHookUsed
  | SyndicationSendSawCardHookUsed
  | SyndicationInvestorAcceptsLegalConditionsHookUsed
  | SyndicationAssociateFileToOutOfFeeProofsHookUsed
  | SyndicationInvestorV2MatchesHookUsed
  | ApiSyndicationRecommendationsStateChanged
  | ApiSyndicationMatchesStateChanged
  | ApiPreIntroChartsStateChanged
  | ApiSyndicationInsigthsChartsStateChanged
  | ApiSyndicationOnboardingMatchesStateChanged
  | ApiSyndicationConditionsNeedingAcceptanceStateChanged
  | ApiSyndicationLoadOutOfFeeFilesStateChanged
  | ApiSyndicationMatchDataroomStateChanged
  | ApiSyndicationOnboardingResultsStateChanged
  | ApiSyndicationExistingProofFilesStateChanged
  | ApiSyndicationMatchTocVersionStateChanged
  | ApiSyndicationSendMatchResponseStateChanged
  | ApiSyndicationMatchEventsStateChanged
  | ApiSyndicationMatchOutReasons
  | SyndicationMatchProfileHookUsed
  | ApiSyndicationMatchProfileStateChanged
  | SyndicationOnboardingMatchesForGuestHookUsed
  | ApiSyndicationOnboardingMatchesForGuestStateChanged
  | SyndicationMatchProfileAnalyticsHookUsed
  | ApiSyndicationMatchProfileAnalyticsStateChanged
  | ApiSyndicationCompanyInformationStateChanged
  | MatchFeedbackSent
  | RequestIntroductionChanged
  | PermissionsCheckHookUsed
  | AdminClickedSendIntros
  | AdminSuccessfullySentIntros
  | AdminFailedToSendIntros
  | PermissionsCheckStateChanged
  | PermissionsAdminStateChanged
  | PermissionsStateChanged
  | SyndicationInvestorDetailsHookUsed
  | SyndicationInvestorDetailsStateChanged
  | SendTransactionDocumentButtonClicked
  | TransactionDocumentUploadResponse
  | TransactionDocumentUploadEmailSent
  | ApiSendDealToArchive
  | ApiCompanyIsFundraising
  | SuccessfullySentDealToArchive
  | FailedSentDealToArchive
  | SyndicationContactSalesClicked;
