import { CreateAPIOptions } from '.';
import { normalize } from './models';
import { stripFalsyValues } from './utils';
import { LeverageableAssets } from '@hum/types';

export type FinancialDetailsPayload = {
  fundraisingTimeFrame: string;
  targetRaise?: string; // FF: Remove once OnboardingV4TargetCapitalRaise flag is removed
  lastValuation: number;
  totalEquityRaised: string;
  useOfFunds: string;
  leverageableAssets: [LeverageableAssets] | [];
};

//svb only
export type UserInfo = {
  firstName: string;
  lastName: string;
  companyRole: string;
};

export const createCapitalApplicationAPI = ({ client }: CreateAPIOptions) => {
  const saveFinancialDetails = async (
    companyId: number,
    payload: FinancialDetailsPayload
  ) => {
    const formattedPayload = normalize.financialDetails.out(
      stripFalsyValues(payload)
    );
    return await client.patch(
      `/companies/${companyId}/onboarding`,
      formattedPayload
    );
  };

  const saveUserInfo = async (companyId: number, payload: UserInfo) => {
    const formattedPayload = normalize.userInfo.out(stripFalsyValues(payload));
    return await client.patch(
      `/companies/${companyId}/onboarding`,
      formattedPayload
    );
  };

  return { saveFinancialDetails, saveUserInfo };
};
