import pickBy from 'lodash/pickBy';
import omit from 'lodash/omit';
import {
  FileStatus,
  Company,
  CompanyContact,
  CompanyContactStatus,
  CompanyFile,
  ConnectorStatus,
  ApplicationStatus,
  CompanyType,
} from '@hum/types';
import {
  CompanyDataAsset,
  EMPTY_ARRAY,
  Branding,
  SignUpForm,
  Connector,
  MNDAPayloadType,
  AirbyteConnectorType,
} from '@hum/icm-app/src/state';
import {
  Action,
  apiCompanySignUpRequestStateChanged,
  apiUpdatedCompanyApplication,
  apiUserReviewSessionRequestSent,
  apiUserUpdateRequestStateChanged,
  companySettingsChanged,
} from '@hum/icm-app/src/actions';
import { request } from './utils';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { toast } from '@hum/common/src/modules/toast';
import { Dispatch } from 'redux';
import { createDownloadFile } from '@hum/icm-app/src/modules/files/hooks/downloadFile';
import { ManageInsightsSettingsRequest } from '@hum/icm-app/src/pages/admin/AdminAnalytics/ducks/actions';
import { analytics } from '@hum/common';
import { CreateAPIOptions } from '.';

export type SignupMinimalForm = {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  invitationHash: string | undefined;
  acceptedTOS: boolean;
  subdomain?: string;
  companyRoles?: CompanyType[];
};

export type AnalyticsEvent = {
  name: string;
  email?: string;
  includeRoleScope?: boolean;
  payload?: Object | null;
};

// TODO: There's a typo here 👇
export const creatreLegacyAPI = ({ client }: CreateAPIOptions) => {
  function addConnector(
    companyId: number,
    type: string,
    airbyteEnabled: boolean,
    data: any
  ) {
    if (airbyteEnabled && type in AirbyteConnectorType) {
      return client.post(
        `/companies/${companyId}/airbyte/connectors/${type}`,
        data
      );
    }
    return client.post(`/companies/${companyId}/connectors/${type}`, data);
  }

  async function getCompanyContact(companyId: number) {
    return client
      .get(`/companies/${companyId}/contact`, { withCredentials: true })
      .then(normalize.user.in);
  }

  type AddCompanyUserForm = {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    position?: string;
    channel?: string;
  };

  async function addCompanyUser(
    companyId: number,
    { firstName, lastName, email, phone, position, channel }: AddCompanyUserForm
  ) {
    return client
      .post(
        `users`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          company_id: companyId,
          company_position: position,
          phone: phone,
          channel: channel,
        },
        { withCredentials: true }
      )
      .then((response) => {
        toast.success('Created new user');
        return response;
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to save user');
        return error;
      });
  }

  const updateCompanyUser = async (
    companyId: number,
    userId: number,
    { firstName, lastName, email, phone, position }: AddCompanyUserForm
  ) => {
    return client
      .patch(
        `/users/${userId}`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          company_id: companyId,
          phone: phone,
          company_position: position,
        },
        { withCredentials: true }
      )
      .then((response) => {
        toast.success('Saved user');
        return response;
      })
      .catch((error) => {
        toast.error(error?.message || 'Failed to save user');
        return error;
      });
  };

  async function removeCompanyUser(userId: number) {
    return client.delete(`/users/${userId}`, { withCredentials: true });
  }

  async function getCompanyUsers(companyId: number) {
    return client
      .get(`/companies/${companyId}/users`, { withCredentials: true })
      .then((response) => {
        const users = response.map(normalize.user.in);
        // sort by active, then invited, later disabled
        const usersSorted = [
          ...users.filter(
            ({ status }) => status === CompanyContactStatus.Active
          ),
          ...users.filter(
            ({ status }) => status === CompanyContactStatus.Invited
          ),
          ...users.filter(
            ({ status }) => status === CompanyContactStatus.Disabled
          ),
        ];

        return usersSorted;
      });
  }

  async function adminCreateCompanyV5({ payload }) {
    const { contact, company, application } = payload;
    const lastRaised = application.lastRaised;
    return await client
      .post('/v5/admin_capital_applications', {
        contact: normalize.companyOperator.cleanOut(pickBy(contact)),
        company: normalize.company.out(pickBy(company)),
        application: normalize.application.out(
          pickBy({
            ...application,
            lastRaised:
              lastRaised &&
              new Date(
                `${lastRaised
                  .toString()
                  .slice(0, 2)}-01-${lastRaised.toString().slice(2)}`
              ).toISOString(),
            yearFounded: Number(application.yearFounded),
          })
        ),
      })
      .catch((error: any) => {
        throw error;
      });
  }

  async function adminUploadMnda(companyId: number) {
    return await client
      .post(`/v5/companies/${companyId}/mnda`, { withCredentials: true })
      .catch((error: any) => {
        throw error;
      });
  }

  async function getCompanyInvitations(companyId: number) {
    return client
      .get(`/companies/${companyId}/invitations`, { withCredentials: true })
      .then((invitations) => invitations.map(normalize.companyInvitation.in));
  }

  type AddCompanyInvitationForm = {
    firstName: string;
    lastName: string;
    companyName: string;
    email: string;
  };

  async function removeCompanyInvitation(invitationId: number) {
    return client.delete(`/companies/${invitationId}/invitation`);
  }

  async function addCompanyInvitation(
    companyId: number,
    { firstName, lastName, companyName, email }: AddCompanyInvitationForm
  ) {
    return client.post(`/companies/${companyId}/invite_company`, {
      contact: {
        first_name: firstName,
        last_name: lastName,
        email: email,
      },
      company: {
        name: companyName,
      },
    });
  }
  async function adminEditCompanyFormClosed(
    companyId: number | undefined,
    syndicationInvestor: any,
    company: any
  ) {
    return client.patch(`/companies/${companyId}/syndication/investors`, {
      syndication_investor: syndicationInvestor,
      company: company,
    });
  }
  async function updateCompanyInvitation(
    companyId: number,
    _invitationId: number,
    { firstName, lastName, companyName, email }: AddCompanyInvitationForm
  ) {
    return client.post(`/companies/${companyId}/invite_company`, {
      contact: {
        first_name: firstName,
        last_name: lastName,
        email: email,
      },
      company: {
        name: companyName,
      },
    });
  }

  type UpdateCompanySettingsForm = {
    subdomain: string;
  };

  type UpdateCompanyApplicationForm = {
    companyLocation?: string;
    website?: string;
    yearlyRevenue?: string;
    businessType?: string;
    monthsOfRunway?: string;
    recordSystem?: string;
    paymentProcessor?: string;
    notes?: string;
  };

  const updateCompanyApplication = (dispatch: Dispatch<Action>) => async (
    companyId: number,
    {
      companyLocation,
      website,
      yearlyRevenue,
      businessType,
      monthsOfRunway,
      recordSystem,
      paymentProcessor,
      notes,
    }: UpdateCompanyApplicationForm
  ) => {
    await client.patch(`/companies/${companyId}/capital_application`, {
      company: pickBy(
        {
          website: website,
          country: companyLocation,
          business_type: businessType,
          notes,
        },
        (v) => v != null
      ),
      application: pickBy(
        {
          yearly_revenue: yearlyRevenue,
          months_of_runway: monthsOfRunway,
          record_system: recordSystem,
          payment_processor: paymentProcessor,
        },
        (v) => v != null
      ),
    });

    dispatch(apiUpdatedCompanyApplication({ companyId }));
  };

  async function getCompanySettings(companyId: number): Promise<any> {
    return (
      (await client
        .get(`/companies/${companyId}/settings`, { withCredentials: true })
        .then(normalize.companySettings.in)) || {}
    );
  }

  const updateCompanySettings = (dispatch: Dispatch<Action>) => async (
    companyId: number,
    { subdomain }: UpdateCompanySettingsForm
  ) => {
    const ret = await client.post(`/companies/${companyId}/settings`, {
      subdomain,
    });

    dispatch(companySettingsChanged({ subdomain }));
    return ret;
  };

  type UpdateCompanyProps = {
    name?: string;
    labelingCompleted?: boolean;
    frozenCompanyAnalysisId?: number;
  };

  async function updateCompany(
    companyId: number,
    { name, labelingCompleted, frozenCompanyAnalysisId }: UpdateCompanyProps
  ) {
    return client.patch(
      `/companies/${companyId}`,
      pickBy(
        {
          name,
          labeling_completed: labelingCompleted,
          frozen_company_analysis_id: frozenCompanyAnalysisId,
        },
        (value) => value != null
      )
    );
  }
  async function getChartsByTabName(
    companyId: number,
    tabName: string,
    companyAnalysisId?: number
  ) {
    const params = {};
    if (companyAnalysisId) {
      params['companyAnalysisId'] = companyAnalysisId;
    }
    return client
      .get(`/companies/${companyId}/charts/${tabName}`, {
        withCredentials: true,
        params,
      })
      .then((charts) => charts.map(normalize.chart.in));
  }

  async function getSyndicationInsightsCharts(
    companyId: number,
    companyAnalysisId?: number
  ) {
    const params = {};
    if (companyAnalysisId) {
      params['companyAnalysisId'] = companyAnalysisId;
    }
    return client
      .get(`/companies/${companyId}/insights/charts`, {
        withCredentials: true,
        params,
      })
      .then((insightsCharts) => {
        if (!insightsCharts || Object.keys(insightsCharts).length === 0) {
          return undefined;
        }

        for (const chartCategory in insightsCharts) {
          insightsCharts[chartCategory] = insightsCharts[chartCategory].map(
            normalize.chart.in
          );
        }
        return insightsCharts;
      });
  }

  function getAvailablePortfolioCompanies(companyId: number) {
    return client
      .get(`/companies/${companyId}/portfolio/available/companies`)
      .then((companies) => companies.map(normalize.company.in));
  }

  async function getCompany(companyId: number): Promise<Company> {
    const company = await client.get(`/companies/${companyId}`, {
      withCredentials: true,
    });

    return normalize.company.in(company);
  }

  async function getCompanyTheme(subdomain: string): Promise<Branding> {
    const branding = await client.get(`/appearance/${subdomain}`, {
      withCredentials: true,
    });

    return normalize.branding.in(branding);
  }
  async function getBranding(companyId: number): Promise<Branding> {
    const branding = await client.get(
      `/companies/${companyId}/settings/appearance`,
      {
        withCredentials: true,
      }
    );

    return normalize.branding.in(branding || {});
  }
  async function setBranding(companyId: number, branding: Branding) {
    return client
      .post(
        `/companies/${companyId}/settings/appearance`,
        normalize.branding.out(branding)
      )
      .then((r) => {
        toast.success('Branding changes saved!');
        return normalize.branding.in(r);
      })
      .catch((error) => {
        toast.error(error?.description || 'Failed to save branding changes.');
      });
  }

  async function searchCompanies(filterQuery: string) {
    return client
      .get(`/companies/search?${filterQuery}`, {
        withCredentials: true,
      })
      .then((response) => ({
        companies: response.results.map(normalize.company.in),
        pagination: normalize.pagination.in(response.pagination),
      }));
  }

  function getPortfolioCompanies(companyId: number) {
    return client
      .get(`/companies/${companyId}/portfolio`)
      .then((companies) => companies.map(normalize.company.in));
  }

  const getCompanyDataAssets = async (
    companyId: number
  ): Promise<CompanyDataAsset[]> => {
    // const dataAssets = MOCK_COMPANY_DATA_ASSETS[companyId] || EMPTY_ARRAY
    const dataAssets =
      (await client.get(`/companies/${companyId}/data_assets`, {
        withCredentials: true,
      })) || EMPTY_ARRAY;

    return dataAssets.map(normalize.companyDataAsset.in).filter(Boolean);
  };

  const resendInvite = async (user: CompanyContact) => {
    try {
      const response = await client.post(`otp`, {
        email: user.email,
        action:
          user.status === CompanyContactStatus.Invited
            ? 'SET_PASSWORD'
            : 'RESET_PASSWORD',
      });
      if (response) {
        toast.success(`An email has been sent to ${user.email}`);
      }
    } catch (error: any) {
      toast.error(error?.description || `Failed to send invite.`);
    }
  };

  async function getCompanyFiles(companyId: number) {
    const files =
      (await client.get(`/companies/${companyId}/files`, {
        withCredentials: true,
      })) || EMPTY_ARRAY;

    return files.map(normalize.file.in);
  }

  const deleteFile = async (
    companyId: number,
    fileId: number
  ): Promise<CompanyFile | undefined> => {
    try {
      const removedFile = await client
        .delete(`/companies/${companyId}/files/${fileId}`)
        .then(({ file }) => file)
        .then(normalize.file.in);
      return removedFile;
    } catch (error) {
      throw error;
    }
  };

  const setFileStatus = async (
    companyId: number,
    fileId: number,
    status: FileStatus
  ): Promise<CompanyFile | undefined> => {
    try {
      const file = await client
        .patch(
          `/companies/${companyId}/files/${fileId}`,
          normalize.file.out({ status })
        )
        .then((response) => {
          if (response) {
            return normalize.file.in(response.file);
          } else return null;
        });

      return file;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to set file status.');
    }
  };

  type RemoteDataAssetMappingPostPayload = {
    data_asset_type?: string;
    data_asset_id: number;
    target_taxonomy?: string;
    field_labels: Array<{
      application_range_end?: string;
      application_range_start?: string;
      field_name: string;
      labels: string[];
      splits: number[];
    }>;
  };

  const setDataAssetMap = async (
    companyId: number,
    payload: RemoteDataAssetMappingPostPayload
  ) => {
    const response = await client.post(
      `/companies/${companyId}/data_mappings`,
      payload
    );
    return response;
  };

  const getDataAssetMap = async (
    companyId: number,
    dataAssetId: number
  ): Promise<any> => {
    const response = await client.get(
      `/companies/${companyId}/data_mappings/data_asset/${dataAssetId}`
    );

    const used = {};

    return (response?.field_labels || EMPTY_ARRAY).filter((map: any) => {
      if (used[map.field_name]) {
        return false;
      }

      used[map.field_name] = true;
      return map;
    });
  };
  const getAvailableConnectors = async () => {
    const response = await client.get(`/connectors`);
    return response;
  };

  const getDocuSignConnector = async () => {
    return client
      .get(`/docusign/connector`, { withCredentials: true })
      .then(normalize.docusignConnector.in);
  };

  function addDocuSignConnector(data: any) {
    return client.post(`/docusign/authorization/access_token`, data);
  }

  const saveFileMappingColumns = async (
    companyId: number,
    fileId: number,
    payload: any
  ) => {
    try {
      const file = await client.patch(
        `/companies/${companyId}/files/${fileId}`,
        normalize.file.out({ meta: payload })
      );
      return file;
    } catch (error: any) {
      // TODO - move this logic to saga
      toast.error(error?.message || 'Failed to delete file.');
    }
  };

  type MNDAForm = {
    companyId: number;
    fullName: string;
    businessName: string;
    position: string;
  };

  const addMNDAFields = (_dispatch: Dispatch<Action>) => async ({
    companyId,
    fullName,
    businessName,
    position,
  }: MNDAForm): Promise<string> => {
    const { redirectURL } = await client
      .post(
        `/companies/${companyId}/docusign/mnda`,
        {
          company: {
            name: businessName,
          },
          contact: {
            title: position,
            name: fullName,
          },
        },
        { withCredentials: true }
      )
      .then(normalize.docusignMNDA.in);

    return redirectURL;
  };

  const createDataDump = async (
    companyId: number,
    fileId: number,
    type: string
  ) => {
    try {
      const payload = { data_type: type, source_file_id: fileId };
      const response = client
        .post(`/companies/${companyId}/data_dumps`, payload)
        .then(normalize.dataDump.in);
      return response;
    } catch (error: any) {
      // TODO - move this logic to saga
      toast.error(error?.message || 'Failed to delete file.');
    }
  };

  // this should be called generate but...
  const generateCapitalMachineModel = async (companyId: number) => {
    try {
      const response = await client
        .post(`/companies/${companyId}/capital_machine_models`, {})
        .then(normalize.capitalMachineModel.in);
      return response;
    } catch (error: any) {
      // TODO - move this logic to saga
      toast.error(error?.message || 'Failed to delete file.');
    }
  };

  // Track sign up
  const trackStatus = (
    status: ApplicationStatus,
    email: string | undefined,
    companyId: number
  ) => {
    const trackEvents = {
      [ApplicationStatus.Engage]: 'signup:sent_to_engage',
      [ApplicationStatus.Pass]: 'signup:sent_to_pass',
    };
    const trackEvent = trackEvents[status];

    // @ts-ignore
    analytics.identify(String(email), {
      email,
      company_status: status,
      company_id: companyId,
    });

    // @ts-ignore
    analytics.track(trackEvent, { companyID: companyId });
  };

  // TODO: remove if not used
  const companySignUp = (dispatch: Dispatch<any>) => (payload: SignUpForm) => {
    return request(dispatch)(apiCompanySignUpRequestStateChanged, async () => {
      try {
        const { application, company } = await client
          .post('/capital_applications', {
            contact: normalize.companyOperator.cleanOut(payload.contact),
            company: normalize.company.out(payload.company),
            application: normalize.application.out(payload.application),
            utmParams: normalize.utmParams.out(payload.utmParams as any),
            ...(payload.subdomain ? { subdomain: payload.subdomain } : {}),
            auth_provider: payload.authProvider,
          })
          .then((r) => ({
            application: normalize.application.in({
              ...r.application,
              auth_provider: payload.authProvider,
            }),
            company: normalize.company.in(r.company),
          }));

        toast.success('Application submitted successfully!');

        trackStatus(application.status, payload.contact.email, company.id);

        return { application, company };
      } catch (error: any) {
        toast.error(error?.message || 'Failed to create your application.');
        return error;
      }
    });
  };

  const trackEvent = async ({
    name,
    email,
    includeRoleScope,
    payload,
  }: AnalyticsEvent) => {
    // @ts-ignore
    analytics.track(name);

    await client.post('frontend/events', {
      type: name,
      email,
      include_role_scope: includeRoleScope,
      payload: payload || {},
    });
  };

  const signupMinimal = async ({
    firstName,
    lastName,
    email,
    companyName,
    invitationHash,
    acceptedTOS,
    subdomain,
    companyRoles,
  }: SignupMinimalForm) => {
    return client.post(`capital_applications/minimal`, {
      contact: {
        first_name: firstName,
        last_name: lastName,
        email,
      },
      company: {
        name: companyName,
        accepted_tos: acceptedTOS,
      },
      subdomain,
      roles: companyRoles,
      invitation_hash: invitationHash,
    });
  };

  const calculateCostOfEquity = async (payload: any) => {
    try {
      const response = await client
        .post('/cost_of_equity', normalize.costOfEquityCalculation.out(payload))
        .then(normalize.costOfEquityCalculation.in);

      // @ts-ignore
      analytics.track('coe:submit', payload);

      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to create your application.');
      return error;
    }
  };

  const deleteTenant = async ({ companyId, connectorId, tenantId }: any) => {
    try {
      const response = await client.delete(
        `/companies/${companyId}/connectors/${connectorId}/tenant/${tenantId}`,
        {
          withCredentials: true,
        }
      );
      return response;
    } catch (error: any) {
      console.error(error);

      toast.error(
        error?.message || 'Failed to remove tenant from Xero account.'
      );
    }
  };

  const getCompanyInvestors = async (companyId: number) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/investors`, { withCredentials: true })
        .then((r) => r.map(normalize.company.in));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to load investors');

      throw error;
    }
  };

  const getCompanyAnalyses = async (companyId: number) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/company_analysis`, {
          withCredentials: true,
        })
        .then((r) => r.map(normalize.companyAnalysis.in));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to load company analyses');

      throw error;
    }
  };

  const getCompanyAnalysisWarnings = async (
    companyId: number,
    companyAnalysisId: number
  ) => {
    try {
      const response = await client
        .get(
          `/companies/${companyId}/company_analysis/${companyAnalysisId}/company_analysis_warnings`,
          {
            withCredentials: true,
          }
        )
        .then((r) => r.map(normalize.companyAnalysisWarnings.in));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to load company analyses');

      throw error;
    }
  };

  const getCompanyPublicProfile = async (companyId: number) => {
    try {
      const publicInfo = await client
        .get(`/v1/companies/${companyId}/public_info`)
        .then(normalize.teaserPublicInfo.in);
      const publicFinances = await client
        .get(`/v1/companies/${companyId}/public_finances`)
        .then(normalize.teaserPublicFinances.in)
        .catch(() => {
          return {};
        });

      return { publicInfo, publicFinances };
    } catch (error: any) {
      toast.error(
        error?.message || 'Failed to fetch company public profile data'
      );
    }
  };

  type ChangeEmail = {
    currentPassword: string;
    newEmail: string;
  };
  const changeEmail = async (payload: ChangeEmail) => {
    try {
      return client.post(`/users/change_email`, normalize.user.out(payload), {
        withCredentials: true,
      });
    } catch (error: any) {
      toast.error(error?.message || 'Failed to change email');

      return error;
    }
  };

  type UpdateAccountSettings = {
    firstName: string;
    lastName: string;
    position: string;
    phone: string;
  };

  const updateAccountSettings = (dispatch: Dispatch<Action>) => async (
    payload: UpdateAccountSettings
  ) => {
    return request(dispatch)(
      apiUserUpdateRequestStateChanged,
      async (): Promise<any> => {
        try {
          const path = `/users/profile`;
          const response = await client.patch(
            path,
            normalize.user.out(payload),
            {
              withCredentials: true,
            }
          );

          // FIXME: event must describe exactly what happened. This is redundant, we can use apiUserUpdateRequestStateChanged. See https://github.com/captec/capital/blob/master/frontend/docs/architecture.md#component-dos-and-donts
          dispatch(apiUserReviewSessionRequestSent({}));
          return response;
        } catch (error: any) {
          toast.error('Failed to update account settings');
          console.error(error);
          throw error;
        }
      }
    );
  };

  const deleteConnector = (
    companyId: number,
    connectorId: number,
    connectorType: string,
    airbyteEnabled: boolean
  ) => {
    if (airbyteEnabled && connectorType in AirbyteConnectorType) {
      return client.delete(
        `/companies/${companyId}/airbyte/connectors/${connectorId}`,
        {
          withCredentials: true,
        }
      );
    }
    return client.delete(`/companies/${companyId}/connectors/${connectorId}`, {
      withCredentials: true,
    });
  };

  const requestForConnectors = (companyId: number, connectors: string[]) => {
    return client.patch(`/companies/${companyId}/onboarding`, {
      requested_connectors: connectors,
    });
  };

  const getConnectors = async (companyId: number) => {
    const rawConnectors: any[] =
      (await client.get(`/companies/${companyId}/connectors`, {
        withCredentials: true,
      })) || EMPTY_ARRAY;

    return rawConnectors
      .map(normalize.connector.in)
      .filter(
        (connector) => connector.status !== ConnectorStatus.Authenticating
      ) as Connector[];
  };

  const getFile = async (companyId: number, fileId: number) => {
    const info = (await client
      .get(`/companies/${companyId}/files/${fileId}/download`)
      .then((file) => {
        // plug source_url into file if it exists
        const source_url = file.source_url
          ? { source_url: file.source_url }
          : {};
        return { ...file, ...source_url };
      })) as { download_url: string; file: CompanyFile };

    return {
      ...info,
      file: normalize.file.in(info.file), // todo: type this
    };
  };

  const updateFile = async (
    companyId: number,
    fileId: number,
    payload: unknown
  ) => {
    return client
      .patch(
        `/companies/${companyId}/files/${fileId}`,
        normalize.file.out(payload)
      )
      .then(({ file }) => file)
      .then(normalize.file.in);
  };

  const acceptFeeTos = async (companyId: number) => {
    const response = await client.patch(
      `companies/${companyId}/onboarding`,
      { accepted_fee_tos: true },
      { withCredentials: true }
    );

    return response;
  };

  const acceptMNDA = async (companyId: number, payload: MNDAPayloadType) => {
    const path = `/companies/${companyId}/onboarding/mnda`;
    const response = await client
      .post(path, normalize.user.out(payload), { withCredentials: true })
      .catch((e) => {
        throw e;
      });
    return response;
  };

  //Investor Analytics
  const getDataCharts = async (companyId: number) => {
    const path = `companies/${companyId}/analytics`;
    return await client
      .get(path)
      .then((res) => res)
      .catch((e) => {
        throw e;
      });
  };
  const getTaskUrlCompanyFinancial = async ({ companyId, docType }) => {
    const path = `companies/${companyId}/analytics_download_tasks`;
    return await client
      .post(path, { doc_type: docType })
      .then((res) => res.task_id)
      .catch((e) => {
        throw e;
      });
  };
  const downloadCompanyFinancialModels = async ({
    companyId,
    taskId,
    docType,
  }) => {
    return await client
      .get(
        `companies/${companyId}/analytics_download_tasks/${taskId}?doc_type=${docType}`,
        {
          withCredentials: true,
        }
      )
      .catch((error) => {
        throw error;
      });
  };
  const getTaskIdCompanyPublicFinances = async ({ companyUuid }) => {
    const path = `/v1/public_analytics_download_tasks`;
    return await client
      .post(path, { token: companyUuid })
      .then((res) => res.task_id)
      .catch((e) => {
        throw e;
      });
  };
  const getTaskIdMatchCompanyPublicFinances = async ({
    companyId,
    matchUuid,
  }) => {
    const path = `/v1/companies/${companyId}/matches/${matchUuid}/public_analytics_download_tasks`;
    return await client
      .post(path)
      .then((res) => res.task_id)
      .catch((e) => {
        throw e;
      });
  };
  const downloadCompanyFinancialData = async (taskUrl: string) => {
    return await client
      .get(taskUrl, { withCredentials: true })
      .catch((error) => {
        throw error;
      });
  };
  const downloadCompanyPublicFinancesData = async (taskId: string) => {
    return await client
      .get(`/v1/public_analytics_download_tasks/${taskId}`, {
        withCredentials: true,
      })
      .catch((error) => {
        throw error;
      });
  };
  const getFileCompanyFinancials = async ({ companyName, url }) => {
    return await client
      .get(`${url}?download=true`, { responseType: 'blob' })
      .then((file) => {
        createDownloadFile(file, 'xlsx', companyName);
      });
  };

  const getManageInsightsSettings = async (companyId: number) => {
    const path = `companies/${companyId}/analytics_permissions`;
    return await client
      .get(path, { withCredentials: true })
      .then((res) => {
        toast.success('Settings updated successfully');
        return res.map(normalize.manageInsightsSettings.in);
      })
      .catch((e) => {
        toast.error('There was an error updating your settings.');
        throw e;
      });
  };

  const updateManageInsightsSettings = async (
    data: ManageInsightsSettingsRequest
  ) => {
    const path = `companies/${data.companyId}/analytics_permissions`;
    return await client
      .post(path, { permissions: omit(data, ['companyId']) })
      .catch((e) => {
        throw e;
      });
  };

  const showCompanyFinancialSummary = async (companyId: number) => {
    const path = `companies/${companyId}/analytics_permissions?status=financial_summary`;
    return await client
      .get(path)
      .then((res) => res)
      .catch((e) => {
        throw e;
      });
  };

  const showQuickBooksRawData = async (companyId: number) => {
    const path = `companies/${companyId}/has_quickbooks_connectors`;
    return await client
      .get(path)
      .then((res) => res)
      .catch((e) => {
        throw e;
      });
  };

  return {
    addConnector,
    getCompanyContact,
    addCompanyUser,
    updateCompanyUser,
    removeCompanyUser,
    getCompanyUsers,
    adminCreateCompanyV5,
    adminUploadMnda,
    getCompanyInvitations,
    removeCompanyInvitation,
    addCompanyInvitation,
    adminEditCompanyFormClosed,
    updateCompanyInvitation,
    updateCompanyApplication,
    getCompanySettings,
    updateCompanySettings,
    updateCompany,
    getChartsByTabName,
    getSyndicationInsightsCharts,
    getAvailablePortfolioCompanies,
    getCompany,
    getCompanyTheme,
    getBranding,
    setBranding,
    searchCompanies,
    getPortfolioCompanies,
    getCompanyDataAssets,
    resendInvite,
    getCompanyFiles,
    deleteFile,
    setFileStatus,
    setDataAssetMap,
    getDataAssetMap,
    getAvailableConnectors,
    getDocuSignConnector,
    addDocuSignConnector,
    saveFileMappingColumns,
    addMNDAFields,
    createDataDump,
    generateCapitalMachineModel,
    companySignUp,
    trackEvent,
    signupMinimal,
    calculateCostOfEquity,
    deleteTenant,
    getCompanyInvestors,
    getCompanyAnalyses,
    getCompanyAnalysisWarnings,
    getCompanyPublicProfile,
    changeEmail,
    updateAccountSettings,
    deleteConnector,
    requestForConnectors,
    getConnectors,
    getFile,
    updateFile,
    acceptFeeTos,
    acceptMNDA,
    getDataCharts,
    getTaskUrlCompanyFinancial,
    getTaskIdCompanyPublicFinances,
    getTaskIdMatchCompanyPublicFinances,
    downloadCompanyFinancialData,
    downloadCompanyPublicFinancesData,
    getFileCompanyFinancials,
    getManageInsightsSettings,
    updateManageInsightsSettings,
    showCompanyFinancialSummary,
    showQuickBooksRawData,
    downloadCompanyFinancialModels,
  };
};
