import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';
import { BaseRequestStateChanged } from '@hum/common/src/ducks/actions';
import { DocusignConnector } from '../state';

export enum DocusignActionType {
  DOCUSIGN_CONNECTOR_HOOK_USED = 'DOCUSIGN_CONNECTOR_HOOK_USED',
  DOCUSIGN_SERVICE_CONNECTED = 'DOCUSIGN_SERVICE_CONNECTED',
  API_DOCUSIGN_CONNECTOR_LOADED = 'API_DOCUSIGN_CONNECTOR_LOADED',
}

/**
 */

export type DocusignConnectorHookUsed = BaseAction<DocusignActionType.DOCUSIGN_CONNECTOR_HOOK_USED>;

export type DocusignConnectorLoaded = BaseRequestStateChanged<
  DocusignActionType.API_DOCUSIGN_CONNECTOR_LOADED,
  DocusignConnector
>;

export type DocusignServiceConnected = BaseAction<
  DocusignActionType.DOCUSIGN_SERVICE_CONNECTED,
  {
    params: any;
  }
>;

export const docusignConnectorHookUsed = actionCreator<DocusignConnectorHookUsed>(
  DocusignActionType.DOCUSIGN_CONNECTOR_HOOK_USED
);

export const docusignConnectorLoaded = actionCreator<DocusignConnectorLoaded>(
  DocusignActionType.API_DOCUSIGN_CONNECTOR_LOADED
);

export const docusignServiceConnected = actionCreator<DocusignServiceConnected>(
  DocusignActionType.DOCUSIGN_SERVICE_CONNECTED
);

export type DocusignAction =
  | DocusignServiceConnected
  | DocusignConnectorHookUsed
  | DocusignConnectorLoaded;
