import {
  investmentRangesToSelectOptions,
  investmentTypesToSelectOptions,
  leverageableAssetsToSelectOptions,
  otherRequiredInsightsToSelectOptions,
  renameKeys,
  requiredInsightsToSelectOptions,
  unwantedInvestorsToSelectOptions,
} from '@hum/icm-app/src/backend/api/utils';

export const application = {
  in: (data: any) => {
    const capitalApplication = renameKeys({
      yearly_revenue: 'yearlyRevenue',
      yearly_revenue_for_syndication: 'yearlyRevenueForSyndication',
      months_of_runway: 'monthsOfRunway',
      company_id: 'companyID',
      record_system: 'recordSystem',
      payment_processor: 'paymentProcessor',
      investment_types: 'investmentTypes',
      investment_ranges: 'investmentRanges',
      investment_amount: 'investmentAmount',
      auth_provider: 'authProvider',
      revenue_model: 'revenueModel',
      //traction_ltm_revenue: 'tractionLtmRevenue',
      year_over_year_growth_rate: 'yearOverYearGrowthRate',
      ebitda_burn: 'ebitdaBurn',
      existing_debt: 'existingDebt',
      target_debt_raise: 'targetDebtRaise',
      use_of_funds: 'useOfFunds',
      capital_deployment: 'capitalDeployment',
      unwanted_investors: 'unwantedInvestors',
      vertical_industry: 'industryVertical',
      equity_raised: 'totalEquityRaised',
      fundraising_time_frame: 'fundraisingTimeFrame',
      latest_post_money_valuation: 'latestPostMoneyValuation',
      investor_mutual_nda_required: 'requireMutualNda',
      wants_non_dilutive_funding: 'accountTypeSyndication',
      wants_company_analytics: 'accountTypeAnalytics',
      leverage_assets: 'leverageableAssets',
      number_of_employees: 'numberOfEmployees',
      required_insights: 'requiredInsights',
      other_required_insights: 'otherRequiredInsights',
      onboarding_status: 'onboardingStatus',
      dataroom_enable: 'dataroomEnable',
      insights_enable: 'insightsEnable',
      syndication_enabled: 'syndicationEnabled',
      show_onboarding: 'showOnboarding',
      invite_url: 'inviteUrl',
      cash_on_hand: 'cashOnHand',
      ltm_revenue: 'ltmRevenue',
      last_month_EBITDA: 'lastMonthEBITDA',
      year_founded: 'yearFounded',
      funding_type: 'fundingType',
      last_funding_round: 'lastFundingRound',
      last_raised: 'lastRaised',
      self_reported_industry: 'selfReportedIndustry',
      review_and_confirm_by: 'reviewAndConfirmBy',
      review_and_confirm_on: 'reviewAndConfirmOn',
      review_and_confirm_needed: 'reviewAndConfirmNeeded',
      requested_connectors: 'requestedConnectors',
      test: 'isTestAccount',
    })(data);

    return {
      ...capitalApplication,
      ...(capitalApplication &&
        capitalApplication.lastRaised && {
          lastRaised:
            `${new Date(capitalApplication.lastRaised)
              .toISOString()
              .slice(5, 7)}${new Date(capitalApplication.lastRaised)
              .toISOString()
              .slice(0, 4)}` || null,
        }),
      ...(capitalApplication &&
        capitalApplication.investmentRanges && {
          investmentRanges: investmentRangesToSelectOptions(
            capitalApplication.investmentRanges || []
          ),
        }),
      ...(capitalApplication &&
        capitalApplication.investmentTypes && {
          investmentTypes: investmentTypesToSelectOptions(
            capitalApplication.investmentTypes || []
          ),
        }),
      ...(capitalApplication &&
        capitalApplication.leverageableAssets && {
          leverageableAssets: leverageableAssetsToSelectOptions(
            capitalApplication.leverageableAssets || []
          ),
        }),
      ...(capitalApplication &&
        capitalApplication.requiredInsights && {
          requiredInsights: requiredInsightsToSelectOptions(
            capitalApplication.requiredInsights || []
          ),
        }),
      ...(capitalApplication &&
        capitalApplication.otherRequiredInsights && {
          otherRequiredInsights: otherRequiredInsightsToSelectOptions(
            capitalApplication.otherRequiredInsights || []
          ),
        }),
      ...(capitalApplication &&
        capitalApplication.unwantedInvestors && {
          unwantedInvestors: unwantedInvestorsToSelectOptions(
            capitalApplication.unwantedInvestors || []
          ),
        }),
    };
  },
  out: renameKeys({
    company: 'company_id',
    yearlyRevenue: 'yearly_revenue',
    yearlyRevenueForSyndication: 'yearly_revenue_for_syndication',
    monthsOfRunway: 'months_of_runway',
    recordSystem: 'record_system',
    paymentProcessor: 'payment_processor',
    investmentTypes: 'investment_types',
    investmentRanges: 'investment_ranges',
    investmentAmount: 'investment_amount',
    revenueModel: 'revenue_model',
    industryVertical: 'vertical_industry',
    totalEquityRaised: 'equity_raised',
    equityRaised: 'equity_raised',
    //tractionLtmRevenue: 'traction_ltm_revenue',
    yearOverYearGrowthRate: 'year_over_year_growth_rate',
    ebitdaBurn: 'ebitda_burn',
    existingDebt: 'existing_debt',
    targetDebtRaise: 'target_debt_raise',
    fundraisingTimeFrame: 'fundraising_time_frame',
    numberOfEmployees: 'number_of_employees',
    latestPostMoneyValuation: 'latest_post_money_valuation',
    leverageableAssets: 'leverage_assets',
    requiredInsights: 'required_insights',
    useOfFunds: 'use_of_funds',
    capitalDeployment: 'capital_deployment',
    accountTypeAnalytics: 'wants_company_analytics',
    accountTypeSyndication: 'wants_non_dilutive_funding',
    requireMutualNda: 'investor_mutual_nda_required',
    dataroomEnable: 'dataroom_enable',
    insightsEnable: 'insights_enable',
    lastFundingRound: 'last_funding_round',
    yearFounded: 'year_founded',
    fundingType: 'funding_type',
    lastRaised: 'last_raised',
    isTestAccount: 'test',
    selfReportedIndustry: 'self_reported_industry',
  }),
};
