import qs from 'query-string';
import pickBy from 'lodash/pickBy';
import { toast } from '@hum/common/src/modules/toast';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { OTPAction } from '@hum/icm-app/src/components/types';
import { User } from '@hum/types';
import { Login, PasswordReset } from '../../state';
import { Dispatch } from 'redux';
import {
  Action,
  apiRequestFailed,
  apiUserLoginRequestStateChanged,
  apiUserRecoverAccountRequestStateChanged,
  apiUserSetPasswordRequestStateChanged,
} from '@hum/icm-app/src/actions';
import { request } from './utils';
import { analytics } from '@hum/common';
import { CreateAPIOptions } from '.';

const INCORRECT_CREDENTIALS_API_ERROR = 'Incorrect email or password';

export const API_SIGNUP_URL = '/v1/signup';

export const createAuthAPI = ({ client }: CreateAPIOptions) => {
  const normalizeSession = (user: any) => {
    const roles = user.roles;
    if (user.roles.length === 0) {
      throw new Error(
        `User ${user.id} ${user.email} does not have any roles set`
      );
    }

    return normalize.user.in({ ...user, roles });
  };

  const recoverAccount = (dispatch: Dispatch<Action>) => async (
    payload: Pick<Login, 'email'>
  ) => {
    return request(dispatch)(
      apiUserRecoverAccountRequestStateChanged,
      async () => {
        try {
          const response = await client.post(`/otp`, {
            ...payload,
            action: OTPAction.ResetPassword,
          });
          return response;
        } catch (error: any) {
          toast.error(
            error?.message ||
              'There was an error when trying to recover your account'
          );
          throw error;
        }
      }
    );
  };

  const setPassword = (dispatch: Dispatch<Action>) => async (
    payload: Pick<PasswordReset, 'password' | 'acceptedCommunications'> & {
      token: string;
    }
  ) => {
    return request(dispatch)(
      apiUserSetPasswordRequestStateChanged,
      async (): Promise<User> => {
        try {
          const response = await client
            .patch(
              `users/password`,
              {
                password: payload.password,
                accepted_communications: payload.acceptedCommunications,
              },
              {
                withCredentials: true,
                headers: { 'X-OTP': payload.token },
              }
            )
            .then(({ user }) => normalizeSession(user));
          return response;
        } catch (error: any) {
          toast.error(
            error?.message ||
              'There was an error when trying to recover your account'
          );
          throw error;
        }
      }
    );
  };

  const setPasswordWithTos = (dispatch: Dispatch<Action>) => async (
    payload: PasswordReset,
    token: string
  ) => {
    return request(dispatch)(
      apiUserSetPasswordRequestStateChanged,
      async (): Promise<User> => {
        try {
          const response = await client
            .patch(`users/password`, normalize.passwordReset.out(payload), {
              withCredentials: true,
              headers: { 'X-OTP': token },
            })
            .then(({ user }) => normalizeSession(user));
          return response;
        } catch (error: any) {
          toast.error(
            error?.message ||
              'There was an error when trying to recover your account'
          );
          throw error;
        }
      }
    );
  };

  /**
   * @deprecated
   */

  const login = (dispatch: Dispatch<Action>) => async (payload: Login) => {
    return request(dispatch)(
      apiUserLoginRequestStateChanged,
      async (): Promise<User> => {
        try {
          const response = await client
            .post(`/session`, payload, { withCredentials: true })
            .then(({ user }) => normalizeSession(user));
          // @ts-ignore
          analytics.track('login:success');
          return response;
        } catch (error: any) {
          if (error.message === INCORRECT_CREDENTIALS_API_ERROR) {
            // @ts-ignore
            analytics.track(`login:fail`, { user: payload.email });
          } else {
            // @ts-ignore
            analytics.track(`login:incorrect-credentials`, {
              user: payload.email,
            });
          }

          toast.error(error?.message || 'Failed to login');

          dispatch(
            apiRequestFailed({
              error,
              reason: error?.message || 'Failed to login',
            })
          );
          throw error;
        }
      }
    );
  };

  const getIsInvitationRedeemed = async (invitationHash: string) => {
    try {
      const path = `/investor/portfolio/invites/${invitationHash}/is-redeemed`;
      const response = await client.get(path);
      return await normalize.invitationRedemption.in(response);
    } catch (error: any) {
      toast.error(error?.message || 'Invitation status could not be retrieved');
    }
  };

  const userLogout = async () => {
    try {
      const response = await client
        .delete(`/session`, {
          withCredentials: true,
        })
        .then(() => ({ success: true }));
      // @ts-ignore
      analytics.track('logout:success');
      return response;
    } catch (error: any) {
      // @ts-ignore
      analytics.track('logout:fail');
      toast.error(error?.message || 'Failed to logout');
      throw error;
    }
  };

  const userReviewSession = async () => {
    try {
      const response = await client
        .get(`/session`, {
          withCredentials: true,
        })
        .then(({ user }) => normalizeSession(user));
      return response;
    } catch (error: any) {
      // do not surface to user because no active session will show a non-error
      console.warn(error);
    }
  };

  const xeroGenerateLoginUrl = async () => {
    try {
      const response = await client.get(`/auth/xero/login`, {
        withCredentials: true,
      });
      return response;
    } catch (error: any) {
      toast.error(error?.message || "Failed to get Xero's Oauth URL");
      throw error;
    }
  };

  const xeroRevoke = async () => {
    try {
      const response = await client.post(`/auth/xero/revoke`, {
        withCredentials: true,
      });
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to revoke all xero access');
      throw error;
    }
  };

  const xeroGenerateTransactionUrl = async () => {
    try {
      const response = await client.get(`/auth/xero/transaction`, {
        withCredentials: true,
      });
      return response;
    } catch (error: any) {
      toast.error(error?.message || "Failed to get Xero's Oauth URL");
      throw error;
    }
  };

  const xeroLogin = async (payload: any): Promise<User> => {
    try {
      const response = await client
        .post(`/session/xero`, payload, {
          withCredentials: true,
        })
        .then(({ user }) => normalizeSession(user));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to login');
      throw error;
    }
  };

  const xeroSignup = async (payload: any) => {
    try {
      const response = await client.post(`/users/signup`, payload, {
        withCredentials: true,
      });
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to login');
      console.warn(error);
    }
  };

  const xeroAddAuth = async (payload: any) => {
    try {
      const response = await client.post(`/users/profile/auth/xero`, payload, {
        withCredentials: true,
      });
      return response;
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || 'Failed to sign up with Xero.');
    }
  };

  const reviewIfEmailIsAllowed = async (email: string) => {
    return await client
      .get(`/emails/acceptability`, {
        params: { email },
        withCredentials: true,
        paramsSerializer: (params) => qs.stringify(params), // allows emails like julio+something@captec.io to work
      })
      .then(normalize.emailAcceptability.in)
      .catch((e) => {
        throw e;
      });
  };

  const postSignupV4 = async (
    email: string,
    company_name: string,
    website: string,
    us_state: string,
    vertical_industry: string,
    invitation_hash: string,
    revenue_model: string,
    subdomain: string,
    utmMedium: string,
    utmSource: string,
    utmCampaign: string,
    utmTerm: string,
    utmContent: string,
    application: {
      fundraisingTimeFrame?: string;
      yearFounded?: number;
    }
  ) => {
    return client.post(`/companies/signup`, {
      email: email,
      legal_business_name: company_name,
      website: website,
      state: us_state,
      vertical_industry: vertical_industry,
      invitation_hash: invitation_hash,
      revenue_model: revenue_model,
      subdomain: subdomain,
      utm_params: {
        utm_medium: utmMedium,
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_term: utmTerm,
        utm_content: utmContent,
      },
      application: pickBy({
        fundraising_time_frame: application.fundraisingTimeFrame,
        year_founded: application.yearFounded,
      }),
    });
  };

  const postSignupV5 = async (
    workEmail: string,
    firstName: string,
    lastName: string,
    companyRole: string,
    companyName: string,
    website: string,
    password: string,
    acceptedCommunications: boolean,
    acceptedPrivacyPolicy: boolean,
    acceptedTos: boolean,
    application: {
      fundRaisingTimeFrame?: string;
    },
    invitationHash: string,
    channel: string,
    utmMedium: string,
    utmSource: string,
    utmCampaign: string,
    utmTerm: string,
    utmContent: string,
    subdomain: string
  ) => {
    return client.post(API_SIGNUP_URL, {
      email: workEmail,
      first_name: firstName,
      last_name: lastName,
      company_position: companyRole,
      company_name: companyName,
      website: website,
      invitation_hash: invitationHash,
      password: password,
      accepted_communications: acceptedCommunications,
      accepted_privacy_policy: acceptedPrivacyPolicy,
      accepted_tos: acceptedTos,
      channel: channel,
      subdomain: subdomain,
      utm_params: pickBy({
        utm_medium: utmMedium,
        utm_source: utmSource,
        utm_campaign: utmCampaign,
        utm_term: utmTerm,
        utm_content: utmContent,
      }),
      application: pickBy({
        fundraising_time_frame: application.fundRaisingTimeFrame,
      }),
    });
  };

  const verifyEmail = async (payload: { token: string }) => {
    return await client
      .post(`users/verify-email`, undefined, {
        withCredentials: true,
        headers: { 'X-OTP': payload.token },
      })
      .catch((error) => {
        toast.error(
          error?.message ||
            'There was an error when trying to recover your account'
        );
        throw error;
      });
  };

  const resendVerifyEmail = async () => {
    return await client
      .get(`users/resend_verify_email`, {
        withCredentials: true,
      })
      .catch((error) => {
        toast.error(
          error?.message ||
            'There was an error when trying to resend your verification email'
        );
        throw error;
      });
  };

  return {
    normalizeSession,
    recoverAccount,
    setPassword,
    setPasswordWithTos,
    login,
    getIsInvitationRedeemed,
    userLogout,
    userReviewSession,
    xeroGenerateLoginUrl,
    xeroRevoke,
    xeroGenerateTransactionUrl,
    xeroLogin,
    xeroSignup,
    xeroAddAuth,
    reviewIfEmailIsAllowed,
    postSignupV4,
    postSignupV5,
    verifyEmail,
    resendVerifyEmail,
  };
};
