import { User } from '@hum/types';
import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';
import { InvitationRedemption } from '../state';
import { BaseRequestStateChanged } from '@hum/common/src/ducks/actions';
/*
 * Please consider that there are 2 types of users using investor portal
 * Investor: SVB, Handelsbank, etc. – Manages companies
 * Company: Companies managed by investor, not by Capital
 * This whole section will be prefixed with IP meaning Investor Portal
 * Note: Talk with Craig about  prefixing – Julio
 */

export enum AuthActionType {
  USER_RECOVER_ACCOUNT_REQUEST_STATE_CHANGED = 'USER_RECOVER_ACCOUNT_REQUEST_STATE_CHANGED',
  USER_SET_PASSWORD_REQUEST_STATE_CHANGED = 'USER_SET_PASSWORD_REQUEST_STATE_CHANGED',
  USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED = 'USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED',
  USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED_SUCCEEDED = 'USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED_SUCCEEDED',
  USER_VERIFY_EMAIL_VISITED = 'USER_VERIFY_EMAIL_VISITED',
  USER_LOGIN_REQUEST_STATE_CHANGED = 'USER_LOGIN_REQUEST_STATE_CHANGED',
  USER_LOGOUT_REQUEST_STATE_CHANGED = 'USER_LOGOUT_REQUEST_STATE_CHANGED',
  USER_UPDATE_STATE_CHANGED = 'USER_UPDATE_STATE_CHANGED',
  USER_REVIEW_SESSION_REQUEST_SENT = 'USER_REVIEW_SESSION_REQUEST_SENT',
  USER_REVIEW_SESSION_REQUEST_STATE_CHANGED = 'USER_REVIEW_SESSION_REQUEST_STATE_CHANGED',

  SIGNUP_IS_INVITATION_REDEEMED_HOOK_USED = 'SIGNUP_IS_INVITATION_REDEEMED_HOOK_USED',
  API_SIGNUP_IS_INVITATION_REDEEMED_STATE_CHANGED = 'API_SIGNUP_IS_INVITATION_REDEEMED_STATE_CHANGED',

  ALLOWED_EMAIL_REQUEST_STATE_CHANGED = 'ALLOWED_EMAIL_REQUEST_STATE_CHANGED',
  ALLOWED_EMAIL_REQUEST_SENT = 'ALLOWED_EMAIL_REQUEST_SENT',

  SIGNUP_V5_REQUEST_SENT = 'SIGNUP_V5_REQUEST_SENT',
  SIGNUP_V5_RESET = 'SIGNUP_V5_RESET',

  RESEND_VERIFY_EMAIL_BUTTON_CLICKED = 'RESEND_VERIFY_EMAIL_BUTTON_CLICKED',
  RESEND_VERIFY_EMAIL_BUTTON_CLICKED_SUCCESS = 'RESEND_VERIFY_EMAIL_BUTTON_CLICKED_SUCCESS',
  RESEND_VERIFY_EMAIL_BUTTON_CLICKED_FAIL = 'RESEND_VERIFY_EMAIL_BUTTON_CLICKED_FAIL',

  // analytics
  PAGEVIEW_LOGIN = 'PAGEVIEW_LOGIN',
  PAGEVIEW_COMPANY_SIGNUP = 'PAGEVIEW_COMPANY_SIGNUP',
  PAGEVIEW_COMPANY_SIGNUP_ADDITIONAL_FIELDS = 'PAGEVIEW_COMPANY_SIGNUP_ADDITIONAL_FIELDS',
  PAGEVIEW_COMPANY_SIGNUP_WELCOME_TO_HUM = 'PAGEVIEW_COMPANY_SIGNUP_WELCOME_TO_HUM', // choose your adventure / welcome to Hum
  PAGEVIEW_COMPANY_SIGNUP_SHARE_YOUR_GOALS = 'PAGEVIEW_COMPANY_SIGNUP_SHARE_YOUR_GOALS', // choose your adventure / share your goals
  PAGEVIEW_COMPANY_SIGNUP_GIVE_US_A_SNAPSHOT = 'PAGEVIEW_COMPANY_SIGNUP_GIVE_US_A_SNAPSHOT', // choose your adventure / give us a snapshot
  PAGEVIEW_COMPANY_SIGNUP_FUNDRAISING_HISTORY = 'PAGEVIEW_COMPANY_SIGNUP_FUNDRAISING_HISTORY', // choose your adventure / fundraising history
  PAGEVIEW_COMPANY_SIGNUP_VERIFY_YOUR_EMAIL = 'PAGEVIEW_COMPANY_SIGNUP_VERIFY_YOUR_EMAIL', // verify your email
  PAGEVIEW_INVESTOR_SIGNUP = 'PAGEVIEW_INVESTOR_SIGNUP',
  PAGEVIEW_RECOVER_PASSWORD = 'PAGEVIEW_RECOVER_PASSWORD',
  PAGEVIEW_SET_PASSWORD = 'PAGEVIEW_SET_PASSWORD',
}

export type ApiUserRecoverAccountRequestStateChanged = BaseRequestStateChanged<
  AuthActionType.USER_RECOVER_ACCOUNT_REQUEST_STATE_CHANGED,
  {}
>;

export const apiUserRecoverAccountRequestStateChanged = actionCreator<ApiUserRecoverAccountRequestStateChanged>(
  AuthActionType.USER_RECOVER_ACCOUNT_REQUEST_STATE_CHANGED
);

export type ApiUserSetPasswordRequestStateChanged = BaseRequestStateChanged<
  AuthActionType.USER_SET_PASSWORD_REQUEST_STATE_CHANGED,
  User
>;

export const apiUserSetPasswordRequestStateChanged = actionCreator<ApiUserSetPasswordRequestStateChanged>(
  AuthActionType.USER_SET_PASSWORD_REQUEST_STATE_CHANGED
);

export type ApiUserVerifyEmailRequestStateChanged = BaseRequestStateChanged<
  AuthActionType.USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED,
  User
>;

export const apiUserVerifyEmailequestStateChanged = actionCreator<ApiUserVerifyEmailRequestStateChanged>(
  AuthActionType.USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED
);

export type UserVerifyEmailVisited = BaseAction<
  AuthActionType.USER_VERIFY_EMAIL_VISITED,
  { token: string }
>;

export const userVerifyEmailVisited = actionCreator<UserVerifyEmailVisited>(
  AuthActionType.USER_VERIFY_EMAIL_VISITED
);

export type ResendVerificationEmailButtonClicked = BaseAction<AuthActionType.RESEND_VERIFY_EMAIL_BUTTON_CLICKED>;

export const resendVerificationEmailButtonClicked = actionCreator<ResendVerificationEmailButtonClicked>(
  AuthActionType.RESEND_VERIFY_EMAIL_BUTTON_CLICKED
);

export type ResendVerificationEmailButtonClickedSuccess = BaseAction<AuthActionType.RESEND_VERIFY_EMAIL_BUTTON_CLICKED_SUCCESS>;

export const resendVerificationEmailButtonClickedSuccess = actionCreator<ResendVerificationEmailButtonClickedSuccess>(
  AuthActionType.RESEND_VERIFY_EMAIL_BUTTON_CLICKED_SUCCESS
);

export type ResendVerificationEmailButtonClickedFail = BaseAction<AuthActionType.RESEND_VERIFY_EMAIL_BUTTON_CLICKED_FAIL>;

export const resendVerificationEmailButtonClickedFail = actionCreator<ResendVerificationEmailButtonClickedFail>(
  AuthActionType.RESEND_VERIFY_EMAIL_BUTTON_CLICKED_FAIL
);

export type ApiUserVerifyEmailequestStateSucceeded = BaseAction<AuthActionType.USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED_SUCCEEDED>;

export const apiUserVerifyEmailequestStateSucceeded = actionCreator<ApiUserVerifyEmailequestStateSucceeded>(
  AuthActionType.USER_VERIFY_EMAIL_REQUEST_STATE_CHANGED_SUCCEEDED
);

export type ApiUserLoginRequestStateChanged = BaseRequestStateChanged<
  AuthActionType.USER_LOGIN_REQUEST_STATE_CHANGED,
  User
>;

export const apiUserLoginRequestStateChanged = actionCreator<ApiUserLoginRequestStateChanged>(
  AuthActionType.USER_LOGIN_REQUEST_STATE_CHANGED
);

export type ApiUserUpdateStateChanged = BaseRequestStateChanged<
  AuthActionType.USER_UPDATE_STATE_CHANGED,
  User
>;

export const apiUserUpdateRequestStateChanged = actionCreator<ApiUserUpdateStateChanged>(
  AuthActionType.USER_UPDATE_STATE_CHANGED
);

export type ApiUserLogoutRequestStateChanged = BaseRequestStateChanged<
  AuthActionType.USER_LOGOUT_REQUEST_STATE_CHANGED,
  { success: boolean }
>;

export const apiUserLogoutRequestStateChanged = actionCreator<ApiUserLogoutRequestStateChanged>(
  AuthActionType.USER_LOGOUT_REQUEST_STATE_CHANGED
);

// Review if a session exists
export type ApiUserReviewSessionRequestSent = BaseAction<
  AuthActionType.USER_REVIEW_SESSION_REQUEST_SENT,
  {}
>;

export const apiUserReviewSessionRequestSent = actionCreator<ApiUserReviewSessionRequestSent>(
  AuthActionType.USER_REVIEW_SESSION_REQUEST_SENT
);

export type ApiUserReviewSessionRequestStateChanged = BaseRequestStateChanged<
  AuthActionType.USER_REVIEW_SESSION_REQUEST_STATE_CHANGED,
  User
>;

export const apiUserReviewSessionRequestStateChanged = actionCreator<ApiUserReviewSessionRequestStateChanged>(
  AuthActionType.USER_REVIEW_SESSION_REQUEST_STATE_CHANGED
);

// verify if an email is allowed (not using public email services/domains)
export type AllowedEmailRequestSent = BaseAction<
  AuthActionType.ALLOWED_EMAIL_REQUEST_SENT,
  { email: string }
>;

export const allowedEmailRequestSent = actionCreator<AllowedEmailRequestSent>(
  AuthActionType.ALLOWED_EMAIL_REQUEST_SENT
);

export type AllowedEmailRequestStateChanged = BaseRequestStateChanged<
  AuthActionType.ALLOWED_EMAIL_REQUEST_STATE_CHANGED,
  { isAllowed: boolean }
>;

export const allowedEmailRequestStateChanged = actionCreator<AllowedEmailRequestStateChanged>(
  AuthActionType.ALLOWED_EMAIL_REQUEST_STATE_CHANGED
);

// signup with email + details
export type SignUpV5RequestSent = BaseAction<
  AuthActionType.SIGNUP_V5_REQUEST_SENT,
  {
    workEmail: string;
    firstName: string;
    lastName: string;
    companyRole: string;
    companyName: string;
    website: string;
    password: string;
    acceptedCommunications: boolean;
    acceptedPrivacyPolicy: boolean;
    acceptedTos: boolean;
    invitationHash?: string;
    utmMedium?: string;
    utmSource?: string;
    utmCampaign?: string;
    utmTerm?: string;
    utmContent?: string;
    channel?: string;
    subdomain?: string;
    application: {
      fundRaisingTimeFrame?: string;
    };
  }
>;

export const signUpV5RequestSent = actionCreator<SignUpV5RequestSent>(
  AuthActionType.SIGNUP_V5_REQUEST_SENT
);

export type SignUpV5Reset = BaseAction<AuthActionType.SIGNUP_V5_RESET, {}>;

export const signUpV5Reset = actionCreator(AuthActionType.SIGNUP_V5_RESET);

export type SignupIsInvitationRedeemedHookUsed = BaseAction<
  AuthActionType.SIGNUP_IS_INVITATION_REDEEMED_HOOK_USED,
  { invitationHash: string }
>;

export type ApiSignupIsInvitationRedeemedStateChanged = BaseRequestStateChanged<
  AuthActionType.API_SIGNUP_IS_INVITATION_REDEEMED_STATE_CHANGED,
  InvitationRedemption
>;

export const signupIsInvitationRedeemedHookUsed = actionCreator<SignupIsInvitationRedeemedHookUsed>(
  AuthActionType.SIGNUP_IS_INVITATION_REDEEMED_HOOK_USED
);

export const apiSignupIsInvitationRedeemedStateChanged = actionCreator<ApiSignupIsInvitationRedeemedStateChanged>(
  AuthActionType.API_SIGNUP_IS_INVITATION_REDEEMED_STATE_CHANGED
);

// pageview tracking
export type TrackPageviewLogin = BaseAction<AuthActionType.PAGEVIEW_LOGIN, {}>;
export const trackPageviewLogin = actionCreator<TrackPageviewLogin>(
  AuthActionType.PAGEVIEW_LOGIN
);

export type TrackPageviewCompanySignup = BaseAction<
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP,
  {}
>;
export const trackPageviewCompanySignup = actionCreator<TrackPageviewCompanySignup>(
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP
);

export type TrackPageviewCompanySignupAdditionalFields = BaseAction<
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_ADDITIONAL_FIELDS,
  {}
>;
export const trackPageviewCompanySignupAdditionalFields = actionCreator<TrackPageviewCompanySignupAdditionalFields>(
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_ADDITIONAL_FIELDS
);

export type TrackPageviewCompanySignupWelcomeToHum = BaseAction<
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_WELCOME_TO_HUM,
  {}
>;
export const trackPageviewCompanySignupWelcomeToHum = actionCreator<TrackPageviewCompanySignupWelcomeToHum>(
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_WELCOME_TO_HUM
);

export type TrackPageviewCompanySignupShareYourGoals = BaseAction<
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_SHARE_YOUR_GOALS,
  {}
>;
export const trackPageviewCompanySignupShareYourGoals = actionCreator<TrackPageviewCompanySignupShareYourGoals>(
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_SHARE_YOUR_GOALS
);

export type TrackPageviewCompanySignupGiveUsASnapshot = BaseAction<
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_GIVE_US_A_SNAPSHOT,
  {}
>;
export const trackPageviewCompanySignupGiveUsASnapshot = actionCreator<TrackPageviewCompanySignupGiveUsASnapshot>(
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_GIVE_US_A_SNAPSHOT
);

export type TrackPageviewCompanySignupFundraisingHistory = BaseAction<
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_FUNDRAISING_HISTORY,
  {}
>;
export const trackPageviewCompanySignupFundraisingHistory = actionCreator<TrackPageviewCompanySignupFundraisingHistory>(
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_FUNDRAISING_HISTORY
);

export type TrackPageviewCompanySignupVerifyYourEmail = BaseAction<
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_VERIFY_YOUR_EMAIL,
  {}
>;
export const trackPageviewCompanySignupVerifyYourEmail = actionCreator<TrackPageviewCompanySignupVerifyYourEmail>(
  AuthActionType.PAGEVIEW_COMPANY_SIGNUP_VERIFY_YOUR_EMAIL
);

export type TrackPageviewInvestorSignup = BaseAction<
  AuthActionType.PAGEVIEW_INVESTOR_SIGNUP,
  {}
>;
export const trackPageviewInvestorSignup = actionCreator<TrackPageviewInvestorSignup>(
  AuthActionType.PAGEVIEW_INVESTOR_SIGNUP
);

export type TrackPageviewRecoverPassword = BaseAction<
  AuthActionType.PAGEVIEW_RECOVER_PASSWORD,
  {}
>;
export const trackPageviewRecoverPassword = actionCreator<TrackPageviewRecoverPassword>(
  AuthActionType.PAGEVIEW_RECOVER_PASSWORD
);

export type TrackPageviewSetPassword = BaseAction<
  AuthActionType.PAGEVIEW_SET_PASSWORD,
  {}
>;
export const trackPageviewSetPassword = actionCreator<TrackPageviewSetPassword>(
  AuthActionType.PAGEVIEW_SET_PASSWORD
);

export type AuthAction =
  | ApiUserRecoverAccountRequestStateChanged
  | ApiUserSetPasswordRequestStateChanged
  | ApiUserLoginRequestStateChanged
  | ApiUserLogoutRequestStateChanged
  | ApiUserReviewSessionRequestSent
  | ApiUserReviewSessionRequestStateChanged
  | ApiUserVerifyEmailRequestStateChanged
  | ApiUserVerifyEmailequestStateSucceeded
  | UserVerifyEmailVisited
  | SignupIsInvitationRedeemedHookUsed
  | ApiSignupIsInvitationRedeemedStateChanged
  | AllowedEmailRequestSent
  | AllowedEmailRequestStateChanged
  | SignUpV5RequestSent
  | SignUpV5Reset
  | ApiUserUpdateStateChanged
  | TrackPageviewLogin
  | TrackPageviewCompanySignup
  | TrackPageviewCompanySignupAdditionalFields
  | TrackPageviewCompanySignupWelcomeToHum
  | TrackPageviewCompanySignupShareYourGoals
  | TrackPageviewCompanySignupGiveUsASnapshot
  | TrackPageviewCompanySignupVerifyYourEmail
  | TrackPageviewInvestorSignup
  | TrackPageviewRecoverPassword
  | TrackPageviewSetPassword
  | ResendVerificationEmailButtonClicked
  | ResendVerificationEmailButtonClickedSuccess
  | ResendVerificationEmailButtonClickedFail;
