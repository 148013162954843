import { axios } from '@hum/common/src/api/http';
import { useState } from 'react';

import { client as backend } from '@hum/common/src/api/client';
import { normalize } from '@hum/icm-app/src/backend/api/models';
import { FileInput, FilePresignedPost } from '@hum/icm-app/src/state';
import { apiFileCreated, apiFileUploaded } from '@hum/icm-app/src/actions';
import { toast } from '@hum/common/src/modules/toast';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';

export const useCreate = (companyId: number) => {
  const { dispatch } = useAppStore();
  const [loading, setLoading] = useState(false);

  const create = async (file: FileInput) => {
    try {
      setLoading(true);

      const newFile = await backend
        .post(`/companies/${companyId}/files`, normalize.file.out(file))
        .then(normalize.file.in)
        .catch((error) => {
          throw error;
        });

      dispatch(apiFileCreated({ companyId }));

      return newFile;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to create file.');
    } finally {
      setLoading(false);
    }
  };

  const upload = async (
    filePresignedPost: FilePresignedPost,
    file: File,
    onUploadProgress: (progressEvent: ProgressEvent, file: File) => void
  ) => {
    const { fields, url } = filePresignedPost;
    const formData = new FormData();

    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        formData.append(key, fields[key]);
      }
    }

    formData.append('file', file as any);

    // response is always an empty 200 if successful.
    await axios.post(url, formData, {
      onUploadProgress: (progressEvent) =>
        onUploadProgress ? onUploadProgress(progressEvent, file) : null,
    });

    dispatch(apiFileUploaded({ companyId }));

    return `${filePresignedPost.url}/${fields.key}`;
  };

  return { loading, create, upload };
};
