import {
  Action,
  matchFeedbackSent,
  requestIntroductionSent,
  apiSyndicationCompanyInformationStateChanged,
  apiSyndicationCompanyMatchUpdated,
  apiSyndicationCompanyMatchCreated,
  adminCompanySyndicationEnabledToggled,
} from '../../actions';
import { Company, CompanyType, ProcessStatus } from '@hum/types';
import {
  EMPTY_ARRAY,
  MatchResponse,
  SyndicationMatchProfile,
  SyndicationMatchProfile2,
  SyndicationSectionDeals,
  BlockPermissionsPayloadType,
  MatchOutReasonsPayloadType,
} from '../../state';
import { toast } from '@hum/common/src/modules/toast';

import { Dispatch } from 'redux';
import { normalize } from './models';
import { request } from './utils';
import { CreateAPIOptions } from '.';
export type UpdateMatchStatusPayload = {
  status: string;
};
export type UpdateSkipSyndicationLegalAgreementsRequestStatusPayload = {
  skipRequest: boolean;
};
export type UpdateCompanyProcessStatusPayload = {
  processStatus: ProcessStatus;
  notes: string;
};
export type UpdateDataroomInsightsPermsPayload = {
  dataroomEnable?: boolean;
  insightsEnable?: boolean;
};

export const createSyndicationAPI = ({ client }: CreateAPIOptions) => {
  const getSyndicationRecommendations = async (companyId: number) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/syndication/matches/new_matches`, {
          withCredentials: true,
        })
        .then((r) => r.map(normalize.syndicationCompanyMatch.in));
      return response;
    } catch (error: any) {
      toast.error(
        error?.message || 'Failed to fetch syndication recommendations'
      );
    }
  };

  // get matches for admin
  const __DEPRECATED__getSyndicationMatches = async (companyId: number) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/syndication/matches`, {
          withCredentials: true,
        })
        .then((r) => r.map(normalize.syndicationCompanyMatch.in));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch syndication matches');
    }
  };

  const getSyndicationMatches = async (
    companyId: number,
    sectionDeals: SyndicationSectionDeals
  ) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/matches/sections/${sectionDeals}`, {
          withCredentials: true,
        })
        .then((r) => r.map(normalize.syndicationCompanyMatch2.in));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch syndication matches');
    }
  };

  const getSyndicationMatchOutReasons = async () => {
    try {
      const response = await client
        .get(`/syndication/matches/out_reasons/company_passed`)
        .then((res) => res.map(normalize.syndicationMatchOutReasons.in));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch match out reasons');
    }
  };

  // get syndication_pre_intro charts.
  const getPreIntroCharts = async (
    matchUuid: string,
    companyAnalysisId?: number
  ) => {
    try {
      const params = {};
      if (companyAnalysisId) {
        params['companyAnalysisId'] = companyAnalysisId;
      }
      return await client
        .get(
          `/portal/syndication/matches/${matchUuid}/charts/syndication-pre-intro`,
          { params }
        )
        .then((charts) => charts.map(normalize.chart.in));
    } catch (error: any) {
      console.error(error);
      toast.error(
        error?.message || 'Failed to get syndication pre intro charts'
      );

      return error;
    }
  };

  // used when you're company/investor and are at the cards view
  // it has more restricted info to keep matches anonymous
  const getSyndicationOnboardingMatches = async (
    companyId: number,
    companyType: CompanyType[]
  ) => {
    try {
      const path = companyType.includes(CompanyType.SyndicationInvestor)
        ? `/companies/${companyId}/portal/syndication/matches`
        : `/companies/${companyId}/onboarding/syndication/matches`;
      const response = await client.get(path, { withCredentials: true });
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch syndication matches');
    }
  };

  const getSyndicationExistingProofFiles = async (
    companyId: number,
    matchUuid: string
  ) => {
    try {
      const files =
        (await client.get(
          `/companies/${companyId}/portal/syndication/matches/${matchUuid}/files`,
          {
            withCredentials: true,
          }
        )) || EMPTY_ARRAY;
      return files.map(normalize.file.in);
    } catch (error: any) {
      toast.error(error?.message || 'Files could not be retrieved');
    }
  };

  const getMatchTocVersion = async () => {
    try {
      const path = '/portal/syndication/matches/toc/version';
      const response = await client.get(path);
      return await normalize.matchTocVersion.in(response);
    } catch (error: any) {
      toast.error(error?.message || 'Syndication ToC could not be retrieved');
    }
  };

  const sendSyndicationInvestorShowsInterestInCompany = async (
    companyId: number,
    matchUuid: string
  ) => {
    const path = `/companies/${companyId}/portal/syndication/matches/${matchUuid}/investor-expresses-interest`;
    try {
      const response = await client.patch(path);
      return normalize.companySyndicationDetails.in(response);
    } catch (error: any) {
      console.error(error);
    }
  };

  const sendSyndicationSendMatchResponse = async (
    companyId: number,
    companyType: CompanyType[],
    payload: { [id: string]: any }
  ) => {
    try {
      const type = companyType.includes(CompanyType.SyndicationInvestor)
        ? 'portal'
        : 'onboarding';
      const path = `/companies/${companyId}/${type}/syndication/matches/action`;
      const response = await client.post(path, payload);
      return normalize.matchResponse.in(response);
    } catch (error: any) {
      console.error(error);

      toast.error(error?.message || 'Match acceptance could not be sent.');
    }
  };

  const sendSyndicationSendSawCard = async (
    companyId: number,
    companyType: CompanyType[],
    matchUuid: string
  ) => {
    try {
      const path = companyType.includes(CompanyType.SyndicationInvestor)
        ? `/companies/${companyId}/portal/syndication/matches/${matchUuid}/view`
        : `/companies/${companyId}/onboarding/syndication/matches/${matchUuid}/view`;
      await client.get(path);
    } catch (error: any) {
      console.error(error);
    }
  };

  const getSyndicationConditionsNeedingAcceptance = async (
    companyId: number
  ) => {
    try {
      const path = `/companies/${companyId}/portal/syndication/legal-agreements`;
      const response = await client.get(path);
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch syndication conditions');
    }
  };

  const sendSyndicationInvestorAcceptsLegalConditions = async (
    companyId: number,
    payload: { [id: string]: any }
  ) => {
    try {
      const path = `/companies/${companyId}/portal/syndication/legal-agreements`;
      return client.post(
        path,
        normalize.investorAgreementsAcceptance.out(payload)
      );
    } catch (error: any) {
      console.error(error);

      toast.error(
        error?.message || 'Legal conditions acceptance could not be saved.'
      );
    }
  };

  const sendSyndicationAssociateFileToOutOfFeeProofs = async (
    companyId: number,
    matchUuid: string,
    createdFileId: number
  ) => {
    try {
      const path = `/companies/${companyId}/portal/syndication/matches/${matchUuid}/files`;
      const payload = {
        file_id: createdFileId,
      };
      return await client.post(path, payload);
    } catch (error: any) {
      console.error(error);

      toast.error(error?.message || 'File could not be saved.');
    }
  };

  // used when you're company/investor and are at the cards view
  // loads list of accepted matches
  const getSyndicationOnboardingResults = async (
    companyId: number,
    companyType: CompanyType[]
  ) => {
    try {
      const path = companyType.includes(CompanyType.SyndicationInvestor)
        ? `/companies/${companyId}/portal/syndication/matches/accepted`
        : `/companies/${companyId}/onboarding/syndication/matches/accepted`;
      const response = await client
        .get(path, { withCredentials: true })
        .then((r) => r.map(normalize.syndicationOnboardingResult.in));
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch syndication matches');
    }
  };

  const getSyndicationMatchEvents = async (
    companyId: number,
    matchId: number
  ) => {
    try {
      const response = await client
        .get(`/companies/${companyId}/syndication/matches/${matchId}/events`, {
          withCredentials: true,
        })
        .then(normalize.syndicationMatchEvent.in);
      return { ...response, matchId };
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch syndication match events');
    }
  };

  const getSyndicationLoadOutOfFeeFiles = async (
    companyId: number,
    matchId: number
  ) => {
    try {
      const files =
        (await client.get(
          `/companies/${companyId}/syndication/matches/${matchId}/files`,
          {
            withCredentials: true,
          }
        )) || EMPTY_ARRAY;
      return files.map(normalize.file.in);
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch out of fee files');
    }
  };

  const getSyndicationDataroomFiles = async (companyId: number) => {
    const path = `/companies/${companyId}/syndication/dataroom`;
    try {
      const files = await client.get(path, { withCredentials: true });
      return files.map(normalize.file.in);
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch out of files');
    }
  };

  // used when you're investor and want to see cards associate
  // to you through a token
  const getSyndicationOnboardingMatchesForGuest = async (token: string) => {
    try {
      const path = `/syndication_investor/${token}/portal/syndication/matches`;
      const response = await client.get(path, { withCredentials: true });
      return response;
    } catch (error: any) {
      toast.error(
        error?.message || 'Failed to fetch syndication matches for guest.'
      );
    }
  };

  const getSyndicationMatchProfile = async (
    companyId: number,
    companyType: CompanyType[],
    uuid: string
  ): Promise<SyndicationMatchProfile> => {
    try {
      const path = companyType.includes(CompanyType.SyndicationInvestor)
        ? `/companies/${companyId}/portal/syndication/matches/${uuid}/company`
        : `/companies/${companyId}/onboarding/syndication/matches/${uuid}/syndication_investor`;
      const response = await client
        .get(path, { withCredentials: true })
        .then(normalize.syndicationMatchProfile.in);
      return response as SyndicationMatchProfile;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch syndication match events');

      throw error;
    }
  };

  const getSyndicationMatchProfileAnalytics = async (
    companyId: number,
    uuid: string,
    companyAnalysisId?: number
  ) => {
    const params = {};
    if (companyAnalysisId) {
      params['companyAnalysisId'] = companyAnalysisId;
    }
    try {
      const path = `/companies/${companyId}/portal/syndication/matches/${uuid}/company/charts/syndication`;
      const response = await client
        .get(path, { withCredentials: true, params })
        .then((charts) => charts.map(normalize.chart.in));

      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch analytics');
    }
  };

  const getSyndicationMatch = async (
    companyId: number,
    uuid: string
  ): Promise<SyndicationMatchProfile2> => {
    const path = `/companies/${companyId}/matches/${uuid}`;
    const response = await client
      .get(path, { withCredentials: true })
      .then(normalize.syndicationCompanyMatch2.in);
    return response;
  };

  const updateMatchesStatus = (dispatch: Dispatch<Action>) => (
    companyId: number,
    status: string,
    matchIds: number[]
  ) => {
    return request(dispatch)(
      ({ result }) =>
        apiSyndicationCompanyMatchUpdated({
          result,
          companyId,
        }),
      async () => {
        try {
          const response = await client.patch(
            `/companies/${companyId}/syndication/matches/status`,
            {
              status,
              'match-ids': matchIds,
            },
            {
              withCredentials: true,
            }
          );
          toast.success('Updated status for Matches!');
          return response;
        } catch (error: any) {
          console.error(error);
          toast.error(
            error?.message || `Failed to updated status for Matches.`
          );

          throw error;
        }
      }
    );
  };

  const updateMatchStatus = (dispatch: Dispatch<Action>) => (
    companyId: number,
    matchId: number,
    payload: UpdateMatchStatusPayload
  ) => {
    return request(dispatch)(
      ({ result }) =>
        apiSyndicationCompanyMatchUpdated({
          result,
          companyId: companyId,
        }),
      async () => {
        try {
          const response = await client.patch(
            `/companies/${companyId}/syndication/matches/${matchId}/status`,
            payload,
            {
              withCredentials: true,
            }
          );
          toast.success('Updated status for Match!');
          return response;
        } catch (error: any) {
          console.error(error);
          toast.error(
            error?.description ||
              error?.message ||
              `Failed to updated status for Match.`
          );

          return error;
        }
      }
    );
  };

  const sendMatchNotification = (dispatch: Dispatch<Action>) => (
    companyId: number,
    matchIds: number[]
  ) => {
    return request(dispatch)(
      ({ result }) =>
        apiSyndicationCompanyMatchCreated({
          result,
          companyId,
        }),
      async () => {
        try {
          const response = await client.post(
            `/companies/${companyId}/syndication/matches/notifications`,
            { match: matchIds },
            { withCredentials: true }
          );
          toast.success('Email with introduction sent to investor!');
          return response;
        } catch (error: any) {
          console.error(error);
          toast.error(error?.message || 'Failed to send introductions');

          return error;
        }
      }
    );
  };

  const updateMatchSendNotification = async (
    companyId: number,
    matchIds: number[]
  ) => {
    const response = await client.post(
      `/companies/${companyId}/syndication/matches/notifications`,
      { match: matchIds },
      { withCredentials: true }
    );
    return response;
  };

  const updateSkipSyndicationLegalAgreementsRequestStatus = (
    dispatch: Dispatch<Action>
  ) => (
    companyId: number,
    payload: UpdateSkipSyndicationLegalAgreementsRequestStatusPayload
  ) => {
    return request(dispatch)(
      ({ result }) =>
        apiSyndicationCompanyInformationStateChanged({ result, companyId }),
      async () => {
        try {
          const response = await client.patch(
            `/companies/${companyId}/syndication/skip-legal-agreements-request`,

            normalize.updateSkipSyndicationLegalAgreementsRequest.out(payload),
            {
              withCredentials: true,
            }
          );
          toast.success(
            "Successfully changed 'Skipping legal agreements acceptance' configuration."
          );
          return response;
        } catch (error: any) {
          console.error(error);
          toast.error(
            error?.description ||
              error?.message ||
              `Failed to updated status for 'Skipping Legal Agreements Acceptance'.`
          );

          return error;
        }
      }
    );
  };

  const updateCompanySyndicationStatus = (dispatch: Dispatch<Action>) => (
    companyId: number,
    payload: UpdateCompanyProcessStatusPayload
  ) => {
    return request(dispatch)(
      ({ result }) =>
        apiSyndicationCompanyInformationStateChanged({ result, companyId }),
      async () => {
        try {
          const response = await client.patch(
            `/companies/${companyId}/syndication/update-company-syndication-status`,
            normalize.updateCompanyProcessStatusPayload.out(payload),
            {
              withCredentials: true,
            }
          );
          toast.success(
            "Successfully changed 'Company syndication status' configuration."
          );
          return response;
        } catch (error: any) {
          console.error(error);
          toast.error(
            error?.description ||
              error?.message ||
              `Failed to update status for 'Company syndication status'.`
          );

          return error;
        }
      }
    );
  };

  const adminCompanySyndicationEnabledToggle = (dispatch: Dispatch<Action>) => (
    companyId: number,
    enabled: boolean
  ) => {
    return request(dispatch)(
      adminCompanySyndicationEnabledToggled,
      async () => {
        try {
          const response = await client.patch(
            `/companies/${companyId}/capital_application`,
            { application: { syndication_enabled: enabled } },
            { withCredentials: true }
          );
          toast.success(
            `Changed syndication status to ${enabled ? 'enabled' : 'disabled'}`
          );
          return response;
        } catch (error: any) {
          console.error(error);
          toast.error(
            error?.description ||
              error?.message ||
              `Failed to change syndication status`
          );

          return error;
        }
      }
    );
  };

  const getDataroomInsightsPerms = async (
    companyId: number,
    investorId: number
  ) => {
    const path = `/permissions/block/companies/${companyId}/investors/${investorId}`;
    const params = new URLSearchParams();
    params.append('permissions_set_name', 'financial_analysis');
    params.append('permissions_set_name', 'dataroom');
    const request = { params };
    try {
      return await client.get(path, request);
    } catch (error: any) {
      toast.error(error?.message || 'Failed to get company permissions');
    }
  };

  const getAdminDataroomInsightsPerms = async (companyId: number) => {
    const path = `/permissions/block/companies/${companyId}/investors`;
    const params = new URLSearchParams();
    params.append('permissions_set_name', 'financial_analysis');
    params.append('permissions_set_name', 'dataroom');
    const request = { params };
    try {
      return await client.get(path, request);
    } catch (error: any) {
      toast.error(error?.message || 'Failed to get admin company permissions');
    }
  };

  const updateDataroomInsightsPerms = (dispatch: Dispatch<Action>) => (
    company: Company,
    payload: UpdateDataroomInsightsPermsPayload
  ) => {
    return request(dispatch)(
      ({ result }) =>
        apiSyndicationCompanyInformationStateChanged({
          result,
          companyId: company.id,
        }),
      async () => {
        try {
          const response = await client.patch(
            `/capital_applications/${company.application.id}`,
            normalize.application.out(payload),
            {
              withCredentials: true,
            }
          );
          toast.success('Permissions changed successfully.');
          return response;
        } catch (error: any) {
          console.error(error);
          toast.error(
            error?.description ||
              error?.message ||
              `Failed to updated status for 'Permissions'.`
          );

          return error;
        }
      }
    );
  };

  const sendRejectReasons = (dispatch: Dispatch<Action>) => (
    companyId: number,
    uuid: string,
    reason: string
  ) => {
    return request(dispatch)(
      ({ result }) => matchFeedbackSent({ companyId, result }),
      async (): Promise<MatchResponse | undefined> => {
        try {
          const payload = {
            syndicationInvestorActionStatus: 'rejected',
            rejectedReason: reason,
          };

          const response = (await client
            .put(
              `companies/${companyId}/matches/${uuid}`,
              normalize.syndicationCompanyMatch.out(payload)
            )
            .then(normalize.matchResponse.in)) as MatchResponse;

          return response;
        } catch (error: any) {
          console.error(error);
          const message = error?.message || 'Failed to send your feedback.';
          toast.error(message);
        }
      }
    );
  };

  const sendInvestorAccept = (dispatch: Dispatch<Action>) => (
    companyId: number,
    uuid: string
  ) => {
    return request(dispatch)(
      ({ result }) => matchFeedbackSent({ companyId, result }),
      async (): Promise<MatchResponse | undefined> => {
        try {
          const payload = {
            syndicationInvestorActionStatus: 'accepted',
          };

          const response = (await client
            .put(
              `companies/${companyId}/matches/${uuid}`,
              normalize.syndicationCompanyMatch.out(payload)
            )
            .then(normalize.matchResponse.in)) as MatchResponse;

          return response;
        } catch (error: any) {
          console.error(error);
          const message = error?.message || 'Failed to send';
          toast.error(message);
          throw error;
        }
      }
    );
  };

  const getCompanyPublicInfo = async (token: string) => {
    try {
      const publicInfo = await client
        .get('/v1/companies/public_info', { params: { token: token } })
        .then(normalize.teaserPublicInfo.in);

      const publicFinances = await client
        .get('/v1/companies/public_finances', { params: { token: token } })
        .then(normalize.teaserPublicFinances.in)
        .catch(() => {
          return {};
        });

      return { publicInfo, publicFinances };
    } catch (error: any) {
      toast.error(error?.message || 'Failed to fetch company data');
    }
  };

  const sendRequestIntroduction = (dispatch: Dispatch<Action>) => (
    companyId: number,
    token: string
  ) => {
    return request(dispatch)(
      ({ result }) => requestIntroductionSent({ companyId, result }),
      async (): Promise<SyndicationMatchProfile2 | undefined> => {
        try {
          const payload = {
            token: token,
          };

          const response = await client
            .post(
              `companies/${companyId}/matches/request-introduction`,
              payload
            )
            .then(normalize.syndicationCompanyMatch2.in);

          return response;
        } catch (error: any) {
          console.error(error);
          const message = error?.message || 'Failed to send your feedback.';
          toast.error(message);
          throw error;
        }
      }
    );
  };

  const getSyndicationInvestorDetails = async (
    companyId: number,
    matchUuid: string
  ) => {
    const path = `/companies/${companyId}/syndication/match_investors/${matchUuid}`;
    try {
      return await client
        .get(path)
        .then((r) => normalize.syndicationInvestorDetail.in(r));
    } catch (error: any) {
      toast.error(error?.message || 'Failed to get investor details');
    }
  };

  const updateInvestorPermissions = async (
    companyId: number,
    investorUserId?: number,
    payload?: BlockPermissionsPayloadType
  ) => {
    try {
      const path = `/permissions/block/companies/${companyId}/investors/${investorUserId}`;
      const res = await client.post(path, payload);
      return res;
    } catch (error: any) {
      toast.error(
        error?.message || 'Failed to set investor company permissions'
      );
    }
  };

  const transactionDocumentUploadedEmail = async (
    companyId: number,
    matchUuid: string
  ) => {
    try {
      // api function transaction_document_uploaded_email()
      const response = await client.patch(
        `/companies/${companyId}/matches/${matchUuid}/transaction_document_uploaded_email`
      );
      return response;
    } catch (error: any) {
      throw error;
    }
  };

  const sendDealToArchive = async (
    companyId: number,
    matchUuid: string,
    userType: string,
    payload: MatchOutReasonsPayloadType
  ) => {
    const path = `/companies/${companyId}/syndication/archive_${userType}/${matchUuid}`;
    const response = await client
      .post(path, normalize.syndicationMatchOutReasonResult.out(payload))
      .then((res) => {
        if (res === null) {
          throw new Error('This company does not exist.');
        }
      })
      .catch((error) => {
        throw error;
      });
    return response;
  };

  const companyIsFundraising = async (
    companyId: number,
    matchUuid: string,
    isFundraising: boolean
  ) => {
    const path = `/companies/${companyId}/matches/${matchUuid}/company_is_fundraising`;
    const response = await client
      .put(
        path,
        normalize.syndicationCompanyIsFundraising.out({
          isFundraising: isFundraising,
        })
      )
      .then((res) => {
        if (res === null) {
          throw new Error('This company does not exist.');
        }
      })
      .catch((error) => {
        toast.error('Failed to notify when this company is fundraising.');
        throw error;
      });
    return response;
  };

  return {
    createSyndicationAPI,
    getSyndicationRecommendations,
    __DEPRECATED__getSyndicationMatches,
    getSyndicationMatches,
    getSyndicationMatchOutReasons,
    getPreIntroCharts,
    getSyndicationOnboardingMatches,
    getSyndicationExistingProofFiles,
    getMatchTocVersion,
    sendSyndicationInvestorShowsInterestInCompany,
    sendSyndicationSendMatchResponse,
    sendSyndicationSendSawCard,
    getSyndicationConditionsNeedingAcceptance,
    sendSyndicationInvestorAcceptsLegalConditions,
    sendSyndicationAssociateFileToOutOfFeeProofs,
    getSyndicationOnboardingResults,
    getSyndicationMatchEvents,
    getSyndicationLoadOutOfFeeFiles,
    getSyndicationDataroomFiles,
    getSyndicationOnboardingMatchesForGuest,
    getSyndicationMatchProfile,
    getSyndicationMatchProfileAnalytics,
    getSyndicationMatch,
    updateMatchesStatus,
    updateMatchStatus,
    sendMatchNotification,
    updateMatchSendNotification,
    updateSkipSyndicationLegalAgreementsRequestStatus,
    updateCompanySyndicationStatus,
    adminCompanySyndicationEnabledToggle,
    getDataroomInsightsPerms,
    getAdminDataroomInsightsPerms,
    updateDataroomInsightsPerms,
    sendRejectReasons,
    sendInvestorAccept,
    getCompanyPublicInfo,
    sendRequestIntroduction,
    getSyndicationInvestorDetails,
    updateInvestorPermissions,
    transactionDocumentUploadedEmail,
    sendDealToArchive,
    companyIsFundraising,
  };
};
