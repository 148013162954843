import { Application, Company } from '@hum/types';
import {
  CanCompanyBeAddedToInvestorSubdomainsInvestigation,
  CompanyActivationNotices,
  CompanyTOSByToken,
} from '../state';
import {
  BaseCompanyAction,
  BaseRequestStateChanged,
  actionCreator,
  BaseAction,
} from '@hum/common';

/*
 * Code related to: Sign up, Load your data, Connectors
 */

export enum OnboardingActionType {
  COMPANY_SIGNUP_REQUEST_STATE_CHANGED = 'COMPANY_SIGNUP_REQUEST_STATE_CHANGED',
  /**
   * @deprecated
   */
  ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_HOOK_USED = 'ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_HOOK_USED',
  API_ONBOARDING_COMPANY_NOTICE_EXISTENCE_STATE_CHANGED = 'API_ONBOARDING_COMPANY_NOTICE_EXISTENCE_STATE_CHANGED',
  API_ONBOARDING_COMPANY_ACTIVATION_NOTICE_EXISTENCE_STATE_CHANGED = 'API_ONBOARDING_COMPANY_ACTIVATION_NOTICE_EXISTENCE_STATE_CHANGED',
  API_ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_STATE_CHANGED = 'API_ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_STATE_CHANGED',
  API_COMPANY_TOS_BY_TOKEN_STATE_CHANGED = 'API_COMPANY_TOS_BY_TOKEN_STATE_CHANGED',
  ONBOARDING_ADD_COMPANY_TO_INVESTOR_SUBDOMAIN_HOOK_USED = 'ONBOARDING_ADD_COMPANY_TO_INVESTOR_SUBDOMAIN_HOOK_USED',
  API_ADD_COMPANY_TO_INVESTOR_SUBDOMAIN_STATE_CHANGED = 'API_ADD_COMPANY_TO_INVESTOR_SUBDOMAIN_STATE_CHANGED',
  ONBOARDING_V3_SAVE_REQUEST_STATE_CHANGED = 'ONBOARDING_V3_SAVE_REQUEST_STATE_CHANGED',
  ONBOARDING_V4_SAVE_REQUEST_STATE_CHANGED = 'ONBOARDING_V4_SAVE_REQUEST_STATE_CHANGED',
  BASIC_BUSINESS_FORM_SUBMITTED = 'BASIC_BUSINESS/FORM_SUBMITTED',
  ABOUT_YOU_FORM_SUBMITTED = 'ABOUT_YOU/FORM_SUBMITTED',
  FUNDRAISING_GOALS_FORM_SUBMITTED = 'FUNDRAISING_GOALS/FORM_SUBMITTED',
  REVIEW_AND_CONFIRM_FORM_SUBMITTED = 'REVIEW_AND_CONFIRM_FORM_SUBMITTED',
  FAILED_ONBOARDING_V5_SAVE_REQUEST = 'FAILED_ONBOARDING_V5_SAVE_REQUEST',
  SUCCEEDED_ONBOARDING_V5_SAVE_REQUEST = 'SUCCEEDED_ONBOARDING_V5_SAVE_REQUEST',
  PAGEVIEW_COMPANY_ONBOARDING_CONNECT = 'PAGEVIEW_COMPANY_ONBOARDING_CONNECT',
}

export type CompanySignupV5Payload = {
  companyPosition?: string;
  companyName?: string;
  website?: string;
  location?: string;
  country?: string;
  state?: string;
  application?: {
    yearFounded?: number;
    selfReportedIndustry?: string;
    revenueModel?: string;
    investmentAmount?: number;
    fundraisingTimeFrame?: string;
    fundingType?: string;
    useOfFunds?: string;
    capitalDeployment?: string;
    lastStageOfFunding?: string;
    dateOfLastRaise?: string;
    totalEquityRaised?: string;
    lastValuation?: string;
    reviewAndConfirmBy?: string;
  };
};
export type ApiCompanySignUpRequestStateChanged = BaseRequestStateChanged<
  OnboardingActionType.COMPANY_SIGNUP_REQUEST_STATE_CHANGED,
  { application: Application; company: Company }
>;
// TODO: Remove if not used
export const apiCompanySignUpRequestStateChanged = actionCreator<ApiCompanySignUpRequestStateChanged>(
  OnboardingActionType.COMPANY_SIGNUP_REQUEST_STATE_CHANGED
);

export type OnboardingWhetherCompanyCanBeAddedToSubdomainHookUsed = BaseCompanyAction<
  OnboardingActionType.ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_HOOK_USED,
  { investorSubdomain: string }
>;

export type ApiOnboardingCompanyNoticeExistenceStateChanged = BaseRequestStateChanged<
  OnboardingActionType.API_ONBOARDING_COMPANY_NOTICE_EXISTENCE_STATE_CHANGED,
  {
    ONBOARDING_WELCOME?: boolean;
    COMPANY_ONBOARDING_TOUR_COMPLETED?: boolean;
  }
>;

export type ApiOnboardingCompanyActivationNoticeExistenceStateChanged = BaseRequestStateChanged<
  OnboardingActionType.API_ONBOARDING_COMPANY_ACTIVATION_NOTICE_EXISTENCE_STATE_CHANGED,
  CompanyActivationNotices[]
>;

export type ApiOnboardingWhetherCompanyCanBeAddedToSubdomainStateChanged = BaseRequestStateChanged<
  OnboardingActionType.API_ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_STATE_CHANGED,
  CanCompanyBeAddedToInvestorSubdomainsInvestigation
>;

export type ApiOnboardingCompanyTOSByTokenStateChanged = BaseRequestStateChanged<
  OnboardingActionType.API_COMPANY_TOS_BY_TOKEN_STATE_CHANGED,
  CompanyTOSByToken
>;

export type BasicBusinessFormSubmitted = BaseAction<
  OnboardingActionType.BASIC_BUSINESS_FORM_SUBMITTED,
  { companyId: number; payload: CompanySignupV5Payload }
>;
export type AboutYouFormSubmitted = BaseAction<
  OnboardingActionType.ABOUT_YOU_FORM_SUBMITTED,
  { companyId: number; payload: CompanySignupV5Payload }
>;
export type FundraisingGoalsFormSubmitted = BaseAction<
  OnboardingActionType.FUNDRAISING_GOALS_FORM_SUBMITTED,
  { companyId: number; payload: CompanySignupV5Payload }
>;

export type ReviewAndConfirmFormSubmitted = BaseAction<
  OnboardingActionType.REVIEW_AND_CONFIRM_FORM_SUBMITTED,
  { companyId: number; payload: CompanySignupV5Payload }
>;

export const onboardingWhetherCompanyCanBeAddedToSubdomainHookUsed = actionCreator<OnboardingWhetherCompanyCanBeAddedToSubdomainHookUsed>(
  OnboardingActionType.ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_HOOK_USED
);

export const apiOnboardingCompanyNoticeExistenceStateChanged = actionCreator<ApiOnboardingCompanyNoticeExistenceStateChanged>(
  OnboardingActionType.API_ONBOARDING_COMPANY_NOTICE_EXISTENCE_STATE_CHANGED
);

export const apiOnboardingCompanyActivationNoticeExistenceStateChanged = actionCreator<ApiOnboardingCompanyActivationNoticeExistenceStateChanged>(
  OnboardingActionType.API_ONBOARDING_COMPANY_ACTIVATION_NOTICE_EXISTENCE_STATE_CHANGED
);

export const apiOnboardingCompanyTOSByTokenStateChanged = actionCreator<ApiOnboardingCompanyTOSByTokenStateChanged>(
  OnboardingActionType.API_COMPANY_TOS_BY_TOKEN_STATE_CHANGED
);

export const apiOnboardingWhetherCompanyCanBeAddedToSubdomainStateChanged = actionCreator<ApiOnboardingWhetherCompanyCanBeAddedToSubdomainStateChanged>(
  OnboardingActionType.API_ONBOARDING_WHETHER_COMPANY_CAN_BE_ADDED_TO_SUBDOMAIN_STATE_CHANGED
);

export type OnboardingAddCompanyToInvestorSubdomainHookUsed = BaseCompanyAction<
  OnboardingActionType.ONBOARDING_ADD_COMPANY_TO_INVESTOR_SUBDOMAIN_HOOK_USED,
  { investorSubdomain: string }
>;

export const onboardingAddCompanyToInvestorSubdomainHookUsed = actionCreator<OnboardingAddCompanyToInvestorSubdomainHookUsed>(
  OnboardingActionType.ONBOARDING_ADD_COMPANY_TO_INVESTOR_SUBDOMAIN_HOOK_USED
);

export type OnboardingV3SaveRequestStateChanged = BaseRequestStateChanged<
  OnboardingActionType.ONBOARDING_V3_SAVE_REQUEST_STATE_CHANGED,
  { application: Application; company: Company }
>;

export const onboardingV3SaveRequestStateChanged = actionCreator<any>(
  OnboardingActionType.ONBOARDING_V3_SAVE_REQUEST_STATE_CHANGED
);

export const onboardingV4SaveRequestStateChanged = actionCreator<any>(
  OnboardingActionType.ONBOARDING_V4_SAVE_REQUEST_STATE_CHANGED
);

export const basicBusinessFormSubmitted = actionCreator<BasicBusinessFormSubmitted>(
  OnboardingActionType.BASIC_BUSINESS_FORM_SUBMITTED
);
export const aboutYouFormSubmitted = actionCreator<AboutYouFormSubmitted>(
  OnboardingActionType.ABOUT_YOU_FORM_SUBMITTED
);
export const fundraisingGoalsFormSubmitted = actionCreator<FundraisingGoalsFormSubmitted>(
  OnboardingActionType.FUNDRAISING_GOALS_FORM_SUBMITTED
);

export const reviewAndConfirmFormSubmitted = actionCreator<ReviewAndConfirmFormSubmitted>(
  OnboardingActionType.REVIEW_AND_CONFIRM_FORM_SUBMITTED
);

export type FailedOnboardingV5SaveRequest = BaseAction<OnboardingActionType.FAILED_ONBOARDING_V5_SAVE_REQUEST>;

export const apiFailedWhenOnboardingV5SaveRequestSent = actionCreator<FailedOnboardingV5SaveRequest>(
  OnboardingActionType.FAILED_ONBOARDING_V5_SAVE_REQUEST
);

export type SucceededOnboardingV5SaveRequest = BaseAction<OnboardingActionType.SUCCEEDED_ONBOARDING_V5_SAVE_REQUEST>;

export const apiSucceededWhenOnboardingV5SaveRequestSent = actionCreator<SucceededOnboardingV5SaveRequest>(
  OnboardingActionType.SUCCEEDED_ONBOARDING_V5_SAVE_REQUEST
);

export type TrackPageviewOnboardingConnect = BaseAction<OnboardingActionType.PAGEVIEW_COMPANY_ONBOARDING_CONNECT>;

export const trackPageviewOnboardingConnect = actionCreator<TrackPageviewOnboardingConnect>(
  OnboardingActionType.PAGEVIEW_COMPANY_ONBOARDING_CONNECT
);

export type OnboardingAction =
  | ApiCompanySignUpRequestStateChanged
  | OnboardingWhetherCompanyCanBeAddedToSubdomainHookUsed
  | ApiOnboardingWhetherCompanyCanBeAddedToSubdomainStateChanged
  | OnboardingAddCompanyToInvestorSubdomainHookUsed
  | ApiOnboardingCompanyNoticeExistenceStateChanged
  | ApiOnboardingCompanyActivationNoticeExistenceStateChanged
  | ApiOnboardingCompanyTOSByTokenStateChanged
  | BasicBusinessFormSubmitted
  | AboutYouFormSubmitted
  | FundraisingGoalsFormSubmitted
  | ReviewAndConfirmFormSubmitted
  | FailedOnboardingV5SaveRequest
  | SucceededOnboardingV5SaveRequest;
