/**
 * re-usable API functions
 */

import React from 'react';
import { creatreLegacyAPI } from './legacy';
import { createAuthAPI } from './auth';
import { createCapitalApplicationAPI } from './capital-application';
import { createOnboardingAPI } from './onboarding';
import { createPortfolioMonitoringAPI } from './portfolio-monitoring';
import { createPrincipleInvestmentAPI } from './principal-investment';
import { createSignupv5API } from './signupv5';
import { createSyndicationAPI } from './syndication';
import { createAdminAPI } from './admin';
import { createCompanyDashboardAPI } from './companyDashboard';
import { createConnectorsAPI } from './connectors';
import { createNoticeAPI } from './notices';
import { Backend } from '@hum/common/src/api/client';

export type CreateAPIOptions = {
  client: Backend;
};

export type API = ReturnType<typeof createAPI>;

export const createAPI = (options: CreateAPIOptions) => {
  return {
    legacy: creatreLegacyAPI(options),
    auth: createAuthAPI(options),
    admin: createAdminAPI(options),
    capitalApplication: createCapitalApplicationAPI(options),
    onboarding: createOnboardingAPI(options),
    portfolioMonitoring: createPortfolioMonitoringAPI(options),
    principalInvestment: createPrincipleInvestmentAPI(options),
    signupv5: createSignupv5API(options),
    syndication: createSyndicationAPI(options),
    companyDashboard: createCompanyDashboardAPI(options),
    connectors: createConnectorsAPI(options),
    notices: createNoticeAPI(options),
  };
};

export const APIContext = React.createContext<API | null>(null);
