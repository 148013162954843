import {
  actionCreator,
  BaseCompanyAction,
  BaseCompaniesAction,
} from '@hum/common';
import { BaseRequestStateChanged } from '@hum/common/src/ducks/actions';
import {
  PortfolioMonitoringInvite,
  CompanyPortfolioInvite,
  PortfolioMonitoringOnboarding,
  PortfolioMonitoringDashboard,
  PortfolioMonitoringConnectorStatus,
} from '../state';
import { Result } from '@hum/common/src/ducks/state';

export enum PortfolioMonitoringActionType {
  PM_INVITED_COMPANIES_USED = 'PM_INVITED_COMPANIES_USED',
  PM_INVITED_COMPANIES_STATE_CHANGED = 'PM_INVITED_COMPANIES_STATE_CHANGED',
  PM_SOURCE_CSV_FILE_UPLOADED = 'PM_SOURCE_CSV_FILE_UPLOADED',
  PM_INVITE_CREATED = 'PM_INVITE_CREATED',
  PM_INVITE_UPDATED = 'PM_INVITE_UPDATED',
  PM_BULK_STATUS_UPDATED = 'PM_BULK_STATUS_UPDATED',
  /**
   * @deprecated
   */
  PM_COMPANY_INVITE_LIST_USED = 'PM_COMPANY_INVITE_LIST_USED',
  PM_COMPANY_INVITE_LIST_STATE_CHANGED = 'PM_COMPANY_INVITE_LIST_STATE_CHANGED',
  PM_COMPANY_INVITE_RESPONSE_SENT = 'PM_COMPANY_INVITE_RESPONSE_SENT',
  PM_SHOW_ONBOARDING_HOOK_USED = 'PM_SHOW_ONBOARDING_HOOK_USED',
  PM_SHOW_ONBOARDING_STATE_CHANGED = 'PM_SHOW_ONBOARDING_STATE_CHANGED',
  PM_PORTFOLIO_COMPANY_INVITE_URL_HOOK_USED = 'PM_PORTFOLIO_COMPANY_INVITE_URL_HOOK_USED',
  PM_PORTFOLIO_COMPANY_INVITE_URL_STATE_CHANGED = 'PM_PORTFOLIO_COMPANY_INVITE_URL_STATE_CHANGED',
  PM_DASHBOARD_HOOK_USED = 'PM_DASHBOARD_HOOK_USED',
  PM_DASHBOARD_STATE_CHANGED = 'PM_DASHBOARD_STATE_CHANGED',
  PM_CONNECTORS_STATUS_HOOK_USED = 'PM_CONNECTORS_STATUS_HOOK_USED',
  PM_CONNECTORS_STATUS_STATE_CHANGED = 'PM_CONNECTORS_STATUS_STATE_CHANGED',
  PM_PORTFOLIO_COMPANY_CONNECTOR_STATUSES_HOOK_USED = 'PM_PORTFOLIO_COMPANY_CONNECTOR_STATUSES_HOOK_USED',
  PM_PORTFOLIO_COMPANY_CONNECTOR_STATUSES_STATE_CHANGED = 'PM_PORTFOLIO_COMPANY_CONNECTOR_STATUSES_STATE_CHANGED',
  PM_PORTFOLIO_COMPANIES_HOOK_USED = 'PM_PORTFOLIO_COMPANIES_HOOK_USED',
  PM_PORTFOLIO_COMPANIES_STATE_CHANGED = 'PM_PORTFOLIO_COMPANIES_STATE_CHANGED',
}

export type PM_InvitedCompaniesUsed = BaseCompanyAction<PortfolioMonitoringActionType.PM_INVITED_COMPANIES_USED>;

export const PM_invitedCompaniesUsed = actionCreator<PM_InvitedCompaniesUsed>(
  PortfolioMonitoringActionType.PM_INVITED_COMPANIES_USED
);

export type PM_InvitedCompaniesStateChanged = BaseRequestStateChanged<
  PortfolioMonitoringActionType.PM_INVITED_COMPANIES_STATE_CHANGED,
  PortfolioMonitoringInvite[]
>;

export const PM_invitedCompaniesStateChanged = actionCreator<PM_InvitedCompaniesStateChanged>(
  PortfolioMonitoringActionType.PM_INVITED_COMPANIES_STATE_CHANGED
);

export type PM_SourceCsvFileUploaded = BaseCompanyAction<
  PortfolioMonitoringActionType.PM_SOURCE_CSV_FILE_UPLOADED,
  { fileId: number }
>;

export const PM_sourceCsvFileUploaded = actionCreator<PM_SourceCsvFileUploaded>(
  PortfolioMonitoringActionType.PM_SOURCE_CSV_FILE_UPLOADED
);

export type PM_InviteCreated = BaseCompanyAction<
  PortfolioMonitoringActionType.PM_INVITE_CREATED,
  { result: Result<PortfolioMonitoringInvite> }
>;

export const PM_inviteCreated = actionCreator<PM_InviteCreated>(
  PortfolioMonitoringActionType.PM_INVITE_CREATED
);

export type PM_InviteUpdated = BaseCompanyAction<
  PortfolioMonitoringActionType.PM_INVITE_UPDATED,
  { result: Result<PortfolioMonitoringInvite> }
>;

export const PM_inviteUpdated = actionCreator<PM_InviteUpdated>(
  PortfolioMonitoringActionType.PM_INVITE_UPDATED
);

export type PM_BulkStatusUpdated = BaseCompanyAction<
  PortfolioMonitoringActionType.PM_BULK_STATUS_UPDATED,
  { result: any }
>;

export const PM_bulkStatusUpdated = actionCreator<PM_BulkStatusUpdated>(
  PortfolioMonitoringActionType.PM_BULK_STATUS_UPDATED
);

export type PM_CompanyInviteListUsed = BaseCompanyAction<
  PortfolioMonitoringActionType.PM_COMPANY_INVITE_LIST_USED,
  { companyId: number }
>;

/**
 * @deprecated
 */
export const PM_companyInviteListUsed = actionCreator<PM_CompanyInviteListUsed>(
  PortfolioMonitoringActionType.PM_COMPANY_INVITE_LIST_USED
);

export type PM_CompanyInviteListStateChanged = BaseRequestStateChanged<
  PortfolioMonitoringActionType.PM_COMPANY_INVITE_LIST_STATE_CHANGED,
  { result: Result<CompanyPortfolioInvite>; companyId: number }
>;

export const PM_companyInviteListStateChanged = actionCreator<PM_CompanyInviteListStateChanged>(
  PortfolioMonitoringActionType.PM_COMPANY_INVITE_LIST_STATE_CHANGED
);

export type PM_CompanyInviteResponseSent = BaseCompanyAction<
  PortfolioMonitoringActionType.PM_COMPANY_INVITE_RESPONSE_SENT,
  { result: Result<CompanyPortfolioInvite>; companyId: number }
>;

export const PM_companyInviteResponseSent = actionCreator<PM_CompanyInviteResponseSent>(
  PortfolioMonitoringActionType.PM_COMPANY_INVITE_RESPONSE_SENT
);

export type PM_ShowOnboardingHookUsed = BaseCompanyAction<PortfolioMonitoringActionType.PM_SHOW_ONBOARDING_HOOK_USED>;

export const PM_showOnboardingHookUsed = actionCreator<PM_ShowOnboardingHookUsed>(
  PortfolioMonitoringActionType.PM_SHOW_ONBOARDING_HOOK_USED
);

export type PM_ShowOnboardingStateChanged = BaseRequestStateChanged<
  PortfolioMonitoringActionType.PM_SHOW_ONBOARDING_STATE_CHANGED,
  Result<PortfolioMonitoringOnboarding>
>;

export const PM_showOnboardingStateChanged = actionCreator<PM_ShowOnboardingStateChanged>(
  PortfolioMonitoringActionType.PM_SHOW_ONBOARDING_STATE_CHANGED
);

export type PM_PortfolioCompanyInviteUrlHookUsed = BaseCompanyAction<PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANY_INVITE_URL_HOOK_USED>;

export const PM_portfolioCompanyInviteUrlHookUsed = actionCreator<PM_PortfolioCompanyInviteUrlHookUsed>(
  PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANY_INVITE_URL_HOOK_USED
);

export type PM_PortfolioCompanyInviteUrlStateChanged = BaseRequestStateChanged<
  PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANY_INVITE_URL_STATE_CHANGED,
  Result<PortfolioMonitoringOnboarding>
>;

export const PM_portfolioCompanyInviteUrlStateChanged = actionCreator<PM_PortfolioCompanyInviteUrlStateChanged>(
  PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANY_INVITE_URL_STATE_CHANGED
);

export type PM_DashboardHookUsed = BaseCompaniesAction<PortfolioMonitoringActionType.PM_DASHBOARD_HOOK_USED>;

export const PM_dashboardHookUsed = actionCreator<PM_DashboardHookUsed>(
  PortfolioMonitoringActionType.PM_DASHBOARD_HOOK_USED
);

export type PM_DashboardStateChanged = BaseRequestStateChanged<
  PortfolioMonitoringActionType.PM_DASHBOARD_STATE_CHANGED,
  Result<PortfolioMonitoringDashboard>
>;

export const PM_dashboardStateChanged = actionCreator<PM_DashboardStateChanged>(
  PortfolioMonitoringActionType.PM_DASHBOARD_STATE_CHANGED
);

export type PM_ConnectorsStatusHookUsed = BaseCompanyAction<PortfolioMonitoringActionType.PM_CONNECTORS_STATUS_HOOK_USED>;

export const PM_connectorsStatusHookUsed = actionCreator<PM_ConnectorsStatusHookUsed>(
  PortfolioMonitoringActionType.PM_CONNECTORS_STATUS_HOOK_USED
);

export type PM_ConnectorsStatusChanged = BaseRequestStateChanged<
  PortfolioMonitoringActionType.PM_CONNECTORS_STATUS_STATE_CHANGED,
  Result<PortfolioMonitoringConnectorStatus>
>;

export const PM_connectorsStatusChanged = actionCreator<PM_ConnectorsStatusChanged>(
  PortfolioMonitoringActionType.PM_CONNECTORS_STATUS_STATE_CHANGED
);

export type PM_PortfolioCompaniesHookUsed = BaseCompanyAction<PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANIES_HOOK_USED>;

export const PM_portfolioCompaniesHookUsed = actionCreator<PM_PortfolioCompaniesHookUsed>(
  PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANIES_HOOK_USED
);

export type PM_PortfolioCompaniesStateChanged = BaseRequestStateChanged<
  PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANIES_STATE_CHANGED,
  Result<PortfolioMonitoringDashboard>
>;

export const PM_portfolioCompaniesStateChanged = actionCreator<PM_PortfolioCompaniesStateChanged>(
  PortfolioMonitoringActionType.PM_PORTFOLIO_COMPANIES_STATE_CHANGED
);

export type PortfolioMonitoringAction =
  | PM_InvitedCompaniesUsed
  | PM_InvitedCompaniesStateChanged
  | PM_SourceCsvFileUploaded
  | PM_InviteCreated
  | PM_InviteUpdated
  | PM_BulkStatusUpdated
  | PM_CompanyInviteListUsed
  | PM_CompanyInviteListStateChanged
  | PM_CompanyInviteResponseSent
  | PM_ShowOnboardingHookUsed
  | PM_ShowOnboardingStateChanged
  | PM_PortfolioCompanyInviteUrlHookUsed
  | PM_PortfolioCompanyInviteUrlStateChanged
  | PM_DashboardHookUsed
  | PM_DashboardStateChanged
  | PM_ConnectorsStatusHookUsed
  | PM_ConnectorsStatusChanged
  | PM_PortfolioCompaniesHookUsed
  | PM_PortfolioCompaniesStateChanged;
