import { CreditBoxReport } from '@hum/types';
import {
  Branding,
  CompanyInvitation,
  SyndicationMatchProfile,
  SyndicationMatchProfile2,
} from '@hum/icm-app/src/state';
import {
  businessTypesToSelectOptions,
  investmentRangesToSelectOptions,
  investmentTypesToSelectOptions,
  renameKeys,
  tagsToSelectOptions,
} from '@hum/icm-app/src/backend/api/utils';

import { RequiredInsights } from '@hum/icm-app/src/components/types';
import { SelectOption } from '@hum/legacy-ui';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { application } from '@hum/icm-app/src/backend/api/models/applicationModels';
/* eslint-disable @typescript-eslint/no-explicit-any, no-shadow */

const user = {
  in: renameKeys({
    userId: 'id',
    first_name: 'firstName',
    last_name: 'lastName',
    company_position: 'position',
    company_id: 'companyID',
    is_primary_contact: 'isPrimaryContact',
    auth_providers: 'authProviders',
    preferred_host: 'preferredHost',
    current_password: 'currentPassword',
    new_email: 'newEmail',
    verified_email: 'verifiedEmail',
    verified_email_on: 'verifiedEmailOn',
    accepted_company_communications_opt_in:
      'acceptedCompanyCommunicationsOptIn',
  }),

  out: renameKeys({
    id: 'userId',
    firstName: 'first_name',
    lastName: 'last_name',
    position: 'company_position',
    companyID: 'company_id',
    companyId: 'company_id',
    authProviders: 'auth_providers',
    preferredHost: 'preferred_host',
    currentPassword: 'current_password',
    newEmail: 'new_email',
    verifiedEmail: 'verified_email',
    verifiedEmailOn: 'verified_email_on',
  }),
};

const teaserPublicInfo = {
  in: renameKeys({
    ebitda_burn: 'ebitdaBurn',
    equity_raised: 'totalEquityRaised',
    existing_debt: 'existingDebt',
    year_over_year_growth_rate: 'yearOverYearGrowthRate',
    latest_post_money_valuation: 'latestPostMoneyValuation',
    fundraising_time_frame: 'fundraisingTimeFrame',
    yearly_revenue: 'yearlyRevenue',
    investment_amount: 'investmentAmount',
    target_debt_raise: 'targetDebtRaise',
    use_of_funds: 'useOfFunds',
    vertical_industry: 'industryVertical',
    revenue_model: 'revenueModel',
    anonymous_description: 'publicDescription',
    show_pre_intro_charts: 'showPreIntroCharts',
  }),
};

const teaserPublicFinances = {
  in: (data: any) => {
    const teaserData = renameKeys({
      revenue: 'revenue',
      margins: 'margins',
      ltv_cac: 'ltvCac',
      valuation: 'valuation',
      equity_raise: 'equityRaise',
      revenue_as_of: 'revenueAsOf',
      margins_as_of: 'marginsAsOf',
      qoq_revenue_growth_rate: 'qoqRevenueGrowthRate',
      qoq_revenue_growth_rate_as_of: 'qoqRevenueGrowthRateAsOf',
      yoy_revenue_growth_rate: 'yoyRevenueGrowthRate',
      yoy_revenue_growth_rate_as_of: 'yoyRevenueGrowthRateAsOf',
      run_rate_revenue: 'runRateRevenue',
      run_rate_revenue_as_of: 'runRateRevenueAsOf',
      founded_year: 'foundedYear',
    })(data);

    return {
      ...teaserData,
    };
  },
  out: renameKeys({
    revenue: 'revenue',
    margins: 'margins',
    ltvCac: 'ltv_cac',
    valuation: 'valuation',
    equityRaise: 'equity_raise',
    revenueAsOf: 'revenue_as_of',
    marginsAsOf: 'margins_as_of',
    runRateRevenue: 'run_rate_revenue',
    runRateRevenueAsOf: 'run_rate_revenue_as_of',
    foundedYear: 'founded_year',
  }),
};

const collectUtmParamsWithRenamedKeys = (renameMap: any) => (dict: {
  [key: string]: string | string[] | undefined;
}) => {
  const filteredUtmParams = {
    utmMedium: dict.utmMedium,
    utmSource: dict.utmSource,
    utmCampaign: dict.utmCampaign,
    utmTerm: dict.utmTerm,
    utmContent: dict.utmContent,
  };
  return renameKeys(renameMap)(filteredUtmParams);
};

const companyInvitation = {
  in: (data: CompanyInvitation) => {
    return {
      id: data.id,
      company: company.in(data.company),
      owner: user.in(data.owner),
    };
  },
  out: (data: any) => {
    return {
      id: data.id,
      company: company.out(data.company),
      owner: user.out(data.owner),
    };
  },
};

const utmParams = {
  in: renameKeys({
    utm_medium: 'utmMedium',
    utm_source: 'utmSource',
    utm_campaign: 'utmCampaign',
    utm_term: 'utmTerm',
    utm_content: 'utmContent',
  }),
  out: collectUtmParamsWithRenamedKeys({
    utmMedium: 'utm_medium',
    utmSource: 'utm_source',
    utmCampaign: 'utm_campaign',
    utmTerm: 'utm_term',
    utmContent: 'utm_content',
  }),
};

const pmConnectorStatus = {
  in: renameKeys({
    id: 'id',
    name: 'name',
    contact_name: 'contactName',
    contact_email: 'contactEmail',
    status: 'status',
  }),
};

const activationStatus = {
  in: renameKeys({
    has_company_configured_dataroom: 'hasCompanyConfiguredDataroom',
    has_company_configured_connector: 'hasCompanyConfiguredConnector',
    has_company_uploaded_additional_files: 'hasCompanyUploadedAdditionalFiles',
    has_company_validated_funding_profile: 'hasCompanyValidatedFundingProfile',
    has_company_shared_a_file_with_investor:
      'hasCompanySharedAFileWithInvestor',
    missing_file_types_in_dataroom: 'missingFileTypesInDataroom',
  }),
};

const signupStatus = {
  in: renameKeys({
    has_basic_business_info: 'hasBasicBusinessInfo',
    has_fundraising_history: 'hasFundraisingHistory',
    has_fundraising_goals: 'hasFundaisingGoals',
    complete: 'complete',
  }),
};

const renameCompanyKeys = renameKeys({
  logo_url: 'logo',
  created_at: 'createdAt',
  updated_at: 'updatedAt',
  contact_id: 'contactId',
  business_type: 'businessType',
  business_type_for_syndication: 'businessTypeForSyndication',
  anonymous_description: 'publicDescription',
  capital_application: 'application',
  teaser_data: 'teaserData',
  show_pre_intro_charts: 'showPreIntroCharts',
  syndication_investor: 'syndicationInvestor', // here primarily to avoid duplicated key @ mapped
  accepted_tos: 'acceptedToS',
  accepted_tos_on: 'acceptedToSOn',
  accepted_fee_tos: 'acceptedFeeToS',
  accepted_fee_tos_on: 'acceptedFeeToSOn',
  accepted_nda: 'acceptedNDA',
  accepted_nda_on: 'acceptedNDAOn',
  labeling_completed: 'labelingCompleted',
  frozen_company_analysis_id: 'frozenCompanyAnalysisId',
  process_status: 'processStatus',
  analytics_status: 'analyticsStatus',
  pending_mnda_sign_offline: 'pendingMndaSignOffline',
  signup_status: 'signupStatus',
});

const renameCompanySyndicationDetailsKeys = renameKeys({
  company_name: 'companyName',
  company_description: 'companyDescription',
});

const branding = {
  in: (data: any) =>
    renameKeys({
      primary_color: 'primaryColor',
      logo_preset: 'preset',
      logo_file_id: 'logoFileId',
      logo_file_url: 'logoFileUrl',
      edited_logo_file_id: 'editedLogoFileId',
      edited_logo_file_url: 'editedLogoFileUrl',
    })(data),
  out: (data: Branding) => ({
    primary_color: data.primaryColor,
    logo_preset: data.preset || 'full_color',
    logo_file_id: data.logoFileId,
    edited_logo_file_id: data.editedLogoFileId,
  }),
};

const tags = {
  out: (tags: SelectOption[]) => {
    return { tags: tags?.map((tag) => tag.value) || [] };
  },
};

const company = {
  in: (data: any) => {
    return {
      ...renameCompanyKeys(data),
      contact: data.contact && user.in(data.contact),
      syndicationInvestor:
        data.syndication_investor &&
        syndicationInvestorDetail.in(data.syndication_investor),
      application: application.in(data.capital_application),
      teaserData: teaserPublicFinances.in(data.teaser_data),
      tags: tagsToSelectOptions(data.tags),
      activationStatus:
        data.activation_status && activationStatus.in(data.activation_status),
      signupStatus: data.signup_status && signupStatus.in(data.signup_status),
      analyticsStatus: data.analytics_status,
    };
  },
  out: ({ website, tags, ...keys }: any) => {
    const mapped = website
      ? {
          ...keys,
          website:
            website.startsWith('http://') || website.startsWith('https://')
              ? website.toLowerCase()
              : `https://${website.toLowerCase()}`,
        }
      : keys;

    return renameKeys({
      logo: 'logo_url',
      createdAt: 'created_at',
      updatedAt: 'updated_at',
      pendingMndaSignOffline: 'pending_mnda_sign_offline',
      businessType: 'business_type',
      businessTypeForSyndication: 'business_type_for_syndication',
      publicDescription: 'anonymous_description',
      acceptedToS: 'accepted_tos',
      acceptedNDA: 'accepted_nda',
      labelingCompleted: 'labeling_completed',
    })(mapped);
  },
};

const companySyndicationDetails = {
  in: (data: any) => {
    return renameCompanySyndicationDetailsKeys(data);
  },
};

const companyDataAsset = {
  in: renameKeys({
    company_id: 'companyId',
    connector_task_id: 'connectorTaskId',
    display_name: 'displayName',
    created_at: 'createdAt',
    data_type: 'dataType',
  }),
};

const companySettings = {
  in: identity,
  out: identity,
};

const companyOperator = {
  cleanOut: renameKeys({
    id: 'userId',
    firstName: 'first_name',
    lastName: 'last_name',
    position: 'company_position',
  }),
  out: renameKeys({
    id: 'userId',
    firstName: 'first_name',
    lastName: 'last_name',
    position: 'company_position',
  }),
};

const invite = {
  in: renameKeys({
    user_id: 'user',
    company_id: 'company',
    invite_url: 'url',
    created_at: 'createdAt',
    updated_at: 'updatedAt',
  }),

  out: renameKeys({
    user: 'user_id',
    company: 'company_id',
    url: 'invite_url',
    createdAt: 'created_at',
    updatedAt: 'updated_at',
  }),
};

const customerTapeMapping = {
  in: renameKeys({
    customer_id: 'customerId',
    order_id: 'orderId',
    transaction_date: 'transactionDate',
    amount: 'amount',
    customer_acquire_date: 'customerAcquireDate',
  }),

  out: renameKeys({
    customerId: 'customer_id',
    orderId: 'order_id',
    transactionDate: 'transaction_date',
    amount: 'amount',
    customerAcquireDate: 'customer_acquire_date',
  }),
};

const fileMeta = {
  out: (data: any) => {
    return renameKeys({
      customerTapeMapping: 'customer_tape_mapping',
    })(
      data && {
        ...data,
        customerTapeMapping: customerTapeMapping.out(data.customerTapeMapping),
      }
    );
  },
};

const file = {
  in: (data: any) => {
    let file = renameKeys({
      file_type: 'type',
      company_id: 'companyId',
      document_type: 'documentType',
      is_external: 'isExternal',
      created_by: 'createdBy',
      created_at: 'createdAt',
      updated_at: 'updatedAt',
      ingested_at: 'ingestedAt',
      ingestion_status: 'ingestionStatus',
      presigned_post: 'filePresignedPost',
    })(data);

    file = file.meta?.customer_tape_mapping
      ? {
          ...file,
          customerTapeMapping: customerTapeMapping.in(
            file.meta.customer_tape_mapping
          ),
        }
      : file;

    file = file.source_url
      ? { ...file, source: { url: file.source_url, path: file.source_path } }
      : file;

    return file;
  },
  out: ({ source, ...rest }: any) => {
    const { customerTapeMapping, ...rest2 }: any = source
      ? { ...rest, source_url: source.url, source_path: source.path }
      : rest;

    let data = customerTapeMapping
      ? {
          ...rest2,
          customerTapeMapping: undefined,
          meta: {
            ...rest2.meta,
            customer_tape_mapping: customerTapeMapping.out(customerTapeMapping),
          },
        }
      : rest2;

    data = pickBy(
      {
        ...data,
        meta: fileMeta.out(data.meta),
      },
      (v) => v != null
    );

    return renameKeys({
      type: 'file_type',
      company: 'company_id',
      documentType: 'document_type',
      isExternal: 'is_external',
      createdBy: 'created_by',
      createdAt: 'created_at',
      updatedAt: 'updated_at',
    })(data);
  },
};

const dataDump = {
  in: renameKeys({
    company_id: 'company', // TODO: @Jafet let's rename this
    data_type: 'dataType',
    error_message: 'error',
    source_file_id: 'sourceFileId',
    created_by: 'createdBy',
    updated_at: 'updatedAt',
  }),

  out: renameKeys({
    company: 'company_id',
    dataType: 'data_type',
    error: 'error_message',
    sourceFileId: 'source_file_id',
    createdBy: 'created_by',
  }),
};

const capitalMachineModel = {
  in: renameKeys({
    company_id: 'company',
    error_message: 'error',
    drive_file_id: 'driveFileId',
    output_file_id: 'outputFileId',
    master_s3_file_id: 'masterS3FileId',
    master_s3_file_url: 'masterS3FileUrl',
    created_by: 'createdBy',
    updated_at: 'updatedAt',
  }),

  out: renameKeys({
    company: 'company_id',
    error: 'error_message',
    driveFileId: 'drive_file_id',
    outputFileId: 'output_file_id',
    masterS3FileId: 'master_s3_file_id',
    masterS3FileUrl: 'master_s3_file_url',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
  }),
};

const plaidItem = {
  in: renameKeys({
    company_id: 'company',
    created_at: 'createdAt',
    created_by: 'createdBy',
    institution_name: 'institutionName',
    item_id: 'itemId',
    updated_at: 'updatedAt',
    public_token: 'publicToken',
  }),
  out: renameKeys({
    company: 'company_id',
    createdAt: 'created_at',
    createdBy: 'created_by',
    institutionName: 'institution_name',
    itemId: 'item_id',
    updatedAt: 'updated_at',
    publicToken: 'public_token',
  }),
};

const quickBooksConnection = {
  in: renameKeys({
    company_id: 'company',
    realm_id: 'realm',
  }),
  out: renameKeys({
    company: 'company_id',
    realm: 'realm_id',
    token: 'code',
  }),
};

const stripeConnection = {
  in: renameKeys({ company_id: 'company' }),
};

const companyConnector = {
  in: renameKeys({
    company_id: 'company',
    connector_type: 'connectorType',
    created_at: 'createdAt',
    created_by: 'createdBy',
    updated_at: 'updatedAt',
  }),
  out: renameKeys({
    company: 'company_id',
    connectorType: 'connector_type',
    createdAt: 'created_at',
    createdBy: 'created_by',
    updatedAt: 'updated_at',
  }),
};

const syndicationInvestorDetail = {
  in: (data: any) => {
    const {
      investmentRanges,
      investmentTypes,
      investmentBusinessTypes,
      ...detail
    } = renameKeys({
      investment_types: 'investmentTypes',
      company_id: 'companyId',
      investor_user_id: 'investorUserId',
      investment_ranges: 'investmentRanges',
      syndication_investment_business_types: 'investmentBusinessTypes',
      investment_yearly_revenue_min: 'investmentYearlyRevenueMin',
      investment_yearly_revenue_max: 'investmentYearlyRevenueMax',
      skip_legal_agreements_request: 'skipLegalAgreementsRequest',
    })(data);
    return {
      ...detail,
      investmentRanges: investmentRangesToSelectOptions(investmentRanges),
      investmentTypes: investmentTypesToSelectOptions(investmentTypes),
      investmentBusinessTypes: businessTypesToSelectOptions(
        investmentBusinessTypes
      ),
    };
  },
  out: renameKeys({
    investmentTypes: 'investment_types',
    investmentRanges: 'investment_ranges',
    investmentBusinessTypes: 'investment_business_types',
    investmentYearlyRevenueMin: 'investment_yearly_revenue_min',
    investmentYearlyRevenueMax: 'investment_yearly_revenue_max',
  }),
};

const syndicationCompanyDetail = {
  in: renameKeys({
    investment_types: 'investmentTypes',
    investment_ranges: 'investmentRanges',
    yearly_revenue_for_syndication: 'yearlyRevenueForSyndication',
  }),
  out: renameKeys({
    investmentTypes: 'investment_types',
    investmentRanges: 'investment_ranges',
    investmentAmount: 'investment_amount',
    yearlyRevenueForSyndication: 'yearly_revenue_for_syndication',
  }),
};

const syndicationCompanyMatch = {
  in: (data: any) => {
    const { syndicationInvestor, ...fields } = renameKeys({
      company_action_status: 'companyActionStatus',
      company_action_updated_at: 'companyActionUpdatedAt',
      company_id: 'companyId',
      company_sent_at: 'companySentAt',
      company_viewed_at: 'companyViewedAt',
      created_at: 'createdAt',
      deleted_by: 'deletedBy',
      syndication_investor: 'syndicationInvestor',
      syndication_investor_action_status: 'syndicationInvestorActionStatus',
      syndication_investor_action_updated_at:
        'syndicationInvestorActionUpdatedAt',
      syndication_investor_id: 'syndicationInvestorId',
      syndication_investor_sent_at: 'syndicationInvestorSentAt',
      syndication_investor_viewed_at: 'syndicationInvestorViewedAt',
      updated_at: 'updatedAt',
      opt_out_of_fee: 'optOutOfFee',
    })(data);

    return {
      ...fields,
      syndicationInvestor: syndicationInvestorDetail.in(syndicationInvestor),
    };
  },
  out: renameKeys({
    syndicationInvestorActionStatus: 'syndication_investor_action_status',
    rejectedReason: 'rejected_reason',
  }),
};

const syndicationCompanyMatch2 = {
  in: (data: any): SyndicationMatchProfile2 => {
    return {
      company: data.company && company.in(data.company),
      uuid: data.uuid,
      status: data.status,
    };
  },
};

const syndicationMatchEvent = {
  in: ({ views: { syndication_investor, ...views }, ...keys }: any) => ({
    ...keys,
    views: {
      syndicationInvestor: syndication_investor,
      ...views,
    },
  }),
};

const syndicationMatchOutReasons = {
  in: renameKeys({
    created_at: 'createdAt',
    id: 'id',
    match_out_reason_type: 'matchOutReasonType',
    reason: 'reason',
    updated_at: 'updatedAt',
  }),
  out: renameKeys({
    createdAt: 'created_at',
    id: 'id',
    matchOutReasonType: 'match_out_reason_type',
    reason: 'reason',
    updatedAt: 'updated_at',
  }),
};

const syndicationMatchOutReasonResult = {
  out: renameKeys({
    passReasonIds: 'pass_reason_ids',
    otherDescription: 'other_description',
  }),
};

const syndicationCompanyIsFundraising = {
  out: renameKeys({
    isFundraising: 'is_fundraising',
  }),
};

const syndicationInvestorCreateForm = {
  in: renameKeys({
    syndication_investor: 'syndicationInvestor',
  }),
  out: renameKeys({
    syndicationInvestor: 'syndication_investor',
  }),
};

const syndicationOnboardingResult = {
  in: renameKeys({
    company_id: 'companyId',
    syndication_investor_id: 'syndicationInvestorId',
  }),
  out: renameKeys({
    companyId: 'company_id',
    syndicationInvestorId: 'syndication_investor_id',
  }),
};

export const companyOnboarding = {
  out: (data: any) => {
    const {
      state,
      latestPostMoneyValuation,
      otherRequiredInsights,
      unwantedInvestors,
      [RequiredInsights.RevenueBreakdownAndProjections]: revenueBreakdownAndProjections,
      [RequiredInsights.CustomerCohortAcquisitionCostAndLtv]: customerCohortAcquisitionCostAndLtv,
      [RequiredInsights.MarginProgression]: marginProgression,
      [RequiredInsights.SalesAndMarketingSpendEfficiency]: salesAndMarketingSpendEfficiency,
      [RequiredInsights.ScenarioBuilding]: scenarioBuilding,
      [RequiredInsights.PublicCompanyAndPrivatePeerComparables]: publicCompanyAndPrivatePeerComparables,
      ...application
    } = data;

    const formattedLeverageableAssets = application.leverageableAssets.map(
      (t: { value: string }) => t.value
    );

    const cleanOtherRequiredInsights = (otherRequiredInsights || []).map(
      (insight: SelectOption) => insight.value
    );
    const cleanUnwantedInvestors = (unwantedInvestors || []).map(
      (investor: SelectOption) => investor.value
    );

    const cleanApplication = {
      ...application,
      leverageableAssets: formattedLeverageableAssets,
      otherRequiredInsights: cleanOtherRequiredInsights,
      unwantedInvestors: cleanUnwantedInvestors,
      latestPostMoneyValuation: Number(latestPostMoneyValuation),
      requiredInsights: [
        revenueBreakdownAndProjections &&
          RequiredInsights.RevenueBreakdownAndProjections,
        customerCohortAcquisitionCostAndLtv &&
          RequiredInsights.CustomerCohortAcquisitionCostAndLtv,
        marginProgression && RequiredInsights.MarginProgression,
        salesAndMarketingSpendEfficiency &&
          RequiredInsights.SalesAndMarketingSpendEfficiency,
        scenarioBuilding && RequiredInsights.ScenarioBuilding,
        publicCompanyAndPrivatePeerComparables &&
          RequiredInsights.PublicCompanyAndPrivatePeerComparables,
      ].filter((insight) => insight), // todo remove this
    };

    return {
      company: {
        state: state,
      },
      application: renameKeys({
        revenueModel: 'revenue_model',
        industryVertical: 'vertical_industry',
        numberOfEmployees: 'number_of_employees',
        totalEquityRaised: 'equity_raised',
        yearlyRevenue: 'yearly_revenue',
        //tractionLtmRevenue: 'traction_ltm_revenue',
        yearOverYearGrowthRate: 'year_over_year_growth_rate',
        ebitdaBurn: 'ebitda_burn',
        existingDebt: 'existing_debt',
        leverageableAssets: 'leverage_assets',
        targetDebtRaise: 'target_debt_raise',
        useOfFunds: 'use_of_funds',
        fundraisingTimeFrame: 'fundraising_time_frame',
        latestPostMoneyValuation: 'latest_post_money_valuation',
        accountTypeSyndication: 'wants_non_dilutive_funding',
        accountTypeAnalytics: 'wants_company_analytics',
        unwantedInvestors: 'unwanted_investors',
        requiredInsights: 'required_insights',
        otherRequiredInsights: 'other_required_insights',
        requireMutualNda: 'investor_mutual_nda_required',
      })(cleanApplication),
    };
  },
};

export const financialDetails = {
  out: (data: any) => {
    const { state, lastValuation, ...rest } = data;

    const formattedLeverageableAssets = rest.leverageableAssets.map(
      (t: { value: string }) => t.value
    );

    const cleanApplication = {
      ...rest,
      leverageableAssets: formattedLeverageableAssets,
      lastValuation: Number(lastValuation),
    };

    return renameKeys({
      totalEquityRaised: 'equity_raised',
      leverageableAssets: 'leverage_assets',
      targetRaise: 'target_debt_raise',
      useOfFunds: 'use_of_funds',
      fundraisingTimeFrame: 'fundraising_time_frame',
      lastValuation: 'latest_post_money_valuation',
    })(cleanApplication);
  },
};

//onboarding v4 -- only for svb
export const userInfo = {
  out: renameKeys({
    firstName: 'first_name',
    lastName: 'last_name',
    companyRole: 'company_role',
  }),
};

// Principal Investment
const principalInvestment = {
  in: (data: any) => {
    const { interestedBalanceSheetPartners, ...fields } = renameKeys({
      company_id: 'companyId',
      created_at: 'createdAt',
      updated_at: 'updatedAt',
      balance_sheet_partners: 'interestedBalanceSheetPartners',
    })(data);

    return {
      ...fields,
      interestedBalanceSheetPartners: interestedBalanceSheetPartners.map(
        renameKeys({
          balance_sheet_partner: 'balanceSheetPartner',
          updated_at: 'updatedAt',
        })
      ),
    };
  },
  out: renameKeys({
    companyId: 'company_id',
  }),
};

const balanceSheetPartner = {
  in: renameKeys({}),
  out: renameKeys({
    balanceSheetPartnerCompanyId: 'balance_sheet_partner_company_id',
  }),
};

const matchTocVersion = {
  in: renameKeys({ match_toc_version: 'matchTocVersion' }),
};

const invitationRedemption = {
  in: renameKeys({ is_redeemed: 'isRedeemed' }),
};

const investorAgreementsAcceptance = {
  out: renameKeys({
    tosAccepted: 'tos_accepted',
    tosVersion: 'tos_version',
  }),
};

const matchResponse = {
  in: renameKeys({ is_match: 'isMatch' }),
};

const costOfEquityCalculation = {
  in: (data: any) => {
    const { fundingRounds, ...calculation } = renameKeys({
      funding_rounds: 'fundingRounds',
    })(data);

    return {
      ...calculation,
      fundingRounds: fundingRounds.map(
        renameKeys({
          funding_amount: 'fundingAmount',
          post_money: 'postMoney',
        })
      ),
    };
  },
  out: (data: any) => {
    const { funding_rounds = [], ...calculation } = renameKeys({
      fundingRounds: 'funding_rounds',
    })(data);

    return {
      ...calculation,
      funding_rounds: funding_rounds.map(
        renameKeys({
          fundingAmount: 'funding_amount',
          postMoney: 'post_money',
        })
      ),
    };
  },
};

const connector = {
  in: renameKeys({
    company_id: 'companyId',
    connector_type: 'connectorType',
    created_at: 'createdAt',
    created_by: 'createdBy',
    updated_at: 'updatedAt',
    connector_health_status: 'health', // legacy status, cannot deprecate
    health_status: 'healthStatus', // new for codat
    data_pull_in_progress: 'dataPullInProgress',
    most_recent_underlying_data: 'mostRecentUnderlyingData',
    last_success_pull_at: 'lastSuccessfulPullAt',
    connector_source: 'connectorSource',
    auth_provider_id: 'authProviderId',
  }),
  out(data: any) {
    return data;
  },
};

const shopify = connector;

const pagination = {
  in: renameKeys({
    num_pages: 'pageCount',
    page_number: 'currentPage',
    page_size: 'pageSize',
    total_results: 'results',
  }),
};

const chart = {
  in: renameKeys({
    company_id: 'companyId',
    created_at: 'createdAt',
    created_by: 'createdBy',
    updated_at: 'updatedAt',
    updated_by: 'updatedBy',
    tab_name: 'tabName',
  }),
};

const docusignMNDA = {
  in: renameKeys({
    envelope_id: 'ID',
    redirect_url: 'redirectURL',
  }),
  out: renameKeys({}),
};

const envelope = {
  in: renameKeys({
    company_id: 'companyId',
    created_at: 'createdAt',
    created_by: 'createdBy',
    updated_at: 'updatedAt',
    envelope_id: 'envelopeId',
    source_file_id: 'sourceFileId',
  }),
  out: renameKeys({}),
};

const docusignConnector = {
  in: renameKeys({
    updated_at: 'updatedAt',
    expires_at: 'expiresAt',
  }),
  out: renameKeys({}),
};

const portfolioCompany = {
  in: renameKeys({}),
  out: renameKeys({
    investeeCompanyID: 'company_id',
  }),
};

const syndicationMatchProfile = {
  in: ({
    name,
    email,
    type,
    company_id,
    business_type,
    investment_types,
    investment_ranges,
    investment_amount,
    first_name,
    last_name,
  }: any): SyndicationMatchProfile => {
    return {
      name,
      email,
      type,
      companyId: company_id,
      businessType: business_type,
      investmentTypes:
        investment_types && investmentTypesToSelectOptions(investment_types),
      investmentRanges:
        investment_ranges && investmentRangesToSelectOptions(investment_ranges),
      investmentAmount: investment_amount,
      firstName: first_name,
      lastName: last_name,
    };
    // const syndicationMatchProfile = renameKeys({
    //   company_id: 'companyId',
    //   business_type: 'businessType',
    //   investment_types: 'investmentTypes',
    //   investment_ranges: 'investmentRanges',
    //   investment_amount: 'investmentAmount',
    //   first_name: 'firstName',
    //   last_name: 'lastName',
    // })(data);

    // return {
    //   ...syndicationMatchProfile,
    //   ...(syndicationMatchProfile &&
    //     syndicationMatchProfile.investmentRanges && {
    //       investmentRanges: investmentRangesToSelectOptions(
    //         syndicationMatchProfile.investmentRanges || []
    //       ),
    //     }),
    //   ...(syndicationMatchProfile &&
    //     syndicationMatchProfile.investmentTypes && {
    //       investmentTypes: investmentTypesToSelectOptions(
    //         syndicationMatchProfile.investmentTypes || []
    //       ),
    //     }),
    // };
  },
};

const portfolioInvite = {
  in: renameKeys({
    investor_company_id: 'investorCompanyID',
    company_id: 'companyID',
    company_name: 'companyName',
    company_url: 'companyUrl',
    contact_first_name: 'contactFirstName',
    contact_last_name: 'contactLastName',
    contact_email: 'contactEmail',
    contact_position: 'contactPosition',
    invested_capital: 'investedCapital',
    date_of_first_investment: 'dateOfFirstInvestment',
    status_updated_at: 'statusUpdatedAt',
  }),
  out: renameKeys({
    investorCompanyID: 'investor_company_id',
    companyID: 'company_id',
    companyName: 'company_name',
    companyUrl: 'company_url',
    contactFirstName: 'contact_first_name',
    contactLastName: 'contact_last_name',
    contactEmail: 'contact_email',
    contactPosition: 'contact_position',
    investedCapital: 'invested_capital',
    dateOfFirstInvestment: 'date_of_first_investment',
    statusUpdatedAt: 'status_updated_at',
  }),
};

const companyPortfolioInvite = {
  in: renameKeys({
    investor_company_name: 'investorName',
    invite_id: 'id',
  }),
};

const canCompanyBeAddedToInvestorSubdomains = {
  in: renameKeys({
    can_be_added: 'canBeAdded',
    company_name: 'companyName',
  }),
};

const companyTOSByToken = {
  in: renameKeys({
    accepted_tos: 'acceptedTos',
    review_company_communications: 'reviewCompanyCommunications',
  }),
};

const emailAcceptability = {
  in: renameKeys({
    is_allowed: 'isAllowed',
  }),
};

const codatConnectUrl = {
  in: renameKeys({
    connect_url: 'connectUrl',
  }),
  out: renameKeys({
    type: 'connector_type',
  }),
};

const passwordReset = {
  out: renameKeys({
    password: 'password',
    acceptedTOS: 'accepted_tos',
    acceptedCommunications: 'accepted_communications',
    acceptedPrivacyPolicy: 'accepted_privacy_policy',
  }),
};

const updateSkipSyndicationLegalAgreementsRequest = {
  out: renameKeys({
    skipRequest: 'skip_request',
  }),
};

const updateCompanyProcessStatusPayload = {
  out: renameKeys({
    processStatus: 'process_status',
  }),
};

const companyAnalysis = {
  in: renameKeys(
    {
      id: 'id',
      company_id: 'companyId',
      as_of: 'asOf',
      created_at: 'createdAt',
      status: 'status',
      chart_count: 'chartCount',
      warning_count: 'warningCount',
      airflow_url: 'airflowUrl',
    },
    true
  ),
};

const companySignUpV4 = {
  in: renameKeys({
    company_id: 'id',
  }),
  out: renameKeys({
    workEmail: 'email',
    companyName: 'company_name',
    location: 'us_state',
    industry: 'vertical_industry',
    revenueModel: 'revenue_model',
    // invitation_hash
  }),
};

const companyAnalysisWarnings = {
  in: renameKeys(
    {
      id: 'id',
      created_at: 'createdAt',
      warning_type: 'warningType',
      message: 'message',
      detailed_message: 'detailedMessage',
      meta: 'meta',
    },
    true
  ),
};

const manageInsightsSettings = {
  in: renameKeys(
    {
      permission_key: 'permissionKey',
      permission_name: 'permissionName',
      permission_value: 'permissionValue',
    },
    true
  ),
};

const creditBoxReport = {
  in: ({
    id,
    company_id,
    created_at,
    report_type,
    status,
    investor_test_rating,
  }: any): CreditBoxReport => {
    return {
      id,
      companyId: company_id,
      createdAt: created_at,
      type: report_type,
      status,
      rating: investor_test_rating,
    };
  },
};
const creditBoxReportDownload = {
  in: renameKeys({ url: 'url' }),
};

const normalize = {
  chart,
  user,
  companyInvitation,
  shopify,
  teaserPublicInfo,
  teaserPublicFinances,
  application,
  utmParams,
  company,
  branding,
  connector,
  companyDataAsset,
  companyOperator,
  invite,
  file,
  customerTapeMapping,
  dataDump,
  capitalMachineModel,
  companySettings,
  plaidItem,
  quickBooksConnection,
  stripeConnection,
  companyConnector,
  companyOnboarding,
  financialDetails,
  userInfo,
  syndicationInvestorDetail,
  syndicationCompanyDetail,
  syndicationCompanyMatch,
  syndicationMatchEvent,
  syndicationMatchOutReasons,
  syndicationMatchOutReasonResult,
  syndicationCompanyIsFundraising,
  syndicationInvestorCreateForm,
  syndicationOnboardingResult,
  costOfEquityCalculation,
  docusignMNDA,
  envelope,
  docusignConnector,
  syndicationCompanyMatch2,
  pagination,
  portfolioCompany,
  syndicationMatchProfile,
  principalInvestment,
  balanceSheetPartner,
  companySyndicationDetails,
  matchTocVersion,
  matchResponse,
  investorAgreementsAcceptance,
  portfolioInvite,
  companyPortfolioInvite,
  canCompanyBeAddedToInvestorSubdomains,
  updateSkipSyndicationLegalAgreementsRequest,
  updateCompanyProcessStatusPayload,
  tags,
  invitationRedemption,
  companyAnalysis,
  companyAnalysisWarnings,
  pmConnectorStatus,
  companyTOSByToken,
  passwordReset,
  emailAcceptability,
  codatConnectUrl,
  companySignUpV4,
  manageInsightsSettings,
  creditBoxReport,
  creditBoxReportDownload,
};

export { normalize };
