import { Dispatch } from 'redux';
import {
  Action,
  apiOnboardingCompanyActivationNoticeExistenceStateChanged,
  apiOnboardingCompanyNoticeExistenceStateChanged,
  apiOnboardingCompanyTOSByTokenStateChanged,
  onboardingV3SaveRequestStateChanged,
} from '../../actions';
import { request } from './utils';
import { toast } from '@hum/common/src/modules/toast';
import { SelectOption } from '@hum/legacy-ui';
import { normalize } from './models';
import { stripFalsyValues } from './utils';
import {
  CanCompanyBeAddedToInvestorSubdomainsInvestigation,
  NoticeName,
  OnboardingV3Payload,
} from '../../state/app';
import { CreateAPIOptions } from '.';
import { OnboardingPrefill } from './models/onboardingModels';
import {
  Response,
  handleApiPayload,
  handleApiError,
} from '@hum/common/src/api/response';

export const createOnboardingAPI = ({ client }: CreateAPIOptions) => {
  const canCompanyBeAddedToInvestorSubdomain = async (
    companyId: number,
    investorSubdomain: string
  ) => {
    try {
      const response = await client
        .get(
          `/companies/${companyId}/can-be-added-to-subdomain/${investorSubdomain}`,
          {
            withCredentials: true,
          }
        )
        .then(normalize.canCompanyBeAddedToInvestorSubdomains.in);
      return response;
    } catch (error: any) {
      if (error?.message === 'subdomain does not exists') {
        return {
          canBeAdded: undefined,
          companyName: undefined,
        } as CanCompanyBeAddedToInvestorSubdomainsInvestigation;
      }
      toast.error(
        error?.message ||
          'Failed to investigate if company can be added to subdomain'
      );
    }
  };

  const addCompanyToInvestorSubdomains = async (
    companyId: number,
    investorSubdomain: string
  ) => {
    try {
      const response = await client
        .post(`/companies/${companyId}/investors/subdomains`, {
          subdomain: investorSubdomain,
        })
        .then(() =>
          canCompanyBeAddedToInvestorSubdomain(companyId, investorSubdomain)
        )
        .then(normalize.canCompanyBeAddedToInvestorSubdomains.in);

      toast.success(
        `Successfully added you to ${response.companyName}'s portfolio`
      );

      return response;
    } catch (error: any) {
      toast.error(
        error?.message || 'Failed to add company to investor subdomains'
      );
    }
  };

  const saveOnboardingV3 = (dispatch: Dispatch<Action>) => (
    companyId: number,
    payload: OnboardingV3Payload
  ) => {
    return request(dispatch)(
      (result) => onboardingV3SaveRequestStateChanged({ companyId, result }),
      async () => {
        try {
          const cleanPayload = stripFalsyValues(payload);

          // re-attach required false values
          const withTheRightValues = {
            ...cleanPayload,
            accountTypeAnalytics: payload.accountTypeAnalytics,
            accountTypeSyndication: payload.accountTypeSyndication,
            leverageableAssets: payload.leverageableAssets || [],
          };
          const formattedPayload = normalize.companyOnboarding.out(
            withTheRightValues
          );

          const response = await client.patch(
            `/companies/${companyId}/capital_application`,
            formattedPayload
          );

          toast.success('Saved your information');

          return response;
        } catch (error: any) {
          toast.error(error?.message || 'Failed to save.');
          return error;
        }
      }
    );
  };

  const saveUnwantedInvestors = async (
    companyId: number,
    payload: { unwantedInvestors: SelectOption[] }
  ) => {
    try {
      return await client.patch(`/companies/${companyId}/capital_application`, {
        application: {
          unwanted_investors: payload.unwantedInvestors.map(
            (investor) => investor.value
          ),
        },
      });
    } catch (error: any) {
      toast.error(error?.message || 'Failed to save.');
      return error;
    }
  };

  const saveUnwantedInvestorsDispatch = () => async (
    companyId: number,
    payload: { unwantedInvestors: SelectOption[] }
  ) => {
    try {
      const response = await client.patch(
        `/companies/${companyId}/capital_application`,
        {
          application: {
            unwanted_investors: payload.unwantedInvestors.map(
              (investor) => investor.value
            ),
          },
        }
      );
      toast.success('Unwanted investor list saved');
      return response;
    } catch (error: any) {
      toast.error(error?.message || 'Failed to save.');
      return error;
    }
  };

  const saveRequireNDA = async (
    companyId: number,
    payload: { requireMutualNda: boolean }
  ) => {
    try {
      return await client.patch(`/companies/${companyId}/capital_application`, {
        application: {
          investor_mutual_nda_required: payload.requireMutualNda,
        },
      });
    } catch (error: any) {
      toast.error(error?.message || 'Failed to save.');
      return error;
    }
  };

  const getCompanyNoticeExistence = (dispatch: Dispatch<Action>) => async (
    companyId: number,
    noticeName: string
  ) => {
    return request(dispatch)(
      apiOnboardingCompanyNoticeExistenceStateChanged,
      async () => {
        try {
          return await client
            .get(`/companies/${companyId}/notices/${noticeName}`, {
              withCredentials: true,
              headers: { Pragma: 'no-cache' },
            })
            .then((r) => ({ [noticeName]: r.exists }))
            .catch(() => ({ [noticeName]: false }));
        } catch (error: any) {
          return error;
        }
      }
    );
  };

  const getCompanyActivationNotice = (dispatch: Dispatch<Action>) => async (
    companyId: number
  ) => {
    return request(dispatch)(
      apiOnboardingCompanyActivationNoticeExistenceStateChanged,
      async () => {
        try {
          return await client.get(
            `/companies/${companyId}/notices?names=${[
              NoticeName.PorfolioInvestorGetStarted,
              NoticeName.CompanyActivationFundingProfileVisited,
              NoticeName.InvestorPreferencesVisited,
              NoticeName.CompanyActivationFundingOpportunitiesVisited,
              NoticeName.CompanyActivationDataRoomDismissed,
              NoticeName.CompanyActivationConnectorsDismissed,
              NoticeName.CompanyActivationInvestorPreferencesDismissed,
              NoticeName.CompanyActivationFundingProfileDismissed,
              NoticeName.CompanyActivationIntroductionRequestDismissed,
              NoticeName.CompanyActivationDismissedGetStarted,
            ].join('&names=')}`,
            {
              withCredentials: true,
              headers: { Pragma: 'no-cache' },
            }
          );
        } catch (error: any) {
          return error;
        }
      }
    );
  };

  const setCompanyNoticeAsSeen = (dispatch: Dispatch<Action>) => (
    companyId: number,
    noticeName: string
  ) => {
    return request(dispatch)(
      apiOnboardingCompanyNoticeExistenceStateChanged,
      async () => {
        try {
          return await client
            .post(`/companies/${companyId}/notices`, {
              name: noticeName,
            })
            .then(() => ({ [noticeName]: true }))
            .catch(() => ({ [noticeName]: false }));
        } catch (error: any) {
          return error;
        }
      }
    );
  };

  const getCompanyTOSByToken = (dispatch: Dispatch<Action>) => async (
    token: string
  ) => {
    return request(dispatch)(
      apiOnboardingCompanyTOSByTokenStateChanged,
      async () => {
        try {
          return await client
            .get(`/users/${token}/tos`, {
              headers: { Pragma: 'no-cache' },
            })
            .then(normalize.companyTOSByToken.in);
        } catch (error: any) {
          return error;
        }
      }
    );
  };

  // Fetches company detail to auto fill forms.
  const getOnboardingPrefill = (
    email: string
  ): Promise<Response<OnboardingPrefill>> => {
    return client
      .post(
        '/v1/companies/onboarding_prefill',
        normalize.onboardingPrefill.out({
          email,
        })
      )
      .then(normalize.onboardingPrefill.in)
      .then((data) => handleApiPayload<OnboardingPrefill>(data))
      .catch((error) => handleApiError<OnboardingPrefill>(error));
  };

  return {
    createOnboardingAPI,
    canCompanyBeAddedToInvestorSubdomain,
    addCompanyToInvestorSubdomains,
    saveOnboardingV3,
    saveUnwantedInvestors,
    saveUnwantedInvestorsDispatch,
    saveRequireNDA,
    getCompanyNoticeExistence,
    getCompanyActivationNotice,
    setCompanyNoticeAsSeen,
    getCompanyTOSByToken,
    getOnboardingPrefill,
  };
};
