import { CompanySignupV5Payload } from '@hum/icm-app/src/actions/onboarding';
import { CreateAPIOptions } from '.';

export const createSignupv5API = ({ client }: CreateAPIOptions) => {
  const putSignUpData = async (
    companyId: number,
    payload: CompanySignupV5Payload
  ) => {
    return await client.patch(
      `/v1/companies/${companyId}/signup`,
      {
        user: {
          company_position: payload.companyPosition,
        },
        name: payload.companyName,
        website: payload.website,
        state: payload.state ? payload.state : payload.location,
        country: payload.country,
        application: {
          year_founded: payload.application?.yearFounded,
          self_reported_industry: payload.application?.selfReportedIndustry,
          revenue_model: payload.application?.revenueModel,
          investment_amount: payload.application?.investmentAmount,
          fundraising_time_frame: payload.application?.fundraisingTimeFrame,
          funding_type: payload.application?.fundingType,
          use_of_funds: payload.application?.useOfFunds,
          capital_deployment: payload.application?.capitalDeployment,
          last_funding_round: payload.application?.lastStageOfFunding,
          last_raised: payload.application?.dateOfLastRaise,
          equity_raised: payload.application?.totalEquityRaised,
          latest_post_money_valuation: payload.application?.lastValuation,
          review_and_confirm_by: payload.application?.reviewAndConfirmBy,
        },
      },
      { withCredentials: true }
    );
  };

  return { putSignUpData };
};
