import React, { memo } from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
// @ts-ignore can't import from lib due to SSR incompatibility
import { PlotParams } from 'react-plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
import { useGlobal } from '../../../hooks/useGlobal';
import { Spinner } from '@hum/ui-library';

type PlotlyProps = PlotParams & {
  responsive?: boolean;
  margin?: any;
};

type PlotType = React.ComponentType<PlotParams>;

const Plotly = memo<PlotlyProps>(
  ({ responsive, layout, margin = {}, config, ...rest }) => {
    const Plot = useGlobal<PlotType>({
      name: 'Plotly',
      transform: createPlotlyComponent,
    });

    const props = {
      ...rest,
      config: { displayModeBar: false, ...config },
      useResizeHandler: Boolean(responsive),
      layout: {
        ...layout,
        autosize: responsive ? true : layout.autosize,
        height: responsive ? undefined : layout.height,
        width: responsive ? undefined : layout.width,
        margin: {
          ...layout.margin,
          ...margin,
        },
      },
    };

    return (
      <>
        <Helmet>
          <script
            async
            defer
            key="plotly"
            src="https://cdn.plot.ly/plotly-latest.min.js"
          />
        </Helmet>

        {Plot ? (
          <>
            <Plot
              {...props}
              className={classnames({
                'h-full': responsive,
                'w-full': responsive,
              })}
            />
          </>
        ) : (
          <div
            style={{
              width: props.layout.width || '100%',
              height: props.layout.height || '100%',
            }}
            className="flex items-center justify-center bg-black-900"
          >
            <Spinner />
          </div>
        )}
      </>
    );
  }
);

export { Plotly };
