import React from 'react';
import { useSpring, animated } from 'react-spring';
import classnames from 'classnames';
import { Progress } from '../hooks/useFileManager';

type Props = {
  className?: string;
  progress: Progress;
  id?: number;
};

type AnimatedProps = {
  className?: string;
  percentage: number;
};

const AnimatedUploadProgress: React.FC<AnimatedProps> = ({
  className,
  percentage,
}) => {
  const [finished, setFinished] = React.useState<boolean>(false);
  const { width, transform } = useSpring({
    from: {
      width: `${Math.random() * 4 + 1}%`,
      transform: 'translateY(0px)',
    },
    width: `${percentage}%`,
    transform: finished ? `translateY(-10px)` : 'translateY(0px)',
    delay: 100,
  });

  // Show completed progress bar for a short amount of time before hiding
  React.useEffect(() => {
    if (percentage !== 100 || finished) {
      return;
    }

    const delay = setTimeout(() => setFinished(true), 1200);

    return () => clearTimeout(delay);
  }, [percentage, finished]);

  return (
    <div
      className={classnames('relative w-full h-0', className)}
      aria-label={`Upload progress: ${percentage}%`}
    >
      <div className="absolute w-full overflow-hidden">
        <animated.div
          className="rounded bg-green w-0"
          style={{ width, transform, height: '2px' }}
        />
      </div>
    </div>
  );
};

const UploadProgress: React.FC<Props> = ({ progress, id, className }) => {
  if (!id || !progress[id]) {
    return null;
  }

  const percentage = Math.round(progress[id] * 100);

  return (
    <AnimatedUploadProgress className={className} percentage={percentage} />
  );
};

export { UploadProgress };
