import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { apiFileRemoved } from '@hum/icm-app/src/actions';

export const useRemoveFile = (companyId: number) => {
  const { dispatch, state } = useAppStore();

  const remove = (fileId: number, silent?: boolean) =>
    dispatch(apiFileRemoved({ companyId, fileId, silent }));

  const loading = !state.currentCompanyFiles.loaded;

  return { loading, remove };
};
