import { Action } from '@hum/icm-app/src/actions';
import { BaseRequestStateChanged } from '@hum/common/src/ducks/actions';
import { Dispatch } from 'redux';
import {
  createDataResult,
  createLoadingResult,
  createErrorResult,
  Result,
} from '@hum/common/src/ducks/state';

/* eslint-disable @typescript-eslint/no-explicit-any */
import { getLabelForSelectValue } from '@hum/legacy-ui';
import {
  BUSINESS_TYPE_SELECT_OPTIONS,
  LEVERAGEABLE_ASSETS_SELECT_OPTIONS,
  REQUIRED_INSIGHTS_SELECT_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS,
  SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
} from '@hum/icm-app/src/components/constants';

export const request = (dispatch: Dispatch<Action>) => async <TData>(
  actionCreator: (payload: {
    result: Result<TData>;
  }) => BaseRequestStateChanged<any, TData>,
  load: () => Promise<TData>
) => {
  dispatch(actionCreator({ result: createLoadingResult() }));

  try {
    const result = createDataResult(await load());
    dispatch(actionCreator({ result }));
    return result;
  } catch (error: any) {
    const result = createErrorResult(error);
    dispatch(actionCreator({ result }));
    return result;
  }
};

type KeyValue<TValue> = {
  [identifier: string]: TValue;
};

/**
 * Renames the keys of an object based on a map.
 *
 * @param keyMap Map of renaming keys.
 */

const renameKeys = (renameMap: KeyValue<any>, dropUnknownKeys = false) => (
  source: KeyValue<any>
): any => {
  if (source == null) {
    return null;
  }
  return Object.keys(source).reduce((renamed, key) => {
    let renamedKey = renameMap[key];
    if (renamedKey) {
      renamed[renamedKey] = source[key];
    } else if (!dropUnknownKeys) {
      renamed[key] = source[key];
    }
    return renamed;
  }, {});
};

const querystring = (parameters: {
  [key: string]: string | number | boolean | undefined | null;
}): string => {
  const buffer: string[] = [];
  for (const key in parameters) {
    const value = parameters[key];
    if (value != null && value !== '') {
      buffer.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    }
  }
  return buffer.join('&');
};

const investmentTypesToSelectOptions = (types: { name: string }[]) =>
  types.map(({ name }) => ({
    value: name,
    label: getLabelForSelectValue(
      SYNDICATION_INVESTOR_INVESTMENT_TYPE_OPTIONS,
      name
    ),
  }));

const leverageableAssetsToSelectOptions = (types: { name: string }[]) =>
  types.map(({ name }) => ({
    value: name,
    label: getLabelForSelectValue(LEVERAGEABLE_ASSETS_SELECT_OPTIONS, name),
  }));

const requiredInsightsToSelectOptions = (types: { name: string }[]) =>
  types.map(({ name }) => ({
    value: name,
    label: getLabelForSelectValue(REQUIRED_INSIGHTS_SELECT_OPTIONS, name),
  }));

const otherRequiredInsightsToSelectOptions = (types: { name: string }[]) =>
  types.map(({ name }) => ({
    value: name,
    label: name,
  }));

const unwantedInvestorsToSelectOptions = (types: { name: string }[]) =>
  types.map(({ name }) => ({
    value: name,
    label: name,
  }));

const businessTypesToSelectOptions = (types: { business_type: string }[]) =>
  types.map(({ business_type }) => {
    return {
      value: business_type,
      label: getLabelForSelectValue(
        BUSINESS_TYPE_SELECT_OPTIONS,
        business_type
      ),
    };
  });

const investmentRangesToSelectOptions = (types: { name: string }[]) =>
  types.map(({ name }) => ({
    value: name,
    label: getLabelForSelectValue(
      SYNDICATION_INVESTOR_INVESTMENT_RANGE_OPTIONS,
      name
    ),
  }));

// Remove keys for entries that have empty strings as values
function stripFalsyValues<TValue extends Record<string, any>>(
  value: TValue
): TValue {
  return Object.fromEntries(
    Object.entries(value).filter((entry: any) => entry[1])
  ) as TValue;
}

const tagsToSelectOptions = (tags: { tag_name: string }[]) =>
  tags?.map(({ tag_name }) => ({
    value: tag_name,
    label: tag_name,
  })) || [];

export {
  renameKeys,
  querystring,
  stripFalsyValues,
  investmentTypesToSelectOptions,
  leverageableAssetsToSelectOptions,
  investmentRangesToSelectOptions,
  businessTypesToSelectOptions,
  requiredInsightsToSelectOptions,
  otherRequiredInsightsToSelectOptions,
  unwantedInvestorsToSelectOptions,
  tagsToSelectOptions,
};
