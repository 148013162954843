import {
  ApiSyndicationInvestorShowsInterestInCompanyStateChanged,
  SyndicationAction,
} from './syndication';
import { AuthAction } from './auth';
import {
  Company,
  CompanyChart,
  CompanyContact,
  CompanyFile,
  CompanyPublicProfileData,
  FileStatus,
} from '@hum/types';
import { BaseAction, actionCreator, BaseCompanyAction } from '@hum/common';
import {
  BaseRequestStateChanged,
  RequestAction,
} from '@hum/common/src/ducks/actions';
import { OnboardingV4Action } from '../pages/onboarding-v4/ducks/actions';
import { OnboardingV5Actions } from '@hum/icm-app/src/pages/onboarding-v5/ducks/actions';
import { ChartActions } from '@hum/icm-app/src/pages/investor-analytics/ducks/actions';
import { CompanyDetailActions } from '@hum/icm-app/src/pages/syndication-v2/CompanyDetail/ducks/actions';
import { ManageInsightsSettingsActions } from '@hum/icm-app/src/pages/admin/AdminAnalytics/ducks/actions';
import { AdminActions } from '@hum/icm-app/src/pages/admin/ducks/actions';
import {
  Branding,
  CodatConnectorType,
  CompanyAnalysis,
  CompanyAnalysisWarning,
  CompanyDataAsset,
  CompanyInvitation,
  CompanySettings,
  Connector,
  ConnectorType,
  Envelope,
  FilterCategory,
  Pagination,
} from '@hum/icm-app/src/state';

import { DocusignAction } from './docusign';
import { HistoryAction } from './history';
import { OnboardingAction } from './onboarding';
import { CompanyActivationAction } from './company-activation';
import { CompanyDashboardAction } from './companyDashboard';
import { PortfolioMonitoringAction } from './portfolio-monitoring';
import { PrincipalInvestmentAction } from './principal-investment';
import { APIAction } from './api';
import { ConfettiAction } from '@hum/icm-app/src/modules/confetti/ducks/actions';
import { AnalayticsAction } from '@hum/icm-app/src/modules/analytics/ducks/actions';
import { AdminAction } from './admin';
import { CreditBoxReportsActions } from '@hum/icm-app/src/pages/admin/tabs/Syndication/CompanySyndication/ducks/actions';

export * from './history';
export * from './docusign';
export * from './syndication';
export * from './auth';
export * from './onboarding';
export * from './company-activation';
export * from './principal-investment';
export * from './portfolio-monitoring';
export * from './api';
export * from './admin';
export * from './companyDashboard';

export enum ActionType {
  CHARTS_HOOK_USED = 'CHARTS_HOOK_USED',
  COMPANY_HOOK_USED = 'COMPANY_HOOK_USED',
  FILE_MANAGER_HOOK_USED = 'FILE_MANAGER_HOOK_USED',
  COMPANY_CONTACT_HOOK_USED = 'COMPANY_CONTACT_HOOK_USED',
  PLAID_HOOK_USED = 'PLAID_HOOK_USED',
  COMPANY_FILES_HOOK_USED = 'COMPANY_FILES_HOOK_USED',
  CONNECTORS_HOOK_USED = 'CONNECTORS_HOOK_USED',
  INVESTOR_COMPANIES_HOOK_USED = 'INVESTOR_COMPANIES_HOOK_USED',
  COMPANY_DATA_ASSETS_HOOK_USED = 'COMPANY_DATA_ASSETS_HOOK_USED',
  INVESTOR_COMPANIES_ADDED = 'INVESTOR_COMPANIES_ADDED',
  INVESTOR_COMPANIES_REMOVED = 'INVESTOR_COMPANIES_REMOVED',
  INVESTOR_FORM_SAVED = 'INVESTOR_FORM_SAVED',
  API_PORTFOLIO_INVESTOR_CREATED = 'API_PORTFOLIO_INVESTOR_CREATED',
  API_COMPANY_APPLICATION_CREATED = 'API_COMPANY_APPLICATION_CREATED',
  API_COMPANY_USER_ADDED = 'API_COMPANY_USER_ADDED',
  API_COMPANY_INVITATION_ADDED = 'API_COMPANY_INVITATION_ADDED',
  API_COMPANY_INVITATION_UPDATED = 'API_COMPANY_INVITATION_UPDATED',
  API_COMPANY_INVITATION_REMOVED = 'API_COMPANY_INVITATION_REMOVED',
  API_COMPANY_USER_UPDATED = 'API_COMPANY_USER_UPDATED',
  API_COMPANY_USER_REMOVED = 'API_COMPANY_USER_REMOVED',
  WHITELABEL_REQUEST_STATE_CHANGED = 'WHITELABEL_REQUEST_STATE_CHANGED',

  API_COMPANIES_REQUEST_STATE_CHANGED = 'API_COMPANIES_REQUEST_STATE_CHANGED',
  API_INVESTOR_COMPANIES_LOADED = 'API_INVESTOR_COMPANIES_LOADED',
  API_INVESTOR_AVAILABLE_COMPANIES_LOADED = 'API_INVESTOR_AVAILABLE_COMPANIES_LOADED',

  COMPANY_LIST_FILTERS_CHANGED = 'COMPANY_LIST_FILTERS_CHANGED',
  INVESTOR_AVAILABLE_COMPANIES_HOOK_USED = 'INVESTOR_AVAILABLE_COMPANIES_HOOK_USED',
  DOCUSIGN_ENVELOPE_HOOK_USED = 'DOCUSIGN_ENVELOPE_USED',
  LINKED_ACCOUNT_DELETE_BUTTON_CLICKED = 'LINKED_ACCOUNT_DELETE_BUTTON_CLICKED',
  EXTERNAL_SERVICE_CONNECTED = 'EXTERNAL_SERVICE_CONNECTED',
  API_CONNECTOR_SAVED_SUCCESSFULLY = 'API_CONNECTOR_SAVED_SUCCESSFULLY',
  API_CONNECTOR_DISCONNECTED = 'API_CONNECTOR_DISCONNECTED',
  CHARTS_REQUEST_STATE_CHANGED = 'CHARTS_REQUEST_STATE_CHANGED',
  COMPANY_FILES_REQUEST_STATE_CHANGED = 'COMPANY_FILES_REQUEST_STATE_CHANGED',
  COMPANY_DATA_ASSETS_REQUEST_STATE_CHANGED = 'COMPANY_DATA_ASSETS_REQUEST_STATE_CHANGED',
  COMPANY_REQUEST_STATE_CHANGED = 'COMPANY_REQUEST_STATE_CHANGED',
  COMPANY_SETTINGS_REQUEST_STATE_CHANGED = 'COMPANY_SETTINGS_REQUEST_STATE_CHANGED',
  COMPANY_USERS_REQUEST_STATE_CHANGED = 'COMPANY_USERS_REQUEST_STATE_CHANGED',
  COMPANY_INVITATIONS_REQUEST_STATE_CHANGED = 'COMPANY_INVITATIONS_REQUEST_STATE_CHANGED',
  CONNECTORS_REQUEST_STATE_CHANGED = 'CONNECTORS_REQUEST_STATE_CHANGED',
  DOCUSIGN_ENVELOPE_REQUEST_STATE_CHANGED = 'DOCUSIGN_ENVELOPE_REQUEST_STATE_CHANGED',
  COMPANY_CONTACT_REQUEST_STATE_CHANGED = 'COMPANY_CONTACT_REQUEST_STATE_CHANGED',
  ANALYTICS_EVENT_TRACK_REQUESTED = 'ANALYTICS_EVENT_TRACK_REQUESTED',
  COMPANY_SETTINGS_CHANGED = 'COMPANY_SETTINGS_CHANGED',
  API_ACCEPTED_NDA = 'API_ACCEPTED_NDA',
  API_ACCEPTED_TOS = 'API_ACCEPTED_TOS',
  API_DELETED_CONNECTOR = 'API_DELETED_CONNECTOR',
  ADMIN_COMPANY_INVESTOR_LIST_REQUESTED = 'ADMIN_COMPANY_INVESTOR_LIST_REQUESTED',
  ADMIN_COMPANY_INVESTOR_LIST_STATE_CHANGED = 'ADMIN_COMPANY_INVESTOR_LIST_STATE_CHANGED',
  ADMIN_EDIT_COMPANY_FORM_CLOSED = 'ADMIN_EDIT_COMPANY_FORM_CLOSED',
  BRANDING_HOOK_USED = 'BRANDING_HOOK_USED',
  API_BRANDING_REQUEST_STATE_CHANGED = 'API_BRANDING_REQUEST_STATE_CHANGED',
  API_UPDATE_BRANDING_REQUEST_STATE_CHANGED = 'API_UPDATE_BRANDING_REQUEST_STATE_CHANGED',
  API_FILE_CREATED = 'API_FILE_CREATED',
  API_FILE_UPLOADED = 'API_FILE_UPLOADED',
  API_FILE_UPDATED = 'API_FILE_UPDATED',
  API_FILE_REMOVED = 'API_FILE_REMOVED',
  API_UPDATED_COMPANY_APPLICATION = 'API_UPDATED_COMPANY_APPLICATION',
  API_FILE_UPLOAD_STATE_CHANGED = 'API_FILE_UPLOAD_STATE_CHANGED',
  API_FILE_REMOVE_STATE_CHANGED = 'API_FILE_REMOVE_STATE_CHANGED',
  API_FILE_UPDATE_STATE_CHANGED = 'API_FILE_UPDATE_STATE_CHANGED',
  AVAILABLE_CONNECTORS_HOOK_USED = 'AVAILABLE_CONNECTORS_HOOK_USED',
  AVAILABLE_CONNECTORS_REQUEST_STATE_CHANGED = 'AVAILABLE_CONNECTORS_REQUEST_STATE_CHANGED',
  BRANDING_EDITOR_CHANGED = 'BRANDING_EDITOR_CHANGED',

  CONNECTOR_TENANT_DELETED = 'CONNECTOR_TENANT_DELETED',
  COST_OF_EQUITY_CHANGED = 'COST_OF_EQUITY_CHANGED',

  COMPANY_PUBLIC_PROFILE_HOOK_USED = 'COMPANY_PUBLIC_PROFILE_HOOK_USED',
  COMPANY_PUBLIC_PROFILE_STATE_CHANGED = 'COMPANY_PUBLIC_PROFILE_STATE_CHANGED',

  COMPANY_ANALYSES_HOOK_USED = 'COMPANY_ANALYSES_HOOK_USED',
  COMPANY_ANALYSES_REQUEST_STATE_CHANGED = 'COMPANY_ANALYSES_REQUEST_STATE_CHANGED',
  COMPANY_ANALYSIS_WARNINGS_HOOK_USED = 'COMPANY_ANALYSIS_WARNINGS_HOOK_USED',
  COMPANY_ANALYSIS_WARNINGS_REQUEST_STATE_CHANGED = 'COMPANY_ANALYSIS_WARNINGS_REQUEST_STATE_CHANGED',
  COMPANY_ANALYSIS_SELECTED = 'COMPANY_ANALYSIS_SELECTED',
  COMPANY_ANALYSIS_FROZEN = 'COMPANY_ANALYSIS_FROZEN',
  FROZEN_COMPANY_ANALYSIS_STATE_CHANGED = 'FROZEN_COMPANY_ANALYSIS_STATE_CHANGED',
}

export type CompanyAnalysisAction<TType, TPayload = {}> = BaseAction<
  TType,
  {
    companyId: number;
    companyAnalysisId: number;
  } & TPayload
>;

export type BaseCompanyAction2<TType, TPayload = {}> = BaseAction<
  TType,
  {
    companyId?: number;
  } & TPayload
>;

export type BaseFileAction<TType, TPayload = {}> = BaseCompanyAction<
  TType,
  {
    fileId: number;
  } & TPayload
>;

export type BaseConnectorAction<TType, TPayload = {}> = BaseCompanyAction<
  TType,
  {
    connectorId: number;
  } & TPayload
>;

/**
 */

export type ChartsHookUsed = BaseCompanyAction<
  ActionType.CHARTS_HOOK_USED,
  {
    tabName: string;
    companyAnalysisId?: number;
  }
>;

export type CompanyHookUsed = BaseCompanyAction2<ActionType.COMPANY_HOOK_USED>;
export type FileManagerHookUsed = BaseCompanyAction<ActionType.FILE_MANAGER_HOOK_USED>;
export type CompanyContactHookUsed = BaseCompanyAction<ActionType.COMPANY_CONTACT_HOOK_USED>;

export type AvailableConnectorsRequestStateChanged = BaseRequestStateChanged<
  ActionType.AVAILABLE_CONNECTORS_REQUEST_STATE_CHANGED,
  string[]
>;

export type AvailableConnectorsHookUsed = BaseAction<ActionType.AVAILABLE_CONNECTORS_HOOK_USED>;

export type ConnectorsRequestStateChanged = BaseRequestStateChanged<
  ActionType.CONNECTORS_REQUEST_STATE_CHANGED,
  Connector[]
>;

export type DocusignEnvelopeRequestStateChanged = BaseRequestStateChanged<
  ActionType.DOCUSIGN_ENVELOPE_REQUEST_STATE_CHANGED,
  Envelope
>;

export type ApiFileCreated = BaseCompanyAction<ActionType.API_FILE_CREATED>;
export type ApiFileUploaded = BaseCompanyAction<ActionType.API_FILE_UPLOADED>;
export type ApiFileUpdated = BaseFileAction<
  ActionType.API_FILE_UPDATED,
  {
    status: FileStatus;
  }
>;

export type ApiFileRemoved = BaseFileAction<
  ActionType.API_FILE_REMOVED,
  { silent?: boolean }
>;

export type ApiFileUploadStateChanged = BaseRequestStateChanged<
  ActionType.API_FILE_UPLOAD_STATE_CHANGED,
  {
    fileId: number;
  }
>;

export type ApiFileRemoveStateChanged = BaseRequestStateChanged<
  ActionType.API_FILE_REMOVE_STATE_CHANGED,
  CompanyFile | undefined,
  { silent: boolean }
>;

export type ApiFileUpdateStateChanged = BaseRequestStateChanged<
  ActionType.API_FILE_UPDATE_STATE_CHANGED,
  CompanyFile | undefined
>;

export type BrandingHookUsed = BaseCompanyAction<
  ActionType.BRANDING_HOOK_USED,
  Branding | undefined
>;

export type ApiBrandingRequestStateChanged = BaseRequestStateChanged<
  ActionType.API_BRANDING_REQUEST_STATE_CHANGED,
  Branding | undefined
>;

export type ApiUpdateBrandingRequestStateChanged = BaseRequestStateChanged<
  ActionType.API_UPDATE_BRANDING_REQUEST_STATE_CHANGED,
  Branding | undefined
>;

export type PlaidHookUsed = BaseCompanyAction<ActionType.PLAID_HOOK_USED>;
export type ChartsRequestStateChanged = BaseRequestStateChanged<
  ActionType.CHARTS_REQUEST_STATE_CHANGED,
  CompanyChart[]
>;

export type CompanyRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_REQUEST_STATE_CHANGED,
  Company
>;
export type CompanySettingsRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_SETTINGS_REQUEST_STATE_CHANGED,
  CompanySettings
>;

export type CompanySettingsChanged = BaseAction<
  ActionType.COMPANY_SETTINGS_CHANGED,
  {
    subdomain: string;
  }
>;

export type CompanyUsersRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_USERS_REQUEST_STATE_CHANGED,
  Company
>;

export type CompanyInvitationsRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_INVITATIONS_REQUEST_STATE_CHANGED,
  CompanyInvitation[]
>;

export type CompanyContactRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_CONTACT_REQUEST_STATE_CHANGED,
  CompanyContact
>;

export type CompanyFilesHookUsed = BaseCompanyAction<ActionType.COMPANY_FILES_HOOK_USED>;
export type EnvelopeHookUsed = BaseCompanyAction<ActionType.DOCUSIGN_ENVELOPE_HOOK_USED>;

export type ConnectorsHookUsed = BaseCompanyAction2<ActionType.CONNECTORS_HOOK_USED>;

export type InvestorCompaniesHookUsed = BaseCompanyAction<ActionType.INVESTOR_COMPANIES_HOOK_USED>;
export type CompanyDataAssetsHookUsed = BaseCompanyAction<ActionType.COMPANY_DATA_ASSETS_HOOK_USED>;
export type InvestorFormSaved = BaseCompanyAction<ActionType.INVESTOR_FORM_SAVED>;

export type InvestorCompanyAdded = BaseCompanyAction<ActionType.INVESTOR_COMPANIES_ADDED>;
export type InvestorCompanyRemoved = BaseCompanyAction<ActionType.INVESTOR_COMPANIES_REMOVED>;
export type ApiPortfolioInvestorCreated = BaseAction<ActionType.API_PORTFOLIO_INVESTOR_CREATED>;
export type ApiCompanyApplicationCreated = BaseAction<ActionType.API_COMPANY_APPLICATION_CREATED>;
export type APICompanyUserAdded = BaseAction<ActionType.API_COMPANY_USER_ADDED>;
export type APICompanyUserUpdated = BaseAction<ActionType.API_COMPANY_USER_UPDATED>;
export type APICompanyInvitationAdded = BaseAction<ActionType.API_COMPANY_INVITATION_ADDED>;
export type APICompanyInvitationUpdated = BaseAction<ActionType.API_COMPANY_INVITATION_UPDATED>;
export type APICompanyInvitationRemoved = BaseAction<ActionType.API_COMPANY_INVITATION_REMOVED>;

export type APICompanyUserRemoved = BaseAction<ActionType.API_COMPANY_USER_REMOVED>;

export type ApiCompaniesRequestStateChanged = BaseRequestStateChanged<
  ActionType.API_COMPANIES_REQUEST_STATE_CHANGED,
  {
    companies: Company[];
    pagination: Pagination;
  }
>;
export type ApiWhiteLabelRequestStateChanged = BaseRequestStateChanged<
  ActionType.WHITELABEL_REQUEST_STATE_CHANGED,
  Branding
>;

export type ApiInvestorCompaniesLoaded = BaseCompanyAction<
  ActionType.API_INVESTOR_COMPANIES_LOADED,
  {
    companies: Company[];
  }
>;
export type AdminCompanyInvestorListRequested = BaseCompanyAction<ActionType.ADMIN_COMPANY_INVESTOR_LIST_REQUESTED>;
export type AdminCompanyInvestorListStateChanged = BaseRequestStateChanged<
  ActionType.ADMIN_COMPANY_INVESTOR_LIST_STATE_CHANGED,
  Company[]
>;
export type AdminEditCompanyFormClosed = BaseCompanyAction<ActionType.ADMIN_EDIT_COMPANY_FORM_CLOSED>;
export type ApiInvestorAvailableCompaniesLoaded = BaseCompanyAction<
  ActionType.API_INVESTOR_AVAILABLE_COMPANIES_LOADED,
  {
    companies: Company[];
  }
>;
export type InvestorAvailableCompaniesHookUsed = BaseCompanyAction<ActionType.INVESTOR_AVAILABLE_COMPANIES_HOOK_USED>;
export type CompanyListFiltersChanged = BaseAction<
  ActionType.COMPANY_LIST_FILTERS_CHANGED,
  {
    filters: FilterCategory[];
  }
>;

export type ApiUpdatedCompanyApplication = BaseCompanyAction<ActionType.API_UPDATED_COMPANY_APPLICATION>;

export type ExternalServiceConnected = BaseCompanyAction<
  ActionType.EXTERNAL_SERVICE_CONNECTED,
  {
    displayName: string;
    connectorType: ConnectorType | CodatConnectorType;
    airbyteEnabled: boolean;
    params: any;
  }
>;

export type CompanyFilesRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_FILES_REQUEST_STATE_CHANGED,
  CompanyFile[]
>;

export type CompanyDataAssetsRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_DATA_ASSETS_REQUEST_STATE_CHANGED,
  CompanyDataAsset[]
>;

export type APIConnectorSavedSuccessfully = BaseCompanyAction<
  ActionType.API_CONNECTOR_SAVED_SUCCESSFULLY,
  {
    connectorType: ConnectorType | CodatConnectorType;
  }
>;

export type APIConnectorDisconnected = BaseConnectorAction<ActionType.API_CONNECTOR_DISCONNECTED>;

export type APIAcceptedNDA = BaseCompanyAction<ActionType.API_ACCEPTED_NDA>;
export type APIAcceptedTOS = BaseCompanyAction<ActionType.API_ACCEPTED_TOS>;
export type APIDeletedConnector = BaseConnectorAction<ActionType.API_DELETED_CONNECTOR>;

export type LinkedAccountDeleteButtonClicked = BaseConnectorAction<ActionType.LINKED_ACCOUNT_DELETE_BUTTON_CLICKED>;

export type BrandingEditorChanged = BaseAction<
  ActionType.BRANDING_EDITOR_CHANGED,
  { branding: Branding }
>;

export type CostOfEquityChanged = BaseAction<
  ActionType.COST_OF_EQUITY_CHANGED,
  {} // TODO
>;

export type CompanyPublicProfileHookUsed = BaseCompanyAction<ActionType.COMPANY_PUBLIC_PROFILE_HOOK_USED>;
export type CompanyPublicProfileStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_PUBLIC_PROFILE_STATE_CHANGED,
  CompanyPublicProfileData
>;

export type CompanyAnalysesHookUsed = BaseCompanyAction<ActionType.COMPANY_ANALYSES_HOOK_USED>;
export type CompanyAnalysisRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_ANALYSES_REQUEST_STATE_CHANGED,
  CompanyAnalysis[]
>;
export type CompanyAnalysisWarningsHookUsed = CompanyAnalysisAction<ActionType.COMPANY_ANALYSIS_WARNINGS_HOOK_USED>;
export type CompanyAnalysisWarningsRequestStateChanged = BaseRequestStateChanged<
  ActionType.COMPANY_ANALYSIS_WARNINGS_REQUEST_STATE_CHANGED,
  CompanyAnalysisWarning[]
>;
export type CompanyAnalysisSelected = BaseCompanyAction<
  ActionType.COMPANY_ANALYSIS_SELECTED,
  { companyId: number; selectedCompanyAnalysisId: number }
>;
export type CompanyAnalysisFrozen = BaseCompanyAction<
  ActionType.COMPANY_ANALYSIS_FROZEN,
  { companyId: number; frozenCompanyAnalysisId: number | undefined }
>;
export type FrozenCompanyAnalysisStateChanged = BaseRequestStateChanged<
  ActionType.FROZEN_COMPANY_ANALYSIS_STATE_CHANGED,
  { companyId: number; frozenCompanyAnalysisId: number | undefined }
>;

export const availableConnectorsRequestStateChanged = actionCreator<AvailableConnectorsRequestStateChanged>(
  ActionType.AVAILABLE_CONNECTORS_REQUEST_STATE_CHANGED
);

export const availableConnectorsHookUsed = actionCreator<AvailableConnectorsHookUsed>(
  ActionType.AVAILABLE_CONNECTORS_HOOK_USED
);

export const chartsHookUsed = actionCreator<ChartsHookUsed>(
  ActionType.CHARTS_HOOK_USED
);
export const companyHookUsed = actionCreator<CompanyHookUsed>(
  ActionType.COMPANY_HOOK_USED
);
export const fileManagerHookUsed = actionCreator<FileManagerHookUsed>(
  ActionType.FILE_MANAGER_HOOK_USED
);
export const companyContactHookUsed = actionCreator<CompanyContactHookUsed>(
  ActionType.COMPANY_CONTACT_HOOK_USED
);
export const connectorsHookUsed = actionCreator<ConnectorsHookUsed>(
  ActionType.CONNECTORS_HOOK_USED
);
export const investorCompaniesHookUsed = actionCreator<InvestorCompaniesHookUsed>(
  ActionType.INVESTOR_COMPANIES_HOOK_USED
);
export const investorCompanyAdded = actionCreator<InvestorCompanyAdded>(
  ActionType.INVESTOR_COMPANIES_ADDED
);
export const investorCompanyRemoved = actionCreator<InvestorCompanyRemoved>(
  ActionType.INVESTOR_COMPANIES_REMOVED
);
export const investorFormSaved = actionCreator<InvestorFormSaved>(
  ActionType.INVESTOR_FORM_SAVED
);
export const apiCompaniesRequestStateChanged = actionCreator<ApiCompaniesRequestStateChanged>(
  ActionType.API_COMPANIES_REQUEST_STATE_CHANGED
);
export const apiWhitelabelingRequestStateChanged = actionCreator<ApiWhiteLabelRequestStateChanged>(
  ActionType.WHITELABEL_REQUEST_STATE_CHANGED
);
export const apiPortfolioInvestorCreated = actionCreator<ApiPortfolioInvestorCreated>(
  ActionType.API_PORTFOLIO_INVESTOR_CREATED
);
export const apiCompanyApplicationCreated = actionCreator<ApiCompanyApplicationCreated>(
  ActionType.API_COMPANY_APPLICATION_CREATED
);

export const apiCompanyUserAdded = actionCreator<APICompanyUserAdded>(
  ActionType.API_COMPANY_USER_ADDED
);
export const apiCompanyUserUpdated = actionCreator<APICompanyUserUpdated>(
  ActionType.API_COMPANY_USER_UPDATED
);
export const apiCompanyInvitationAdded = actionCreator<APICompanyInvitationAdded>(
  ActionType.API_COMPANY_INVITATION_ADDED
);
export const apiCompanyInvitationUpdated = actionCreator<APICompanyInvitationUpdated>(
  ActionType.API_COMPANY_INVITATION_UPDATED
);
export const apiCompanyInvitationRemoved = actionCreator<APICompanyInvitationRemoved>(
  ActionType.API_COMPANY_INVITATION_REMOVED
);

export const apiCompanyUserRemoved = actionCreator<APICompanyUserRemoved>(
  ActionType.API_COMPANY_USER_REMOVED
);

export const apiInvestorCompaniesLoaded = actionCreator<ApiInvestorCompaniesLoaded>(
  ActionType.API_INVESTOR_COMPANIES_LOADED
);
export const apiInvestorAvailableCompaniesLoaded = actionCreator<ApiInvestorAvailableCompaniesLoaded>(
  ActionType.API_INVESTOR_AVAILABLE_COMPANIES_LOADED
);
export const investorAvailableCompaniesHookUsed = actionCreator<InvestorAvailableCompaniesHookUsed>(
  ActionType.INVESTOR_AVAILABLE_COMPANIES_HOOK_USED
);
export const companyListFiltersChanged = actionCreator<CompanyListFiltersChanged>(
  ActionType.COMPANY_LIST_FILTERS_CHANGED
);
export const companyDataAssetsHookUsed = actionCreator<CompanyDataAssetsHookUsed>(
  ActionType.COMPANY_DATA_ASSETS_HOOK_USED
);
export const brandingHookUsed = actionCreator<BrandingHookUsed>(
  ActionType.BRANDING_HOOK_USED
);

export const apiBrandingRequestStateChanged = actionCreator<ApiBrandingRequestStateChanged>(
  ActionType.API_BRANDING_REQUEST_STATE_CHANGED
);

export const apiUpdateBrandingRequestStateChanged = actionCreator<ApiUpdateBrandingRequestStateChanged>(
  ActionType.API_UPDATE_BRANDING_REQUEST_STATE_CHANGED
);

export const plaidHookUsed = actionCreator<PlaidHookUsed>(
  ActionType.PLAID_HOOK_USED
);
export const companyCompanyFilesHookUsed = actionCreator<CompanyFilesHookUsed>(
  ActionType.COMPANY_FILES_HOOK_USED
);
export const docusignEnvelopeHookUsed = actionCreator<EnvelopeHookUsed>(
  ActionType.DOCUSIGN_ENVELOPE_HOOK_USED
);
export const externalServiceConnected = actionCreator<ExternalServiceConnected>(
  ActionType.EXTERNAL_SERVICE_CONNECTED
);
export const linkedAccountDeleteButtonClicked = actionCreator<LinkedAccountDeleteButtonClicked>(
  ActionType.LINKED_ACCOUNT_DELETE_BUTTON_CLICKED
);
export const apiConnectorSavedSuccessfully = actionCreator<APIConnectorSavedSuccessfully>(
  ActionType.API_CONNECTOR_SAVED_SUCCESSFULLY
);
export const adminCompanyInvestorListRequested = actionCreator<AdminCompanyInvestorListRequested>(
  ActionType.ADMIN_COMPANY_INVESTOR_LIST_REQUESTED
);
export const adminCompanyInvestorListStateChanged = actionCreator<AdminCompanyInvestorListStateChanged>(
  ActionType.ADMIN_COMPANY_INVESTOR_LIST_STATE_CHANGED
);
export const adminEditCompanyFormClosed = actionCreator<AdminEditCompanyFormClosed>(
  ActionType.ADMIN_EDIT_COMPANY_FORM_CLOSED
);
export const apiFileCreated = actionCreator<ApiFileCreated>(
  ActionType.API_FILE_CREATED
);
export const apiFileUploaded = actionCreator<ApiFileUploaded>(
  ActionType.API_FILE_UPLOADED
);
export const apiUpdatedCompanyApplication = actionCreator<ApiUpdatedCompanyApplication>(
  ActionType.API_UPDATED_COMPANY_APPLICATION
);
export const connectorsRequestStateChanged = actionCreator<ConnectorsRequestStateChanged>(
  ActionType.CONNECTORS_REQUEST_STATE_CHANGED
);
export const docusignEnvelopeRequestStateChanged = actionCreator<DocusignEnvelopeRequestStateChanged>(
  ActionType.DOCUSIGN_ENVELOPE_REQUEST_STATE_CHANGED
);
export const apiFileUpdated = actionCreator<ApiFileUpdated>(
  ActionType.API_FILE_UPDATED
);
export const apiFileRemoved = actionCreator<ApiFileRemoved>(
  ActionType.API_FILE_REMOVED
);
export const apiFileUploadStateChanged = actionCreator<ApiFileUploadStateChanged>(
  ActionType.API_FILE_UPLOAD_STATE_CHANGED
);
export const apiFileRemoveStateChanged = actionCreator<ApiFileRemoveStateChanged>(
  ActionType.API_FILE_REMOVE_STATE_CHANGED
);
export const apiFileUpdateStateChanged = actionCreator<ApiFileUpdateStateChanged>(
  ActionType.API_FILE_UPDATE_STATE_CHANGED
);

export const apiConnectorDisconnected = actionCreator<APIConnectorDisconnected>(
  ActionType.API_CONNECTOR_DISCONNECTED
);
export const apiAcceptedNDA = actionCreator<APIAcceptedNDA>(
  ActionType.API_ACCEPTED_NDA
);
export const apiAcceptedTOS = actionCreator<APIAcceptedTOS>(
  ActionType.API_ACCEPTED_TOS
);
export const apiDeletedConnector = actionCreator<APIDeletedConnector>(
  ActionType.API_DELETED_CONNECTOR
);
export const companyFilesRequestStateChanged = actionCreator<CompanyFilesRequestStateChanged>(
  ActionType.COMPANY_FILES_REQUEST_STATE_CHANGED
);

export const companyDataAssetsRequestStateChanged = actionCreator<CompanyDataAssetsRequestStateChanged>(
  ActionType.COMPANY_DATA_ASSETS_REQUEST_STATE_CHANGED
);

export const chartsRequestStateChanged = actionCreator<ChartsRequestStateChanged>(
  ActionType.CHARTS_REQUEST_STATE_CHANGED
);

export const companyRequestStateChanged = actionCreator<CompanyRequestStateChanged>(
  ActionType.COMPANY_REQUEST_STATE_CHANGED
);

export const companySettingsRequestStateChanged = actionCreator<CompanySettingsRequestStateChanged>(
  ActionType.COMPANY_SETTINGS_REQUEST_STATE_CHANGED
);

export const companySettingsChanged = actionCreator<CompanySettingsChanged>(
  ActionType.COMPANY_SETTINGS_CHANGED
);

export const companyUsersRequestStateChanged = actionCreator<CompanyUsersRequestStateChanged>(
  ActionType.COMPANY_USERS_REQUEST_STATE_CHANGED
);

export const companyInvitationsRequestStateChanged = actionCreator<CompanyInvitationsRequestStateChanged>(
  ActionType.COMPANY_INVITATIONS_REQUEST_STATE_CHANGED
);

export const companyContactRequestChanged = actionCreator<CompanyContactRequestStateChanged>(
  ActionType.COMPANY_CONTACT_REQUEST_STATE_CHANGED
);

export const brandingEditorChanged = actionCreator<BrandingEditorChanged>(
  ActionType.BRANDING_EDITOR_CHANGED
);

export type ConnectorTenantDeleted = BaseConnectorAction<
  ActionType.CONNECTOR_TENANT_DELETED,
  { companyId: number; tenantId: string }
>;

export const connectorTenantDeleted = actionCreator<ConnectorTenantDeleted>(
  ActionType.CONNECTOR_TENANT_DELETED
);

export const costOfEquityChanged = actionCreator<CostOfEquityChanged>(
  ActionType.COST_OF_EQUITY_CHANGED
);

export const companyPublicProfileHookUsed = actionCreator<CompanyPublicProfileHookUsed>(
  ActionType.COMPANY_PUBLIC_PROFILE_HOOK_USED
);

export const companyPublicProfileStateChanged = actionCreator<CompanyPublicProfileStateChanged>(
  ActionType.COMPANY_PUBLIC_PROFILE_STATE_CHANGED
);

export const companyAnalysesHookUsed = actionCreator<CompanyAnalysesHookUsed>(
  ActionType.COMPANY_ANALYSES_HOOK_USED
);
export const companyAnalysisWarningsHookUsed = actionCreator<CompanyAnalysisWarningsHookUsed>(
  ActionType.COMPANY_ANALYSIS_WARNINGS_HOOK_USED
);
export const companyAnalysisRequestStateChanged = actionCreator<CompanyAnalysisRequestStateChanged>(
  ActionType.COMPANY_ANALYSES_REQUEST_STATE_CHANGED
);
export const companyAnalysisWarningsRequestStateChanged = actionCreator<CompanyAnalysisWarningsRequestStateChanged>(
  ActionType.COMPANY_ANALYSIS_WARNINGS_REQUEST_STATE_CHANGED
);
export const companyAnalysisSelected = actionCreator<CompanyAnalysisSelected>(
  ActionType.COMPANY_ANALYSIS_SELECTED
);
export const companyAnalysisFrozen = actionCreator<CompanyAnalysisFrozen>(
  ActionType.COMPANY_ANALYSIS_FROZEN
);
export const frozenCompanyAnalysisStateChanged = actionCreator<FrozenCompanyAnalysisStateChanged>(
  ActionType.FROZEN_COMPANY_ANALYSIS_STATE_CHANGED
);

export type Action =
  | ChartsHookUsed
  | APIAction
  | CompanyHookUsed
  | ConnectorsHookUsed
  | InvestorCompaniesHookUsed
  | InvestorCompanyAdded
  | InvestorCompanyRemoved
  | ApiCompaniesRequestStateChanged
  | CompanySettingsChanged
  | BrandingEditorChanged
  | CostOfEquityChanged
  | ApiPortfolioInvestorCreated
  | ApiCompanyApplicationCreated
  | ApiInvestorCompaniesLoaded
  | ApiInvestorAvailableCompaniesLoaded
  | ApiUpdatedCompanyApplication
  | InvestorAvailableCompaniesHookUsed
  | FileManagerHookUsed
  | ApiFileCreated
  | ApiFileUploaded
  | CompanyListFiltersChanged
  | ApiFileRemoved
  | InvestorFormSaved
  | PlaidHookUsed
  | CompanyFilesHookUsed
  | CompanyContactHookUsed
  | AdminCompanyInvestorListRequested
  | AdminCompanyInvestorListStateChanged
  | AdminEditCompanyFormClosed
  | ConnectorsHookUsed
  | ExternalServiceConnected
  | ChartsRequestStateChanged
  | CompanyFilesRequestStateChanged
  | ConnectorsRequestStateChanged
  | DocusignEnvelopeRequestStateChanged
  | APICompanyUserRemoved
  | APIConnectorDisconnected
  | APIConnectorSavedSuccessfully
  | APICompanyUserAdded
  | APICompanyInvitationAdded
  | APICompanyInvitationUpdated
  | CompanyContactRequestStateChanged
  | CompanyRequestStateChanged
  | CompanyUsersRequestStateChanged
  | CompanyInvitationsRequestStateChanged
  | ApiBrandingRequestStateChanged
  | ApiUpdateBrandingRequestStateChanged
  | LinkedAccountDeleteButtonClicked
  | AvailableConnectorsRequestStateChanged
  | AvailableConnectorsHookUsed
  | CompanyDataAssetsHookUsed
  | ApiFileUploadStateChanged
  | ApiWhiteLabelRequestStateChanged
  | ApiFileRemoveStateChanged
  | ApiFileUpdateStateChanged
  | APICompanyUserUpdated
  | BrandingHookUsed
  | ApiFileUpdated
  | EnvelopeHookUsed
  | APIDeletedConnector
  | CompanyDataAssetsRequestStateChanged
  | APIAcceptedNDA
  | APIAcceptedTOS
  | RequestAction<any>
  | HistoryAction
  | DocusignAction
  | SyndicationAction
  | AuthAction
  | OnboardingAction
  | ApiSyndicationInvestorShowsInterestInCompanyStateChanged
  | CompanySettingsRequestStateChanged
  | ConnectorTenantDeleted
  | PrincipalInvestmentAction
  | AdminAction
  | PortfolioMonitoringAction
  | CompanyPublicProfileHookUsed
  | CompanyPublicProfileStateChanged
  | CompanyAnalysesHookUsed
  | CompanyAnalysisWarningsHookUsed
  | CompanyAnalysisRequestStateChanged
  | CompanyAnalysisWarningsRequestStateChanged
  | CompanyAnalysisSelected
  | CompanyAnalysisFrozen
  | FrozenCompanyAnalysisStateChanged
  | OnboardingV4Action
  | OnboardingV5Actions
  | ConfettiAction
  | AnalayticsAction
  | CompanyActivationAction
  | CompanyDashboardAction
  | ChartActions
  | CompanyDetailActions
  | ManageInsightsSettingsActions
  | CreditBoxReportsActions
  | AdminActions;
