import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

export enum CompanyDashboardType {
  // services / connectors
  COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED = 'COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED',
  COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED_SUCCESS = 'COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED_SUCCESS',
  COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED_FAIL = 'COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED_FAIL',

  // services / connectors
  COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED = 'COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED',
  COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED_SUCCESS = 'COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED_SUCCESS',
  COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED_FAIL = 'COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED_FAIL',
}

export type CompanyDashboardDisconnectServiceButtonClicked = BaseAction<
  CompanyDashboardType.COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED,
  {
    companyId: number;
    connectorId: number;
    isAirbyteService?: boolean;
    isCodatService?: boolean;
  }
>;
export const companyDashboardDisconnectServiceButtonClicked = actionCreator<CompanyDashboardDisconnectServiceButtonClicked>(
  CompanyDashboardType.COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED
);

export type CompanyDashboardDisconnectServiceButtonClickedSuccess = BaseAction<CompanyDashboardType.COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED_SUCCESS>;
export const companyDashboardDisconnectServiceButtonClickedSuccess = actionCreator<CompanyDashboardDisconnectServiceButtonClickedSuccess>(
  CompanyDashboardType.COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED_SUCCESS
);

export type CompanyDashboardDisconnectServiceButtonClickedFail = BaseAction<CompanyDashboardType.COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED_FAIL>;
export const companyDashboardDisconnectServiceButtonClickedFail = actionCreator<CompanyDashboardDisconnectServiceButtonClickedFail>(
  CompanyDashboardType.COMPANY_DASHBOARD_DISCONNECT_SERVICE_BUTTON_CLICKED_FAIL
);

export type CompanyDashboardServiceStartDataPullButtonClicked = BaseAction<
  CompanyDashboardType.COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED,
  { companyId: number; connectorId: number; isAirbyteService?: boolean }
>;
export const companyDashboardServiceStartDataPullButtonClicked = actionCreator<CompanyDashboardServiceStartDataPullButtonClicked>(
  CompanyDashboardType.COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED
);

export type CompanyDashboardServiceStartDataPullButtonClickedSuccess = BaseAction<CompanyDashboardType.COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED_SUCCESS>;
export const companyDashboardServiceStartDataPullButtonClickedSuccess = actionCreator<CompanyDashboardServiceStartDataPullButtonClickedSuccess>(
  CompanyDashboardType.COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED_SUCCESS
);

export type CompanyDashboardServiceStartDataPullButtonClickedFail = BaseAction<CompanyDashboardType.COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED_FAIL>;
export const companyDashboardServiceStartDataPullButtonClickedFail = actionCreator<CompanyDashboardServiceStartDataPullButtonClickedFail>(
  CompanyDashboardType.COMPANY_DASHBOARD_SERVICE_START_DATA_PULL_BUTTON_CLICKED_FAIL
);

export type CompanyDashboardAction =
  | CompanyDashboardDisconnectServiceButtonClicked
  | CompanyDashboardDisconnectServiceButtonClickedSuccess
  | CompanyDashboardDisconnectServiceButtonClickedFail
  | CompanyDashboardServiceStartDataPullButtonClicked
  | CompanyDashboardServiceStartDataPullButtonClickedSuccess
  | CompanyDashboardServiceStartDataPullButtonClickedFail;
