import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { apiFileUpdated } from '@hum/icm-app/src/actions';
import { FileStatus } from '@hum/types';

export const useSetStatus = (companyId: number) => {
  const { dispatch, state } = useAppStore();

  const setStatus = (fileId: number, status: FileStatus) =>
    dispatch(apiFileUpdated({ companyId, fileId, status }));

  const loading = !state.currentCompanyFiles.loaded;

  return { loading, setStatus };
};
