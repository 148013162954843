import { renameKeys } from '@hum/icm-app/src/backend/api/utils';

export type OnboardingPrefill = {
  companyName: string;
  country: string;
  state?: string;
  website: string;
  yearFounded: string;
};

export const onboardingPrefill = {
  in: renameKeys({
    name: 'companyName',
    foundedYear: 'yearFounded',
  }),
  out: renameKeys({
    email: 'user_email',
  }),
};
