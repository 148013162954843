import {
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import orderBy from 'lodash/orderBy';

import { fileManagerHookUsed } from '@hum/icm-app/src/actions';
import { trackCompanyUploadFile } from '@hum/icm-app/src/pages/onboarding-v5/metrics/actions';
import { useAppStore } from '@hum/icm-app/src/hooks/useAppStore';
import { toast } from '@hum/common/src/modules/toast';
import {
  FileStatus,
  CompanyFileDocumentType,
  CompanyFile,
  FileInput,
} from '@hum/types';
import { EMPTY_ARRAY } from '@hum/icm-app/src/state';
import { useRemoveFile } from './remove';
import { useDownloadFile } from './download';
import { useSetStatus } from './setStatus';
import { useCreate } from './create';
import { analytics } from '@hum/common';

type Key = string | number;
export type Progress = Record<string, number>;
type ActiveMap = Record<string, boolean>;

type ActiveSetter = Dispatch<SetStateAction<ActiveMap>>;

interface FileManagerConfig {
  companyId: number;
  filter?: (file: CompanyFile) => boolean;
}

/**
 * Fully featured file manager.
 * WARN: Do not delete this manager until we have another 100% working one with events and tests attached to it
 */
const useFileManager = ({
  companyId,
  filter = () => true,
}: FileManagerConfig) => {
  const [creating, setCreatingState] = useState<ActiveMap>({});
  const [uploading, setUploadingState] = useState<ActiveMap>({});
  const [progress, setProgressState] = useState<Progress>({});
  const { loading: removing, remove: removeFile } = useRemoveFile(companyId);
  const [uploadingFiles, setUploadingFiles] = useState<Record<string, File>>(
    {}
  );
  const { create, upload } = useCreate(companyId);
  const { download: downloadFile } = useDownloadFile(companyId);
  const { setStatus } = useSetStatus(companyId);

  const { state, dispatch } = useAppStore();

  const {
    currentCompanyFiles: { loaded, data: files = EMPTY_ARRAY },
  } = state;

  useEffect(() => {
    dispatch(fileManagerHookUsed({ companyId }));
  }, [companyId]);

  const loadFiles = () => {
    dispatch(fileManagerHookUsed({ companyId }));
  };

  const filteredFiles = useMemo(
    () => orderBy(files.filter(filter), ['createdAt'], ['desc']),
    [files]
  );

  /**
   * Set active progress.
   */
  const setActive = (set: ActiveSetter) => (fileId: Key, value: boolean) =>
    set((state) => ({ ...state, [fileId]: value }));

  const setCreating = setActive(setCreatingState);
  const setUploading = setActive(setUploadingState);

  /**
   * Set the upload progress of a specific file.
   */
  const setProgress = (fileId: Key, percentage: number) =>
    setProgressState((state) => ({ ...state, [fileId]: percentage }));

  /**
   * Upload progress handler factory.
   */
  const handleUploadProgress = (fileId: number) => (e: ProgressEvent) =>
    setProgress(fileId, e.loaded / e.total);

  /**
   * Used when uploading local-computer files.
   */

  const createFromLocalFile = async ({
    reference,
    input,
    file,
  }: {
    reference?: string;
    input: FileInput;
    file: File;
  }) => {
    const { name, type } = file;

    // 1. fulfil defaults
    input.name = input.name || name;
    input.type = input.type || type;
    input.isExternal = false;

    if (reference) setCreating(reference, true);

    // 2. create file on the database.
    const { file: created, filePresignedPost } = await create(input);

    if (reference) setCreating(reference, false);

    setUploadingFiles({
      ...uploadingFiles,
      [created.id]: file,
    });

    // 3. set initial file progress
    setProgress(created.id, 0);
    setUploading(created.id, true);

    // 4. upload file
    try {
      // S3 upload will return empty 200; discard it.
      await upload(filePresignedPost, file, handleUploadProgress(created.id));

      // enforce file progress ends at 100%.
      setProgress(created.id, 1);
      await setStatus(created.id, FileStatus.Success);
    } catch (error) {
      await setStatus(created.id, FileStatus.Failed);
      throw error;
    } finally {
      setUploading(created.id, false);
    }

    // allow work on top of created file
    return created;
  };

  const handleFileChange = useCallback(
    (
      documentType: CompanyFileDocumentType,
      meta: Record<string, any> = {},
      onFileUpload: (file: File) => void
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFiles = Array.from(e.target.files || []);

      // cleanup selection
      e.target.value = '';

      selectedFiles.forEach(async (file) => {
        try {
          await createFromLocalFile({
            input: { name: file.name, type: file.type, documentType, meta },
            file,
          });

          dispatch(trackCompanyUploadFile());

          // @ts-ignore
          analytics.track('document-upload-success', {
            documentType,
            companyID: companyId,
          });
          onFileUpload(file);
        } catch (error: any) {
          // @ts-ignore
          analytics.track('document-upload-failed', {
            documentType,
            companyID: companyId,
          });
          toast.error(error?.message || 'Failed to save file.');
        }
      });
    },
    [companyId, createFromLocalFile]
  );

  return {
    // data
    // query,
    files: filteredFiles,
    loading: files === null && !loaded,
    // raw actions
    create,
    upload,
    // state
    handleFileChange,
    creating,
    uploading,
    uploadingFiles,
    removing,
    progress,
    // handlers
    createFromLocalFile,
    downloadFile,
    removeFile,
    loadFiles,
  };
};

export type FileManager = ReturnType<typeof useFileManager>;

export { useFileManager };
