import { BaseAction, actionCreator } from '@hum/common/src/ducks/actions/base';

export enum APIActionType {
  API_CONNECTOR_DELETED_FAILED = 'API_CONNECTOR_DELETED_FAILED',
  API_REQUEST_FAILED = 'API_REQUEST_FAILED',
}

export const apiConnectorDeletedFailed = actionCreator<
  BaseAction<APIActionType.API_CONNECTOR_DELETED_FAILED, Error>
>(APIActionType.API_CONNECTOR_DELETED_FAILED);

export const apiRequestFailed = actionCreator<
  BaseAction<
    APIActionType.API_REQUEST_FAILED,
    { error: Error; reason?: string }
  >
>(APIActionType.API_REQUEST_FAILED);

export type APIAction =
  | ReturnType<typeof apiConnectorDeletedFailed>
  | ReturnType<typeof apiRequestFailed>;
